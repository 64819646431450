import { PlansFormFields } from '../PlansForm/types'
import { CurrencyEnum } from '@shared/types/CurrencyTypes'
import { Buffer } from 'buffer'

interface ProductMetadata {
  activationTime: {
    unit: string;
    value: number;
  },
  activationType: string;
  monthlyValue: number;
  isFeatured: boolean;
  medalId?: number;
  monthlySavings?: number;
  passId?: number;
  bonusTime?: string;
}

const buildProductPayload = (data: PlansFormFields, id?: string) => {
  const payload = {
    name: data.name,
    slug: data.slug,
    categoryId: parseInt(data.categoryId),
    description: Buffer.from(data.description, 'binary').toString('base64'),
    subscriptionId: data.subscriptionId,
    giftable: data.giftable,
    inventoryItem: data.inventoryItem,
    isActivation: false,
    isActive: data.isActive,
    isHighlighted: false,
    isListed: data.isListed,
    isPhysical: false,
    metadata: generateProductMetadata(data),
    planTypeId: data.planType ? parseInt(data.planType) : null,
    prices: [{
      currencyId: parseInt(data.currencyId),
      price: parseFloat(data.price)
    }],
    redeemable: false,
    singlePurchase: false,
    subscribersOnly: false,
    systemId: parseInt(data.systemId),
    tags: data.tags,
    productPlanConfig: {
      trialType: data.trialType,
      trialDays: parseInt(data.trialDays) || undefined,
      nextProductId: data.nextProductId || undefined,
    },
    restrictions: generateProductRestrictions(data),
    campaignId: data.campaignId !== '0' ? data.campaignId : '',
    ...(id && { id }),
    ...((data.picture && data.hasNewImage) && { pictures: [{ url: data.picture }]}),
  }

  return payload
}

const generateProductMetadata = (data: PlansFormFields): ProductMetadata => {
  const metadata: ProductMetadata = {
    activationTime: {
      unit: 'day',
      value: parseInt(data.activationTimeValue)
    },
    activationType: 'TIMER',
    monthlyValue: parseFloat(data.monthlyValue),
    isFeatured: data.isFeatured
  }

  if (data.systemId.toString() === '1') {
    metadata.medalId = parseInt(data.medalId)
    metadata.monthlySavings = parseFloat(data.monthlySavings)
  } else if (data.systemId.toString() === '4') {
    metadata.passId = parseInt(data.passId)
    metadata.bonusTime = data.discountText
  }

  return metadata
}

const generateProductRestrictions = (data: PlansFormFields) => {
  const groupTagsArray = []
  data.accountGroups.map((group) => {
    groupTagsArray.push({id: group})
  })

  const commercialRegionArray = []
  data.regions.map((region) => {
    commercialRegionArray.push({id: region, options: []})
  })

  const paymentMethodArray = []
  data.paymentMethods.map((method) => {

    const options = []

    if (method === 'CREDIT_CARD' && data.binGroups && data.binGroups.length > 0) {
      options.push({
        id: 'binGroup',
        values: data.binGroups
      })
    }

    if (method === 'CREDIT_CARD' && data.cardBrands && data.cardBrands.length > 0) {
      options.push({
        id: 'brand',
        values: data.cardBrands
      })
    }

    paymentMethodArray.push({
      id: method, 
      options
    })
  })

  return {
    account: {
      groupTags: groupTagsArray
    },
    region: {
      commercialRegion: commercialRegionArray
    },
    payment: {
      paymentMethod: paymentMethodArray
    }
  }
}

export { buildProductPayload }