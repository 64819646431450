import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

// SERVICES
import {
  getCategory,
  createCategory,
  updateCategory,
} from '@services/Category/CategoryService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  TextField,
  Box,
  Card,
} from '@mui/material'

import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'

import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

const CategoryForm = (): JSX.Element => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    if (id) {
      _getCategory()
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }

    if (id) {
      _updateCategory()
    } else {
      _createCategory()
    }
  }

  const _createCategory = async (): Promise<void> => {
    try {
      const payload = {
        name,
        description,
      }

      await createCategory(payload)

      Alert.success(
        'Categoria criada com sucesso',
        navigate('/apps/marketplace/category/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao criar a categoria')
    }
  }

  const _updateCategory = async (): Promise<void> => {
    try {
      const payload = {
        id: parseInt(id),
        name,
        description,
      }

      await updateCategory(payload)

      Alert.success(
        'Categoria alterada com sucesso',
        navigate('/apps/marketplace/category/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao alterar a categoria')
    }
  }

  const _getCategory = async (): Promise<void> => {
    try {
      const categoryData = await getCategory(id)

      if (categoryData) {
        setName(categoryData.name || '')
        setDescription(categoryData.description || '')
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar a categoria')
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!name.trim().length) errors.push('O campo nome é obrigatório')
    if (!description.trim().length)
      errors.push('O campo descrição é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Categoria`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  return (
    <Card>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label="Nome"
                variant="outlined"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Descrição"
                variant="outlined"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  {buttonLabelAction} Categoria
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { CategoryForm }
