// COMPONENTS
import { Grid } from '@mui/material'
import { CustomTextField } from '@components/shared/CustomTextField/CustomTextField'
import { CustomSelect } from '@components/shared/CustomSelect/CustomSelect'
import { CustomImageUpload } from '@components/shared/CustomImageUpload/CustomImageUpload'

// TYPES
import { CoreFieldsProps } from './types'

// STYLES
import * as Styled from './styles'

const CoreFields = (props: CoreFieldsProps) => {
  const {
    categoryOptions,
    systemOptions,
    tagOptions
  } = props

  return (
    <Styled.TopContainer>
      <Styled.TopInfoContainer>
        <Grid container rowSpacing={4} columnSpacing={2}>
          <Grid item xs={6}>
            <CustomTextField name="name" label="Nome do plano *" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name="slug" label="Slug do plano *" />
          </Grid>
          <Grid item xs={6}>
            <CustomSelect name="categoryId" id="categoryId" label="Categoria *" options={categoryOptions}/>
          </Grid>
          <Grid item xs={6}>
            <CustomSelect name="systemId" id="systemId" label="Sistema *" options={systemOptions}/>
          </Grid>
          <Grid item xs={12}>
            <CustomSelect name="tags" id="tags" label="Tags *" options={tagOptions} multiple={true}/>
          </Grid>
        </Grid>
      </Styled.TopInfoContainer>
      <Styled.ImageUploadContainer>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <CustomImageUpload label="Imagem" name="picture" required={true}/>
        </Grid>
      </Styled.ImageUploadContainer>
    </Styled.TopContainer>
  )
}

export { CoreFields }