import styled from 'styled-components'

export const ImageUploadContainer = styled.div`
  display: flex;
  flex: 1;
  img {
    height: 120px;
    margin-bottom: 10px;
  }
`

export const TopInfoContainer = styled.div`
  display: flex;
  width: 70%;
`

export const TopContainer = styled.div`
  display: flex;
`