import { HighlightsForm } from '@components/Highlights/HighlightsForm/HighlightsForm'
import { HighlightsList } from '@components/Highlights/HighlightsList/HighlightsList'
import { CategoryForm } from '@components/Category/CategoryForm/CategoryForm'
import { CategoryList } from '@components/Category/CategoryList/CategoryList'
import { ProductForm } from '@components/Product/ProductForm/ProductForm'
import { ProductList } from '@components/Product/ProductList/ProductList'
import { PlansForm } from '@components/Plans/PlansForm/PlansForm'
import { PlansList } from '@components/Plans/PlansList/PlansList'
import { OffersList } from '@components/Offers/OffersList/OffersList'
import { OffersForm } from '@components/Offers/OffersForm/OffersForm'
import { VoucherForm } from '@components/Voucher/VoucherForm/VoucherForm'
import { VoucherUploadForm } from '@components/Voucher/VoucherUploadForm/VoucherUploadForm'
import { VoucherCouponGenerator } from '@components/Voucher/VoucherCouponGenerator/VoucherCouponGenerator'
import { VouchersList } from '@components/Voucher/VoucherList/VoucherList'
import { ProfileProductsForm } from '@components/ProfileProducts/ProfileProductsForm/ProfileProductsForm'
import { ProfileProductsList } from '@components/ProfileProducts/ProfileProductsList/ProfileProductsList'
import { PurchaseTermsForm } from '@components/PurchaseTerms/PurchaseTermsForm/PurchaseTermsForm'
import { PurchaseTermsList } from '@components/PurchaseTerms/PurchaseTermsList/PurchaseTermsList'
import { BannerList } from '@components/Banner/BannerList/BannerList'
import { BannerForm } from '@components/Banner/BannerForm/BannerForm'
import { TagsForm } from '@components/Tags/TagsForm/TagsForm'
import { TagsList } from '@components/Tags/TagsList/TagsList'
import { TagsOrder } from '@components/Tags/TagsOrder/TagsOrder'
import { SeasonList } from '@components/Season/SeasonList/SeasonList'
import { SeasonForm } from '@components/Season/SeasonForm/SeasonForm'
import { DiscountCouponsList } from '@components/DiscountCoupons/DiscountCouponsList/DiscountCouponsList'
import { DiscountCouponsForm } from '@components/DiscountCoupons/DiscountCouponsForm/DiscountCouponsForm'
import { DiscountProductsForm } from '@components/DiscountProducts/DiscountProductsForm/DiscountProductsForm'
import { DiscountProductsList } from '@components/DiscountProducts/DiscountProductsList/DiscountProductsList'
import { OnboardingRewardsList } from '@components/OnboardingRewards/OnboardingRewardsList/OnboardingRewardsList'
import { OnboardingRewardsForm } from '@components/OnboardingRewards/OnboardingRewardsForm/OnboardingRewardsForm'
import { RedeemCodesList } from '@components/RedeemCodes/RedeemCodesList/RedeemCodesList'
import { RedeemCodesForm } from '@components/RedeemCodes/RedeemCodesForm/RedeemCodesForm'
import { SlotMachineConfig } from '@components/SlotMachine/SlotMachineConfig/SlotMachineConfig'
import { SlotMachineConfigList } from '@components/SlotMachine/SlotMachineConfigList/SlotMachineConfigList'
import { PlayerTradeList } from '@components/StickersTradeList/PlayerTradeList'
import { Welcome } from '@components/shared/Welcome/Welcome'
import { GiftCardReport } from '@components/Voucher/GiftCardReport/GiftCardReport'

type Route = {
  component: JSX.Element
  exact: boolean
  path: string
}

const routes = (): Route[] => [
  // CATEGORIES
  {
    component: <CategoryForm />,
    exact: true,
    path: '/apps/marketplace/category/',
  },
  {
    component: <CategoryForm />,
    exact: true,
    path: '/apps/marketplace/category/:id',
  },
  {
    component: <CategoryList />,
    exact: true,
    path: '/apps/marketplace/category/list',
  },

  // PRODUCTS
  {
    component: <ProductForm />,
    exact: true,
    path: '/apps/marketplace/product/',
  },
  {
    component: <ProductForm />,
    exact: true,
    path: '/apps/marketplace/product/:id',
  },
  {
    component: <ProductList />,
    exact: true,
    path: '/apps/marketplace/product/list',
  },

  // PLANS
  {
    component: <PlansForm />,
    exact: true,
    path: '/apps/marketplace/plans/',
  },
  {
    component: <PlansForm />,
    exact: true,
    path: '/apps/marketplace/plans/:id',
  },
  {
    component: <PlansList />,
    exact: true,
    path: '/apps/marketplace/plans/list',
  },

  // OFFERS
  {
    component: <OffersList />,
    exact: true,
    path: '/apps/marketplace/offers/list',
  },
  {
    component: <OffersForm />,
    exact: true,
    path: '/apps/marketplace/offers',
  },
  {
    component: <OffersForm />,
    exact: true,
    path: '/apps/marketplace/offers/:id',
  },

  // VOUCHERS
  {
    component: <VoucherForm />,
    exact: true,
    path: '/apps/marketplace/voucher/',
  },
  {
    component: <VoucherUploadForm />,
    exact: true,
    path: '/apps/marketplace/voucher/upload',
  },
  {
    component: <VouchersList />,
    exact: true,
    path: '/apps/marketplace/voucher/list',
  },
  {
    component: <VoucherCouponGenerator />,
    exact: true,
    path: '/apps/marketplace/voucher/couponGenerator'
  },
  {
    component: <GiftCardReport />,
    exact: true,
    path: '/apps/marketplace/giftCards/report',
  },

  // CAMPAIGNS
  {
    component: <HighlightsList />,
    exact: true,
    path: '/apps/marketplace/highlights/list',
  },
  {
    component: <HighlightsForm />,
    exact: true,
    path: '/apps/marketplace/highlights/',
  },
  {
    component: <HighlightsForm />,
    exact: true,
    path: '/apps/marketplace/Highlights/:id',
  },

  // PROFILE PRODUCTS
  {
    component: <ProfileProductsList />,
    exact: true,
    path: '/apps/marketplace/profileProducts/list',
  },
  {
    component: <ProfileProductsForm />,
    exact: true,
    path: '/apps/marketplace/profileProducts/',
  },
  {
    component: <ProfileProductsForm />,
    exact: true,
    path: '/apps/marketplace/profileProducts/:id',
  },

  // Purchase Terms
  {
    component: <PurchaseTermsList />,
    exact: true,
    path: '/apps/marketplace/purchaseTerms/list',
  },
  {
    component: <PurchaseTermsForm />,
    exact: true,
    path: '/apps/marketplace/purchaseTerms/',
  },
  {
    component: <PurchaseTermsForm />,
    exact: true,
    path: '/apps/marketplace/purchaseTerms/:id',
  },

  // Banner
  {
    component: <BannerList />,
    exact: true,
    path: '/apps/marketplace/banner/list',
  },
  {
    component: <BannerForm />,
    exact: true,
    path: '/apps/marketplace/banner/',
  },
  {
    component: <BannerForm />,
    exact: true,
    path: '/apps/marketplace/banner/:id',
  },

  // Tags
  {
    component: <TagsList />,
    exact: true,
    path: '/apps/marketplace/tag/list',
  },
  {
    component: <TagsForm />,
    exact: true,
    path: '/apps/marketplace/tag/',
  },
  {
    component: <TagsForm />,
    exact: true,
    path: '/apps/marketplace/tag/:id',
  },
  {
    component: <TagsOrder />,
    exact: true,
    path: '/apps/marketplace/tag/order',
  },

  // Season
  {
    component: <SeasonList />,
    exact: true,
    path: '/apps/marketplace/season/list',
  },
  {
    component: <SeasonForm />,
    exact: true,
    path: '/apps/marketplace/season/',
  },
  {
    component: <SeasonForm />,
    exact: true,
    path: '/apps/marketplace/season/:id',
  },

  // Cupom de desconto
  {
    component: <DiscountCouponsList />,
    exact: true,
    path: '/apps/marketplace/discountCoupons/list',
  },
  {
    component: <DiscountCouponsForm />,
    exact: true,
    path: '/apps/marketplace/discountCoupons/',
  },
  {
    component: <DiscountCouponsForm />,
    exact: true,
    path: '/apps/marketplace/discountCoupons/:id',
  },

  // Cupom de desconto
  {
    component: <DiscountProductsList />,
    exact: true,
    path: '/apps/marketplace/discount/list',
  },
  {
    component: <DiscountProductsForm />,
    exact: true,
    path: '/apps/marketplace/discount/',
  },
  {
    component: <DiscountProductsForm />,
    exact: true,
    path: '/apps/marketplace/discount/:id',
  },

  // Recompensas do Onboarding
  {
    component: <OnboardingRewardsList />,
    exact: true,
    path: '/apps/marketplace/onboarding/list',
  },
  {
    component: <OnboardingRewardsForm />,
    exact: true,
    path: '/apps/marketplace/onboarding/',
  },
  {
    component: <OnboardingRewardsForm />,
    exact: true,
    path: '/apps/marketplace/onboarding/:id',
  },

  // Cupom de produtos
  {
    component: <RedeemCodesList />,
    exact: true,
    path: '/apps/marketplace/redeemCodes/list',
  },
  {
    component: <RedeemCodesForm />,
    exact: true,
    path: '/apps/marketplace/redeemCodes/',
  },
  {
    component: <RedeemCodesForm />,
    exact: true,
    path: '/apps/marketplace/redeemCodes/:id',
  },

  // Álbum de figurinhas
  {
    component: <PlayerTradeList />,
    exact: true,
    path: '/apps/marketplace/stickersAlbum/trades/',
  },

  // Caça coins
  {
    component: <SlotMachineConfigList />,
    exact: true,
    path: '/apps/marketplace/slotMachine/list',
  },
  {
    component: <SlotMachineConfig />,
    exact: true,
    path: '/apps/marketplace/slotMachine/config',
  },
  {
    component: <SlotMachineConfig />,
    exact: true,
    path: '/apps/marketplace/slotMachine/config/:id',
  },

  // Home
  {
    component: <Welcome />,
    exact: true,
    path: '/apps/marketplace/',
  },
]

export default routes
