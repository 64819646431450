import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import update from 'immutability-helper'

// SERVICES
import {
  getProducts,
  createSeasons,
  updateSeasons,
  getSeasonById,
  uploadImage
} from '@services/Season/SeasonService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Card,
  FormControl,
  TextField,
  Autocomplete,
  Box,
  Divider,
  Stack,
  Typography,
  Switch,
  FormControlLabel,
  Tooltip
} from '@mui/material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { ImageUpload } from '@shared/components/ImageUpload/ImageUpload'
import { CustomAutoComplete } from './components/CustomAutoComplete';
import { LoadingButton } from '@mui/lab'

// ICON
import { AddCircleOutlineRounded as AddIcon, Today, Menu, ErrorSharp } from '@mui/icons-material'

//TYPES
import { initialProductState, Product } from '@shared/types/ProductTypes'
import { UploadImageResponse } from '@shared/types/SeasonTypes';

const SeasonForm = (): JSX.Element => {
  const [title, setTitle] = useState<string>('')
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const [slug, setSlug] = useState<string>('')
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [availableProducts, setAvailableProducts] = useState<Product[]>([])
  const [pageBackground, setPageBackground] = useState<string | null>(null);
  const [productsBackground, setProductsBackground] = useState<string | null>(null);
  const [bannerBackground, setBannerBackground] = useState<string | null>(null);
  const [seasonBanner, setSeasonBanner] = useState<string | null>(null);
  const [shouldExpireProducts, setShouldExpireProducts] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    getProducts({
      limit: 1999
    }).then(response => setAvailableProducts(response.results))
  }, [])

  useEffect(() => {
    if (id) {
      _getSeason()
    }
  }, [])
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }

    if (id) {
      _updateSeasons() 
      setLoading(true);
    } else {
      _createSeasons()
      setLoading(true);
    }
  }

  const _createSeasons = async (): Promise<void> => {
    try {
      const allProductsIds = selectedProducts.map(product =>  product.id)
      const payload = {
        title,
        initialDate,
        finalDate,
        slug,
        productsId: allProductsIds,
        pageBackground,
        productsBackground,
        bannerBackground,
        seasonBanner,
        shouldExpireProducts
      }

      await createSeasons(payload)

      Alert.success(
        'Season criada com sucesso',
        navigate('/apps/marketplace/season/list')
      )
    } catch(err) {
      Alert.error(`Ocorreu um erro ao criar a Season: ${err.errors.toString()}`)
      setLoading(false)
    }
  }

  const _updateSeasons = async (): Promise<void> => {
    try {
      const allProductsIds = selectedProducts.map(product =>  product.id)
      const payload = {
        id,
        title,
        initialDate,
        finalDate,
        slug,
        productsId: allProductsIds,
        pageBackground,
        productsBackground,
        bannerBackground,
        seasonBanner,
        shouldExpireProducts
      }

      await updateSeasons(payload)

      Alert.success(
        'Season alterada com sucesso',
        navigate('/apps/marketplace/season/list')
      )
    } catch(err) {
      Alert.error(`Ocorreu um erro ao alterar a Season: ${err.errors.toString()}`)
      setLoading(false)
    }
  }

  const _getSeason = async (): Promise<void> => {
    try {
      const seasonData = await getSeasonById(id)

      if (seasonData) {
        setTitle(seasonData.season.title || '')
        setInitialDate(seasonData.season.initialDate)
        setFinalDate(seasonData.season.finalDate)
        setSlug(seasonData.season.slug)
        setSelectedProducts(seasonData.season.products || [])
        setPageBackground(seasonData.season.pageBackground || '')
        setProductsBackground(seasonData.season.productsBackground || '')
        setBannerBackground(seasonData.season.bannerBackground || '')
        setSeasonBanner(seasonData.season.seasonBanner || '')
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar a season')
    }
  }

  const validateFields = (): string[] => {
    const isProductNameEmpty = selectedProducts.filter(product => product.name === '')
    
    const isDuplicatedItemOnArray = new Set()
    selectedProducts.forEach(product => isDuplicatedItemOnArray.add(product.id))

    const errors = []
    if (!title.length) errors.push('O campo Titulo é obrigatório')
    if (selectedProducts.length === 0) errors.push('Selecione ao menos um produto')
    if (isProductNameEmpty.length) errors.push('Existe um produto vazio na sua lista, revise e tente novamente.')
    if (selectedProducts.length !== isDuplicatedItemOnArray.size) errors.push('Existem produtos duplicados na sua listagem, revise por favor!')
    return errors

  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} season`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const _uploadImage = async (
    file: File,
    imageName: string
  ): Promise<string> => {
    const formData = new window.FormData()
    formData.append('file', file)
    formData.append('imageSlug', `${slug}_${imageName}`)
    const response = await uploadImage(formData)
    return response.url
  }

  const checkFile = (file: File): boolean => {
    if (!file) return false

    const maxSizeAllowed = 15000000
    if (file.size > maxSizeAllowed) {
      Alert.error('A imagem excede o tamanho permitido')
      return false
    }

    return true
  }

  const handleUploadPageBackground = async (rawFile: File): Promise<void> => {
    if (!checkFile(rawFile)) return
    _uploadImage(rawFile, 'pageBackground')
      .then(imageUrl => setPageBackground(imageUrl))
  }

  const handleUploadProductsBackground = async (rawFile: File) => {
    if (!checkFile(rawFile)) return
    _uploadImage(rawFile, 'productsBackground')
      .then(imageUrl => setProductsBackground(imageUrl))
  }

  const handleUploadBannerBackground = async (rawFile: File) => {
    if (!checkFile(rawFile)) return
    _uploadImage(rawFile, 'bannerBackground')
      .then(imageUrl => setBannerBackground(imageUrl))
  }

  const handleUploadSeasonBanner = async (rawFile: File) => {
    if (!checkFile(rawFile)) return
    _uploadImage(rawFile, 'seasonBanner')
      .then(imageUrl => setSeasonBanner(imageUrl))
  }

  const handleRemoveImages = () => {
    setPageBackground(null)
    setProductsBackground(null)
    setBannerBackground(null)
    setSeasonBanner(null)
  }

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setSelectedProducts((prevCards: Product[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Product],
        ],
      }),
    )
  }, [])

  const removePrize = (index: number): void => {
    setSelectedProducts(oldProduct => {
      oldProduct.splice(index, 1)
      return [...oldProduct]
    })
  } 

  const addProduct = (): void => {
    setSelectedProducts(oldProduct => [...oldProduct, initialProductState])
  }

  const renderCard = useCallback(
    (card: Product, index: number) => {
      return (
      <Fragment key={index}>
        <CustomAutoComplete 
          id={card.id}
          index={index}
          moveCard={moveCard}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          availableProducts={availableProducts}
          removePrize={removePrize}
        />
      </Fragment>
      )
    },
    [selectedProducts, availableProducts],
  )

  return (
    <Card sx={{ width: 1200 }}>
    <CardHeader title={renderCardHeader()} />
    <CardContent sx={{p: 4}}>
      <form onSubmit={handleSubmit}> 
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
        <Box sx={{
          maxWidth: '50%',
          width: 600
        }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={8}>
              <TextField
                required
                label="Titulo"
                variant="outlined"
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            </Grid> 
            <Grid item xs={4}>
              <TextField
                required
                label="Slug"
                variant="outlined"
                fullWidth
                onChange={(e) => setSlug(e.target.value)}
                value={slug}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    inputFormat="dd/LL/yyyy KK:mm aaa"
                    label="Data inicial"
                    value={initialDate}
                    onChange={(initialDate) => {
                      setInitialDate(initialDate);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    inputFormat="dd/LL/yyyy KK:mm aaa"
                    label="Data final"
                    value={finalDate}
                    onChange={(finalDate) => {
                      setFinalDate(finalDate);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Tooltip title={
                  <>
                    <p>● Caso seja true, as datas de expiração dos produtos serão alteradas para a data final da season.</p>
                    <p>● Caso seja false, as datas de expiração dos produtos serão <b>REMOVIDAS!</b></p>
                  </>
                }
              >
                <FormControlLabel control={
                  <Switch
                    checked={shouldExpireProducts}
                    onChange={(e) => setShouldExpireProducts(e.target.checked)}
                    color="primary"
                  />}
                  label="Remover produtos da loja ao fim da season"
                />
              </Tooltip>
            </Grid>
          </Grid>
            {!slug ? (
            'Preencha o campo SLUG para fazer upload de imagens'
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  * Fundo da página
                </Typography>
                  <ImageUpload
                    cropped={false}
                    defaultImage={pageBackground}
                    restrictions=".jpg, .jpeg, .png"
                    handleSubmit={rawFile => {
                      handleUploadPageBackground(rawFile)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  * Background de produto
                </Typography>
                  <ImageUpload
                    cropped={false}
                    defaultImage={productsBackground}
                    restrictions=".jpg, .jpeg, .png"
                    handleSubmit={(rawFile) => {
                      handleUploadProductsBackground(rawFile)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 0.2 }}>
                <Grid item xs={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  * Banner background
                </Typography>
                  <ImageUpload
                    cropped={false}
                    defaultImage={bannerBackground}
                    restrictions=".jpg, .jpeg, .png"
                    handleSubmit={(rawFile) => {
                      handleUploadBannerBackground(rawFile)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  * Banner da season
                </Typography>
                  <ImageUpload
                    cropped={false}
                    defaultImage={seasonBanner}
                    restrictions=".jpg, .jpeg, .png"
                    handleSubmit={(rawFile) => {
                      handleUploadSeasonBanner(rawFile)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 0.2 }}>
                {seasonBanner && 
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      onClick={() => handleRemoveImages()}>
                      Remover imagens
                    </Button>
                  </Grid>
                }
              </Grid>
            </>
          )}

        </Box>
        <Box sx={{
          maxWidth: '50%',
          width: 600
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant='outlined' sx={{ width: '100%', p: 1 }}>
                <HeaderTitle title='Produtos da temporada'>
                  <Today fontSize="medium" />
                </HeaderTitle>
                <CardContent>
                  <Grid item xs={10} sx={{ mb: 4 }}>
                    <FormControl fullWidth>
                    {!availableProducts.length ? (
                      'Carregando lista de produtos...'
                      ) : (
                      <>
                        <div>
                          {selectedProducts.map((card, i) => renderCard(card, i))}
                        </div>
                        </>
                      )}
                    </FormControl>
                  </Grid>
                  <Button disabled={!availableProducts.length} onClick={(): void => addProduct()}>Adicionar Produto</Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          
        </Box>
      </Stack>
        <Grid container spacing={2} sx={{ mt: 0.2 }}>
          <Grid item xs sx={{ mt: 5 }}>
            <Box
              display="flex"
              justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={loading}
                loading={loading}
              >
                {buttonLabelAction} Season
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
  )
}

export { SeasonForm }
