const systems = [
  {
    id: 1,
    name: 'CS:GO',
  },
  {
    id: 4,
    name: 'Multigaming',
  },
]

export { systems }
