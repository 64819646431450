// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'
import { accountApi } from '@shared/api/Account/AccountApi'

// TYPES
import {
  DiscountCoupon,
  GetDiscountCouponsResponse,
  CreateDiscountCouponResponse,
  CreateDiscountCouponPayload,
  UpdateDiscountCouponPayload,
  // GetAccountGroupsResponse
} from '@shared/types/DiscountCouponsTypes'
import { GetProductsResponse } from '@shared/types/ProductTypes'

const getDiscountCouponsById = (id: string): Promise<DiscountCoupon> => {
  return marketplaceApi.get(`/backoffice/discountCoupon/${id}`)
}

const getDiscountCouponsByName = (code: string): Promise<DiscountCoupon> => {
  return marketplaceApi.get(`/backoffice/discountCoupon/code/${code}`)
}

const getDiscountCoupons = (data?): Promise<GetDiscountCouponsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/discountCoupon/${queryString}`)
}

const createDiscountCoupons = (data: CreateDiscountCouponPayload): Promise<CreateDiscountCouponResponse> => {
    return marketplaceApi.post('/backoffice/discountCoupon', data)
  }

const updateDiscountCoupons = (data: UpdateDiscountCouponPayload): Promise<CreateDiscountCouponResponse> => {
  return marketplaceApi.put(`/backoffice/discountCoupon/${data.id}`, data)
}

const deleteDiscountCoupons = (id: string): Promise<DiscountCoupon> => {
  return marketplaceApi.delete(`/backoffice/discountCoupon/${id}`)
}

const getCSPlansProducts = (pagination?: {
    page: string,
    limit: string,
    offset: string
}): Promise<GetProductsResponse> => {
  const csPlansCategory = 'subscription-v1'
  let queryString = `?categoryNames=${csPlansCategory}`

    if (pagination) {
      queryString += `&${new URLSearchParams(Object.entries(pagination))}`
    }

  return marketplaceApi.get(`/backoffice/products/${queryString}`)
}
// const getAccountGroups = (data?): Promise<GetAccountGroupsResponse> => {
//   const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

//   return accountApi.get(`/backoffice/accountGroup${queryString}`)
// }

export {
  getDiscountCouponsById,
  getDiscountCouponsByName,
  getDiscountCoupons,
  createDiscountCoupons,
  updateDiscountCoupons,
  deleteDiscountCoupons,
  getCSPlansProducts
 // getAccountGroups
}
