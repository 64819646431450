import { TextField, InputLabel } from '@mui/material'

const TextFieldComponent = (props): JSX.Element => {
  const {
    keyName,
    label,
    value,
    onChange,
    type
  } = props

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <TextField
        key={keyName}
        value={value}
        type={type}
        onChange={onChange}
        fullWidth
      />
    </>
  )
}

export { TextFieldComponent }
