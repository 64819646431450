import { Product } from '@shared/types/ProductTypes'
import { UseFormSetValue } from 'react-hook-form'
import { PlanDefaultValues } from '../PlansForm/types'

const setPlanFormValues = (productData: Product, setValue: UseFormSetValue<any>) => {
  const accountGroups = productData.restrictions?.account?.groupTags.map((group) => group.id) || []
  const regions = productData.restrictions?.region?.commercialRegion.map((region) => region.id) || []
  const paymentMethods = productData.restrictions?.payment?.paymentMethod.map((method) => method.id) || []
  const creditCardRestriction = productData.restrictions?.payment?.paymentMethod.find((method) => method.id === 'CREDIT_CARD')
  const binGroups = creditCardRestriction?.options?.find((group) => group.id === 'binGroup')?.values || []
  const cardBrands = creditCardRestriction?.options?.find((group) => group.id === 'brand')?.values || []

  setValue('name', productData.name)
  setValue('slug', productData.slug)
  setValue('categoryId', productData.category.id.toString())
  setValue('systemId', productData.system.id.toString())
  setValue('tags', productData.tags.map((tag) => tag.id))
  setValue('description', productData.description)
  setValue('currencyId', productData.prices[0].currencyId.toString())
  setValue('price', productData.prices[0].price.toString())
  setValue('planType', productData.planType?.id.toString())
  setValue('subscriptionId', productData.subscriptionId)
  setValue('monthlyValue', productData.metadata?.monthlyValue || '')
  setValue('activationTimeValue', productData.metadata?.activationTime?.value || '')
  setValue('monthlySavings', productData.metadata?.monthlySavings || '')
  setValue('medalId', productData.metadata?.medalId || '')
  setValue('discountText', productData.metadata?.bonusTime || '')
  setValue('passId', productData.metadata?.passId || '')
  setValue('isListed', productData.isListed || false)
  setValue('inventoryItem', productData.inventoryItem || false)
  setValue('isActive', productData.isActive || false)
  setValue('giftable', productData.giftable || false)
  setValue('isFeatured', productData.metadata?.isFeatured || false)
  setValue('picture', productData.pictures[0]?.url || '')
  setValue('accountGroups', accountGroups)
  setValue('regions', regions)
  setValue('paymentMethods', paymentMethods)
  setValue('binGroups', binGroups)
  setValue('cardBrands', cardBrands)
  setValue('campaignId', productData.campaignId || '0')
  setValue('trialType', productData.productPlanConfig?.trialType || '')
  setValue('trialDays', productData.productPlanConfig?.trialDays || undefined)
  setValue('nextProductId', productData.productPlanConfig?.nextProductId || undefined)
}

const setPlanFormDefaultValues = (setValue: UseFormSetValue<any>):void => {
  setValue('name', PlanDefaultValues.name)
  setValue('slug', PlanDefaultValues.slug)
  setValue('categoryId', PlanDefaultValues.categoryId)
  setValue('systemId', PlanDefaultValues.systemId)
  setValue('tags', PlanDefaultValues.tags)
  setValue('description', PlanDefaultValues.description)
  setValue('currencyId', PlanDefaultValues.currencyId)
  setValue('price', PlanDefaultValues.price)
  setValue('planType', PlanDefaultValues.planType)
  setValue('subscriptionId', PlanDefaultValues.subscriptionId)
  setValue('monthlyValue', PlanDefaultValues.monthlyValue)
  setValue('activationTimeValue', PlanDefaultValues.activationTimeValue)
  setValue('monthlySavings', PlanDefaultValues.monthlySavings)
  setValue('medalId', PlanDefaultValues.medalId)
  setValue('discountText', PlanDefaultValues.discountText)
  setValue('passId', PlanDefaultValues.passId)
  setValue('isListed', PlanDefaultValues.isListed)
  setValue('inventoryItem', PlanDefaultValues.inventoryItem)
  setValue('isActive', PlanDefaultValues.isActive)
  setValue('giftable', PlanDefaultValues.giftable)
  setValue('isFeatured', PlanDefaultValues.isFeatured)
  setValue('picture', PlanDefaultValues.picture)
  setValue('accountGroups', PlanDefaultValues.accountGroups)
  setValue('regions', PlanDefaultValues.regions)
  setValue('paymentMethods', PlanDefaultValues.paymentMethods)
  setValue('binGroups', PlanDefaultValues.binGroups)
  setValue('cardBrands', PlanDefaultValues.cardBrands)
  setValue('campaignId', PlanDefaultValues.campaignId)
  setValue('trialType', PlanDefaultValues.trialType)
  setValue('trialDays', PlanDefaultValues.trialDays)
  setValue('nextProductId', PlanDefaultValues.nextProductId)
}

export { setPlanFormValues, setPlanFormDefaultValues }