// COMPONENTS
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

// TYPES
import { FormSelectProps as Props } from './types'
import { CurrencyEnum } from '@shared/types/CurrencyTypes'

const FormSelect = (props: Props): JSX.Element => {
  const {
    error,
    items,
    label,
    multiple,
    product,
    productKey,
    productPrices,
    required,
    setProductType,
    unknownType,
    updateProductState,
    value,
  } = props

  const DropdownStyles = {
    PaperProps: {
      style: {
        maxHeight: 500,
      },
    },
  }

  const renderSelectOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  const handleChange = (e: any) => {
    if (setProductType) {
      if (unknownType) {
        const knownMetadata = {
          ...product.metadata,
          productType: e.target.value
        }
        updateProductState('metadata', knownMetadata, setProductType(e.target.value))
        return
      }
      let initialState
      switch (e.target.value) {
        case 'goldCustom':
          initialState = {
            customGoldAmount: true,
            productType: e.target.value
          }
          break
        default: 
          initialState = { productType: e.target.value }
      }
      updateProductState('metadata', initialState, setProductType(e.target.value))
    } else if (productKey === 'price.currencyId') {
      const moneyPrice = productPrices.filter(price => (
        price.currencyId !== CurrencyEnum['GOLD']
        && price.currencyId !== CurrencyEnum['SILVER']
      ))
      const coinsPrices = productPrices.filter(price => price !== moneyPrice[0])
      if (e.target.value === 0) {
        updateProductState('prices', [...coinsPrices])
      } else {
        const newPrice = [{
          currencyId: e.target.value,
          price: moneyPrice[0]?.price || 0
        }]
        updateProductState('prices', [...newPrice, ...coinsPrices])
      }
    } else {
      updateProductState(productKey, e.target.value)
    }
  }

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        required={required}
        value={value}
        label={label}
        MenuProps={DropdownStyles}
        multiple={multiple}
        onChange={(e) => handleChange(e)}
        error={error}
        fullWidth
      >
        {renderSelectOptions(items)}
      </Select>
    </FormControl>
  )
}

export { FormSelect }
