// STYLES
import * as Styled from './styles'

interface Props {
  children: any
  title: string
}

const HeaderTitle: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <Styled.CardHeader>
      <Styled.CardIcon>{props.children}</Styled.CardIcon>
      <Styled.CardHeaderTitle>{props.title}</Styled.CardHeaderTitle>
    </Styled.CardHeader>
  )
}

export { HeaderTitle }
