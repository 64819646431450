import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getDiscountCoupons,
  deleteDiscountCoupons,
  getDiscountCouponsByName,
} from '@services/DiscountCoupons/DiscountCouponsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'
import { formatDate } from '@shared/utils/FormatDate'

// COMPONENTS
import {
  Button,
  Card,
  LinearProgress,
  Grid,
  Typography,
  TextField,
  Chip,
  Box,
  IconButton,
} from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import {
  AssignmentRounded as AssignmentRoundedIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

// TYPES
import {
  DiscountCoupon,
  GetDiscountCouponsResponse,
  GetDiscountCouponQueryParams,
} from '@shared/types/DiscountCouponsTypes'

// STYLES
import * as Styled from './DiscountCouponsList.styles'

const DiscountCouponsList = (): JSX.Element => {
  const [discountCoupons, setDiscountCoupons] = useState<DiscountCoupon[]>([])
  const [wasSearched, setWasSearched] = useState<boolean>(false)
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const dateFormat = 'PP - KK:mm a'
  const navigate = useNavigate()
  const timeElapsed = Date.now()
  const today = new Date(timeElapsed)

  useEffect(() => {
    const queryParams = {
      page: 1,
      limit: 10,
    }
    _getDiscountCoupons(queryParams)
  }, [])

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!searchText) return

    _getDiscountCouponByName(searchText)
    setWasSearched(true)
  }

  const handleSeeAll = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const queryParams = {
      page: 1,
      limit: 10,
    }
    _getDiscountCoupons(queryParams)
    setWasSearched(false)
  }

  const _getDiscountCoupons = async (
    queryParams: GetDiscountCouponQueryParams
  ): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const DiscountCouponsData: GetDiscountCouponsResponse =
        await getDiscountCoupons(queryParams)
      setDiscountCoupons(DiscountCouponsData.results)
      setRowCount(DiscountCouponsData.pagination.totalItems)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _getDiscountCouponByName = async (code: string): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const DiscountCouponsSearchData = await getDiscountCouponsByName(code)
      const newListCoupon: DiscountCoupon[] = []
      newListCoupon.push(DiscountCouponsSearchData)
      setDiscountCoupons(newListCoupon)
    } catch (err) {
      Alert.error(`${err.errors[0].message}`)
      setDiscountCoupons([])
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _deleteDiscountCoupons = async (id: string): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir o cupom de desconto?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteDiscountCoupons(id)
          Alert.success(
            'Cupom de desconto excluído com sucesso',
            removeDiscountCouponsFromState(id)
          )
        }
      })
      .catch((e) => {
        Alert.error('Ocorreu um erro ao excluir o cupom de desconto')
      })
  }
  const removeDiscountCouponsFromState = (id: string): void => {
    const newDiscountCoupons = discountCoupons.filter(
      (coupon) => coupon.id !== id
    )
    setDiscountCoupons(newDiscountCoupons)
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const fallbackCopyTextToClipboard = (cupomCode: string): void => {
    const textArea = document.createElement('textarea')
    textArea.value = cupomCode || ''
    document.body.prepend(textArea)
    textArea.select()

    document.execCommand('copy')

    document.body.removeChild(textArea)
  }

  const handleCopyCoupon = (cupomCode: string): void => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(cupomCode)
      return
    }

    navigator.clipboard.writeText(cupomCode)
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): void =>
            navigate(`/apps/marketplace/discountCoupons/${id}`)
          }
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Editar
        </Button>
        <Button
          onClick={(): Promise<void> => _deleteDiscountCoupons(id)}
          color="error"
          variant="contained"
        >
          Desativar
        </Button>
      </>
    )
  }

  const DisableCoupon = (): JSX.Element => {
    return <Chip label="Desativado" color="default" />
  }

  const NotActiveCoupon = (): JSX.Element => {
    return <Chip label="Não ativo" variant="outlined" color="default" />
  }

  const ActiveCoupon = (): JSX.Element => {
    return <Chip label="Ativo" color="primary" />
  }

  const ExpireCoupon = (): JSX.Element => {
    return <Chip label="Expirado" color="error" />
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 90,
    },
    {
      field: 'code',
      headerName: 'Código do Cupom',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'left',
      align: 'left',
      minWidth: 180,
      renderCell: (params) => (
        <Grid>
          {params.row.code.toUpperCase()}
          <IconButton
            color="inherit"
            aria-label="upload picture"
            component="span"
            onClick={(): void => handleCopyCoupon(params.row.code)}
          >
            <ContentCopyIcon />
          </IconButton>
        </Grid>
      ),
    },
    {
      field: 'percentage',
      headerName: 'Porcentagem de desconto',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: (params) => <>{params.row.percentage}%</>,
    },
    {
      field: 'isActive',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => (
        <Grid>
          <Typography variant="caption" display="block" gutterBottom>
            {!params.row.isActive ? (
              DisableCoupon()
            ) : (
              <>
                {today.toISOString() < params.row.startDate &&
                  NotActiveCoupon()}
                {today.toISOString() >= params.row.startDate &&
                  today.toISOString() <= params.row.endDate &&
                  ActiveCoupon()}
                {today.toISOString() > params.row.endDate && ExpireCoupon()}
              </>
            )}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'validations',
      headerName: 'Validações',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => (
        <Grid>
          {params.row.isVerifiedPlayersOnly && (
            <Typography variant="caption" display="block" gutterBottom>
              Exclusivo para Verificados
            </Typography>
          )}
        </Grid>
      ),
    },
    {
      field: 'startDate',
      headerName: 'Data inicial',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      renderCell: (params) => formatDate(params.row.startDate, dateFormat),
    },
    {
      field: 'endDate',
      headerName: 'Data final',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      renderCell: (params) => formatDate(params.row.endDate, dateFormat),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Cupons de desconto">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>

      <Styled.FullWidthForm onSubmit={handleSearchSubmit}>
        <Grid container mt={4} columnSpacing={2}>
          <Grid item xs={7} lg={8}>
            <TextField
              label="Buscar"
              variant="outlined"
              fullWidth
              size={'small'}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText.toUpperCase()}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs lg={3}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start' }}
                sx={{ ml: 2 }}
              >
                <Styled.CustomButton
                  variant="contained"
                  type="submit"
                  sx={{ mr: 2 }}
                >
                  Buscar
                </Styled.CustomButton>
                {wasSearched && (
                  <Styled.CustomButton
                    variant="contained"
                    type="submit"
                    onClick={handleSeeAll}
                  >
                    Ver todos
                  </Styled.CustomButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Styled.FullWidthForm>
      {discountCoupons?.length > 0 ? (
        <>
          <Card
            sx={{
              mt: 4,
              ['& .MuiDataGrid-iconSeparator']: {
                display: 'none',
              },
            }}
          >
            <DataGrid
              paginationMode="server"
              rowCount={rowCount}
              rows={discountCoupons}
              columns={columns}
              loading={refreshDataLoading}
              getRowId={(row) => row.id}
              pageSize={10}
              rowsPerPageOptions={[4]}
              autoHeight
              rowHeight={80}
              disableSelectionOnClick
              components={{
                LoadingOverlay: renderCustomLoadingOverlay,
              }}
              sx={{
                ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                  outline: 'none',
                },
                ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                  whiteSpace: 'break-spaces',
                },
              }}
              onPageChange={(page): Promise<void> =>
                _getDiscountCoupons({
                  page: page + 1,
                  limit: 10,
                })
              }
            />
          </Card>
        </>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { DiscountCouponsList }
