import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 1650px;
`

export const CustomLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`