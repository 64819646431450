// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import { ListTradesRespose } from '@shared/types/StickerTypes'

export const getTrades = (queryParams): Promise<ListTradesRespose> => {
  const queryString = new URLSearchParams(queryParams).toString()
  return marketplaceApi.get(
    `/backoffice/sticker-albums/trades?${queryString}`
  )
}
