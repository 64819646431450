import { useFormContext, Controller } from 'react-hook-form'
import { FormControlLabel, Switch } from '@mui/material'

// STYLES
import * as Styled from './styles'

// TYPES
import { CustomToggleProps as Props } from './types'

const CustomToggle = (props: Props) => {
  const { name, label } = props
  const {
    control
  } = useFormContext()

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, field: { onChange } }) => (
          <Styled.SwitchWrapper>
            <FormControlLabel control={
            <Switch
              onChange={(value) => onChange(value)}
              color={"primary"}
              checked={field.value}
            />}
            label={label}
          />
          </Styled.SwitchWrapper>
        )}
      />
    </>
  )
}

export { CustomToggle }
