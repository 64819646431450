import { useFormContext } from 'react-hook-form'

// TYPES
import { SubtitlesProps as Props} from './types'

// COMPONENTS
import { Grid, InputLabel, Button, Box} from '@mui/material'
import { CustomTextField } from '@components/shared/CustomTextField/CustomTextField'
import { useEffect } from 'react'

const Subtitles = (props: Props): JSX.Element => {
  const { subtitles, setSubtitles } = props

  const includeSubtitle = ():void => {
    setSubtitles([
      ...subtitles,
      ''
    ])
  }

  const removeSubtitle = (index: number):void => {
    subtitles.forEach((subtitle, index) => {
      setValue(`subtitle${index + 1}`, '')
    })

    const newSubtitles = [...subtitles]
    newSubtitles.splice(index, 1)
    setSubtitles(newSubtitles)
  }

  useEffect(() => {
    subtitles.forEach((subtitle, index) => {
      setValue(`subtitle${index + 1}`, subtitle)
    })
  }, [subtitles])

  const {
    setValue,
    getValues
  } = useFormContext()

  const handleSubtitles = (index: number) => {
    const newSubtitles = [...subtitles]
    newSubtitles[index] = getValues(`subtitle${index + 1}`)
    setSubtitles(newSubtitles)
  }

  return (
    <>
      <Grid item xs={12}>
        <InputLabel sx={{ mb: -0.5, mt: 1}}>Frases para a animação:</InputLabel>
      </Grid>
      
      {subtitles.map((subtitle, index) => {
          return (
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-end' }}
                alignItems={{ xs: 'center', sm: 'center' }}
              >
                <CustomTextField name={`subtitle${index + 1}`} label={`Frase ${index + 1}`} complementaryOnChange={() => handleSubtitles(index)}/>
                <Button
                  sx={{ ml: 1}}
                  variant="contained"
                  color="error"
                  onClick={() => removeSubtitle(index)}
                >
                  Remover
                </Button>
              </Box>
            </Grid>
          )
      })}

      <Grid item xs={12}>
        <Box
            display="flex"
            justifyContent={{ xs: 'flex-start' }}
            alignItems={{ xs: 'center', sm: 'center' }}
          >
            <Button
              variant="contained"
              onClick={() => includeSubtitle()}
            >
              Adicionar frase
            </Button>
        </Box>
      </Grid>
    </>
  )
}
export { Subtitles }