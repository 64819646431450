import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  name: yup.string().required('O campo Nome é obrigatório'),
  slug: yup.string().required('O campo Slug é obrigatório'),
  categoryId: yup.string().required('O campo Categoria é obrigatório'),
  systemId: yup.string().required('O campo Sistema é obrigatório'),
  tags: yup.array().of(yup.string()).min(1,'O campo Tags é obrigatório').required('O campo Tags é obrigatório'),
  picture: yup.string().required('O campo Imagem é obrigatório'),
  description: yup.string().required('O campo Descrição é obrigatório'),
  currencyId: yup.string().required('O campo Moeda é obrigatório'),
  price: yup.string().required('O campo Valor é obrigatório'),
  paymentMethods: yup.array().of(yup.string()).min(1,'O campo Métodos de Pagamento é obrigatório').required('O campo Métodos de Pagamento é obrigatório'),
  subscriptionId: yup.string().required('O campo Subscription ID é obrigatório'),
  activationTimeValue: yup.string().required('O campo Quantidade de dias do plano é obrigatório'),
  monthlyValue: yup.string().required('O campo Valor mensal é obrigatório'),
  planType: yup.string().when("systemId", {
    is: "1",
    then: yup.string().required('O campo Tipo de plano é obrigatório')
  }),
  monthlySavings: yup.string().when("systemId", {
    is: "1",
    then: yup.string().required('O campo % de economia mensal é obrigatório')
  }),
  medalId: yup.string().when("systemId", {
    is: "1",
    then: yup.string().required('O campo ID da medalha é obrigatório')
  }),
  discountText: yup.string().when("systemId", {
    is: "4",
    then: yup.string().required('O campo Texto informativo desconto é obrigatório')
  }),
  passId: yup.string().when("systemId", {
    is: "4",
    then: yup.string().required('O campo ID do passe é obrigatório')
  }),
  trialDays: yup.number().when("trialType", {
    is: "FREE_DAYS",
    then: yup.number().required('O campo é obrigatório.')
  }),
  nextProductId: yup.string().when("trialType", {
    is: "PRICE_DISCOUNT",
    then: yup.string().required('O campo é obrigatório.')
  })
})
