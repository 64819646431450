import { useEffect, useState } from 'react'

// SERVICES
import { getPlayersInfo } from '@services/Account/AccountService'
import { getTrades } from '@services/Sticker/StickerService'

// HELPERS
import { formatDate } from '@shared/utils/FormatDate'

// COMPONENTS
import { LoadingButton } from '@mui/lab'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { Modal } from './Modal/Modal'

// ICONS
import { PersonSearch } from '@mui/icons-material'

// TYPES
import { GetTradesQueryParams, StickerTradeStatus, Trade } from '@shared/types/StickerTypes'
import { ModalParams } from '@components/StickersTradeList/Modal/types'
import { GetPlayersInfoResponse } from '@shared/types/AccountTypes'

// STYLES
import * as Styled from './styles'

const PlayerTradeList = (): JSX.Element => {
  const [trades, setTrades] = useState<Trade[]>()
  const [searchText, setSearchText] = useState('')
  const [tradeInfo, setTradeInfo] = useState<ModalParams>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rowCount, setRowCount] = useState<number>(1)
  const [searchParams, setSearchParams] = useState<GetTradesQueryParams>({
    page: 1,
    limit: 10,
    accountId: '',
    status: 'TRADED' as StickerTradeStatus
  })

  const _getTrades = async (
    queryParams: GetTradesQueryParams
  ): Promise<void> => {
    setLoading(true)
    let accountId = ''

    if (searchText) {
      try {
        const response = await getPlayerAccount(searchText)
        accountId = response
        setSearchParams(prev => ({ ...prev, accountId: accountId }))
      } catch(e) {
        console.log(e)
      }
    }

    try {
      queryParams = {
        ...queryParams,
        accountId: accountId
      }
      const tradesList = await getTrades(queryParams)
      setTrades(tradesList.results)
      setRowCount(tradesList.pagination.totalItems)
    } catch(e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const getPlayerAccount = async (gcId): Promise<string> => {
    const response: GetPlayersInfoResponse = await getPlayersInfo('playerIds', gcId)
    return response.players[0].accountId || ''
  }

  const handleSearchSubmit = (): void => {
    _getTrades(searchParams)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleOpenModal = (trade: Trade): void => {
    setTradeInfo(trade)
    setOpen(true)
  }

  const renderActionButtons = (trade: Trade): JSX.Element => {
    return (
      <Button variant="contained" onClick={() => handleOpenModal(trade)}>
        Detalhes
      </Button>
    )
  }

  const renderDataGrid = (): JSX.Element => {
    if (!trades)
      return <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />

    return (
      <DataGrid
        paginationMode="server"
        rows={trades}
        rowCount={rowCount}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={10}
        rowsPerPageOptions={[10]}
        autoHeight
        rowHeight={80}
        disableSelectionOnClick
        sx={{
          ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
            outline: 'none',
          },
          ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
            whiteSpace: 'break-spaces',
          },
        }}
        onPageChange={(page): Promise<void> =>
          _getTrades({
            limit: 10,
            accountId: searchParams.accountId,
            status: searchParams.status,
            page: page + 1,
          })
        }
      />
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID da trade',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
    },
    {
      field: 'accountId',
      headerName: 'Account ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
    },
    {
      field: 'sticker',
      headerName: 'Figurinha enviada',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      renderCell: (params) => params.row.sticker?.title || ''
    },
    {
      field: 'tradedSticker',
      headerName: 'Figurinha recebida',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 125,
      renderCell: (params) => params.row.tradedSticker?.title || ''
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
    },
    {
      field: 'tradedAt',
      headerName: 'Data de aceitação',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => params.row.tradedAt ? formatDate(params.row.tradedAt) : '',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row),
    },
  ]

  useEffect(() => {
    _getTrades(searchParams)
  }, [])

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de trades">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      <Styled.SearchForm>
        <TextField
          label="GC ID"
          variant="outlined"
          onChange={(e) => setSearchText(e.target.value)}
          disabled={loading}
          placeholder="GC ID do player"
          value={searchText}
        />
        <FormControl>
          <InputLabel>Status</InputLabel>
          <Select
            value={searchParams.status}
            label='Status'
            onChange={(e): void => setSearchParams(prev => ({
              ...prev,
              status: e.target.value as StickerTradeStatus
            }))}
          >
            <MenuItem value='TRADED'>TRADED</MenuItem>
            <MenuItem value='PENDING'>PENDING</MenuItem>
            <MenuItem value='CANCELLED'>CANCELLED</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          variant="contained"
          onClick={(): void => handleSearchSubmit()}
          disabled={loading}
          loading={loading}
        >
          <PersonSearch /> Filtrar
        </LoadingButton>
      </Styled.SearchForm>
      {renderDataGrid()}
      <Modal
        handleClose={() => handleCloseModal()}
        open={open}
        params={tradeInfo}
      />
    </Styled.Container>
  )
}

export { PlayerTradeList }
