import React from 'react'

const Output = (props) => {
  const { croppedArea } = props
  const scale = 100 / croppedArea.width
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: '170px',
    height: 'auto',
  }

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  }

  return (
    <div className="output">
      <img src={croppedArea} alt="" style={imageStyle} />
    </div>
  )
}

export { Output }
