import styled from 'styled-components'

export const ModalContainer = styled.div``

export const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const DataLine = styled.div`
  border-bottom: 1px solid grey;
`

export const DataTitle = styled.span`
  font-weight: bold;
`

export const DataContent = styled.span``

export const FiguresContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 10px;
`

export const StickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  width: 400px;
  border: 1px solid grey;
  padding: 10px;
`

export const Figure = styled.img`
  max-width: 150px;
  margin: auto;
`
