import {
  useState,
  useEffect
} from 'react'

// @MATERIAL-UI/COMPONENTS
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material'

// TYPES
import { PaymentRestrictionsProps as Props } from './types'
import {
  PaymentRestrictionStructure,
  RestrictionOptionStructure,
  RestrictionOptionValueStructure,
  initialRestrictionOptionState
} from '@shared/types/PaymentTypes'

const PaymentRestrictions = (props: Props): JSX.Element => {
  const [selectedPayment, setSelectedPayment] = useState([])
  const [binGroup, setBinGroup] = useState<RestrictionOptionStructure>(initialRestrictionOptionState)
  const [brand, setBrand] = useState<RestrictionOptionStructure>(initialRestrictionOptionState)
  const [loaded, setLoaded] = useState<boolean>(false)

  const {
    paymentRestrictions, // this comes from backend
    productRestrictions, // this comes from product state
    setRestrictions
  } = props

  const {
    paymentMethod // this comes from product state
  } = productRestrictions.payment

  const {
    restrictions // this comes from backend
  } = paymentRestrictions

  const hasCreditCardRestriction = paymentMethod.find(item => item.id === 'CREDIT_CARD')

  const initialCreditCardOptionsState: RestrictionOptionStructure[] = [
    {
      id: 'binGroup',
      name: binGroup.name,
      values: []
    },
    {
      id: 'brand',
      name: brand.name,
      values: []
    }
  ]

  const handleRestrictionsChange = (selection): void => {
    setSelectedPayment(selection)
  }

  const getOptionValues = (
      options: PaymentRestrictionStructure[],
      type: string
    ): RestrictionOptionValueStructure[] => {
    return options
      .find(method => method.id === 'CREDIT_CARD')
      .options
      .find(option => option.id === type)
      .values
  }

  const handleOptionsChange = (type: string, options: any): void => {
    setRestrictions(prev => {
      return {
        ...prev,
        payment: {
          paymentMethod: [{
            id: 'CREDIT_CARD',
            name: restrictions.find(restriction => restriction.id === 'CREDIT_CARD').name,
            options: [
              {
                id: 'binGroup',
                name: binGroup.name,
                values: type === 'binGroup'
                  ? options.map(option => (
                    {
                      id: option,
                      name: binGroup.values.find(group => group.id === option).name
                    }
                  ))
                  : getOptionValues(prev.payment.paymentMethod, 'binGroup')
              },
              {
                id: 'brand',
                name: brand.name,
                values: type === 'brand'
                  ? options.map(option => (
                    {
                      id: option,
                      name: brand.values.find(b => b.id === option).name
                    }
                  ))
                  : getOptionValues(prev.payment.paymentMethod, 'brand')
              }
            ]
          }]
        }
      }
    })
  }

  useEffect(() => {
    if (!paymentRestrictions) return

    setBinGroup(
      restrictions.find(restriction => restriction.id === 'CREDIT_CARD')
        .options.find(option => option.id === 'binGroup')
    )
    setBrand(
      restrictions.find(restriction => restriction.id === 'CREDIT_CARD')
        .options.find(option => option.id === 'brand')
    )
  }, [paymentRestrictions])

  useEffect(() => {
    if (!loaded) return
    setRestrictions(previous => {
      const initialOptions = paymentMethod.find(method => method.id === 'CREDIT_CARD')
      const creditCardOptions = paymentMethod && initialOptions
        ? initialOptions
        : previous.payment.paymentMethod.find(method => method.id === 'CREDIT_CARD')
      return {
        ...previous,
        ...productRestrictions,
        payment: {
          paymentMethod: selectedPayment.map(payment => (
            {
              id: payment,
              name: restrictions.find(item => item.id === payment)?.name,
              options:
                payment === 'CREDIT_CARD'
                  ? (creditCardOptions ? creditCardOptions.options : initialCreditCardOptionsState)
                  : []
            }
          ))
        }
      }}
    )
  }, [selectedPayment])

  useEffect(() => {
    setLoaded(true)
    if (paymentMethod) {
      setSelectedPayment(paymentMethod.map(method => method.id))
    }
  }, [])

  return (
    <Grid container spacing={2}>
      {/* PAYMENT METHODS */}
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>{paymentRestrictions.name}</InputLabel>
          <Select
            label={`${paymentRestrictions.name}`}
            onChange={(e) => {
              handleRestrictionsChange(e.target.value)
            }}
            multiple
            value={selectedPayment}
          >
            <MenuItem
              disabled
              value={-1}
            >
              {paymentRestrictions.name}
            </MenuItem>
            {restrictions.map(option => (
              <MenuItem
                key={option.id}
                value={option.id}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {hasCreditCardRestriction &&
        <>

          {/* BIN CODES */}
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>{binGroup.name}</InputLabel>
              <Select
                label={`${binGroup.name}`}
                onChange={(e) => {
                  handleOptionsChange('binGroup', e.target.value)
                }}
                multiple
                value={
                  paymentMethod
                    .find(method => method.id === 'CREDIT_CARD')
                    ?.options
                    .find(option => option.id === 'binGroup')
                    ?.values
                    .map(value => value.id) || []
                }
              >
                <MenuItem
                  disabled
                  value={-1}
                >
                  {binGroup.name}
                </MenuItem>
                {binGroup.values.map(option => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* CREDIT CARD FLAG */}
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>{brand.name}</InputLabel>
              <Select
                label={`${brand.name}`}
                onChange={(e) => {
                  handleOptionsChange('brand', e.target.value)
                }}
                multiple
                value={
                  paymentMethod
                    .find(method => method.id === 'CREDIT_CARD')
                    ?.options
                    .find(option => option.id === 'brand')
                    ?.values
                    .map(value => value.id) || []
                }
              >
                <MenuItem
                  disabled
                  value={-1}
                >
                  {paymentRestrictions.name}
                </MenuItem>
                {brand.values.map(option => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      }
    </Grid>
  )
}

export { PaymentRestrictions }
