import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import {
  createConfig,
  getConfig,
  updateConfig
} from '@services/SlotMachine/SlotMachineConfigService'
import { getProducts } from '@services/Product/ProductService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  TextField,
  Box,
  Card,
  Switch,
  FormControl,
  Autocomplete,
  Typography,
} from '@mui/material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

// TYPES
import { slotMachineConfigInitialState } from '../types'
import { SlotMachineConfigProps } from '@shared/types/SlotMachineTypes'
import { Product } from '@shared/types/ProductTypes'

// ICONS
import {
  ReportOutlined as ReportIcon,
  EmojiEventsOutlined as TrophyIcon
} from '@mui/icons-material'

const SlotMachineConfig = (): JSX.Element => {
  const [
    slotMachineConfig,
    setSlotMachineConfig
  ] = useState<SlotMachineConfigProps>(slotMachineConfigInitialState)
  const [
    productsList,
    setProductsList
  ] = useState<Product[]>([])
  const [
    totalDropRate,
    setTotalDropRate
  ] = useState<number>(0)
  const [
    isLoading,
    setIsLoading
  ] = useState<boolean>(true)

  const navigate = useNavigate()
  const { id } = useParams()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    
    if (id) {
      _updateConfig()
    } else {
      _createConfig()
    }
  }

  const createPayload = () => {
    const payload = slotMachineConfig
    delete payload.id
    return payload
  }

  const _updateConfig = async (): Promise<void> => {
    try {
      await updateConfig(createPayload(), id)
      Alert.success(
        'Alteração realizada com sucesso',
        navigate('/apps/marketplace/slotMachine/list')
      )
    } catch(err) {
      Alert.error(`Ocorreu um erro ao realizar a alteração ${err.errors.toString()}`)
    }
  }

  const _createConfig = async (): Promise<void> => {
    try {
      await createConfig(createPayload())
      Alert.success(
        'Configuração criada com sucesso',
        navigate('/apps/marketplace/slotMachine/list')
      )
    } catch(err) {
      Alert.error(`Ocorreu um erro ao cadastrar a configuração ${err.errors.toString()}`)
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    const {
      prizes,
      slug,
      winRate
    } = slotMachineConfig

    prizes.forEach(prize => {
      !prize.dropRate && errors.push(`Selecione um percentual de drop para o item '${prize.slug}'`)
    })

    if (totalDropRate !== 100)
      errors.push(`A soma de drop dos prêmios não está totalizando 100%`)
    if (!prizes.length)
      errors.push(`Selecione ao menos uma premiação`)
    if (!winRate)
      errors.push(`O campo "Porcentagem de vitória" é obrigatório`)
    if (!slug)
      errors.push(`O campo "Slug" é obrigatório`)

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${id ? 'Editar' : 'Cadastrar'} Configurações`}>
        <ReportIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const updateInputSwitch = (key: string, value: boolean): void => {
    setSlotMachineConfig(oldConfig => {
      return {
        ...oldConfig,
        [key]: !value
      }
    })
  }

  const updateInputNumber = (value: string, key: string): void => {
    if (parseInt(value) < 0) return
    if (key !== 'medalId' && value === '') return
    setSlotMachineConfig(oldConfig => {
      return {
        ...oldConfig,
        [key]: isNaN(parseInt(value)) ? null : parseInt(value)
      }
    })
  }

  const updateInputString = (value: string, key: string): void => {
    setSlotMachineConfig(oldConfig => {
      return {
        ...oldConfig,
        [key]: value
      }
    })
  }

  const updatePrizes = (
    prizeSlug: string,
    prizeRate: number,
    arrayIndex: number
    ): void => {
    setSlotMachineConfig(oldConfig => {
      const newPrizes = oldConfig.prizes
      const newPrize = {
        dropRate: prizeRate,
        slug: prizeSlug
      }
      newPrizes[arrayIndex] = newPrize
      return {
        ...oldConfig,
        prizes: newPrizes
      }     
    })
  }

  const addPrize = (): void => {
    setSlotMachineConfig(oldConfig => {
      const newPrize = {
        dropRate: 1,
        slug: productsList[0].slug
      }
      return {
        ...oldConfig,
        prizes: [...oldConfig.prizes, newPrize]
      }
    })
  }

  const removePrize = (index: number): void => {
    setSlotMachineConfig(oldConfig => {
      const newPrizes = oldConfig.prizes
      newPrizes.splice(index, 1)
      return {
        ...oldConfig,
        prizes: newPrizes
      }
    })
  }

  useEffect(() => {
    setIsLoading(true)
    if (id) {
      getConfig(id)
        .then(res => setSlotMachineConfig(res))
    }

    getProducts({ limit: 1999 })
      .then(res => setProductsList(res.results))
  }, [])

  useEffect(() => {
    if (!slotMachineConfig.prizes.length && productsList.length) {
      setSlotMachineConfig(oldConfig => {
        return {
          ...oldConfig,
          prizes: [{
            dropRate: 1,
            slug: productsList[0].slug
          }]
        }
      })
    }
  }, [productsList.length])

  useEffect(() => {
    setTotalDropRate(
      slotMachineConfig.prizes
        .map((prize) => prize.dropRate)
        .reduce((acc, value) => acc + value, 0)
    )
  }, [slotMachineConfig])

  useEffect(() => {
    if (productsList.length > 0) setIsLoading(false)
  }, [productsList])

  return (
    <Card>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            direction="column"
          >
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 800 }}>
                <Grid item xs={4}>
                  <Typography variant="caption" display="block" gutterBottom sx={{ textAlign: 'center' }}>
                    Status da feature
                  </Typography>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <label>Off</label>
                    <Switch
                      checked={slotMachineConfig.active}
                      onChange={() => updateInputSwitch('active', slotMachineConfig.active)}
                      color="primary"
                    />
                    <label>On</label>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    required
                    label="Slug"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateInputString(e.target.value, 'slug')}
                    placeholder="Slug"
                    value={slotMachineConfig.slug}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    required
                    label="Percentual de vitória"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateInputNumber(e.target.value, 'winRate')}
                    placeholder="Apenas numeros"
                    value={slotMachineConfig.winRate}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    required
                    label="Prêmio de consolação (silvers)"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateInputNumber(e.target.value, 'consolationPrizeValue')}
                    placeholder="Apenas numeros"
                    value={slotMachineConfig.consolationPrizeValue}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    required
                    label="Giros grátis"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateInputNumber(e.target.value, 'countFreeSpins')}
                    placeholder="Apenas numeros"
                    value={slotMachineConfig.countFreeSpins}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Medalha"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateInputNumber(e.target.value, 'medalId')}
                    value={slotMachineConfig.medalId || ''}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card variant='outlined' sx={{ width: 1000, marginBottom: 1 }}>
                    <HeaderTitle title='Prêmios'>
                      <TrophyIcon fontSize="medium" />
                    </HeaderTitle>
                    <CardContent>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            {!productsList.length ? (
                              'Carregando lista de produtos...'
                              ) : (
                              slotMachineConfig.prizes?.map((prize, prizeIndex) => (
                                <Grid
                                  key={prizeIndex}
                                  container
                                  spacing={2} sx={{ marginBottom: '2px', marginTop: '2px' }}
                                >
                                  <Grid item xs={8}>
                                    <Autocomplete
                                      value={productsList.find(product => product.slug === prize.slug)}
                                      onChange={(event: any, newValue: Product | null) => {
                                        updatePrizes(newValue.slug, prize.dropRate, prizeIndex)
                                      }}
                                      getOptionLabel={(option: Product) => `${option.name} (${option.slug})`}
                                      options={productsList}
                                      filterSelectedOptions
                                      renderInput={(params) => <TextField {...params} label="Produto" />}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField
                                      required
                                      label="Drop Rate (%)"
                                      variant="outlined"
                                      fullWidth
                                      onChange={(e) => updatePrizes(prize.slug, Number(e.target.value), prizeIndex)}
                                      placeholder="Apenas numeros"
                                      value={prize.dropRate}
                                      type="number"
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    {slotMachineConfig.prizes.length > 1 &&
                                      <Button
                                        color='error'
                                        variant='outlined'
                                        sx={{ height: '100%' }}
                                        onClick={(): void => removePrize(prizeIndex)}
                                      >
                                        Excluir
                                      </Button>
                                    }
                                  </Grid>
                                </Grid>
                              )
                            ))}
                            <Grid container spacing={2} sx={{ marginBottom: '2px', marginTop: '2px' }}>
                                <Grid item xs={8}></Grid>
                                <Grid item xs={2}>
                                  <TextField
                                    error={totalDropRate !== 100}
                                    required
                                    label="TOTAL (Drop rate) %"
                                    variant="outlined"
                                    fullWidth
                                    value={totalDropRate}
                                    type="number"
                                  />
                                </Grid>
                              </Grid>
                          </FormControl>
                          <Button onClick={(): void => addPrize()}>Adicionar Prêmio</Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} sx={{ mt: 2 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  Salvar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { SlotMachineConfig }
