import { useFormContext, Controller } from 'react-hook-form'
import { TextField } from '@mui/material'

// TYPES
import { TextFieldProps as Props } from './types'

const CustomTextField = (props: Props) => {
  const { name, label, type, disabled, numeric, complementaryOnChange } = props
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const formatValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => {
    if (numeric) {
      e.target.value = e.target.value.replace(/[^\d.]+/g, "")
    }

    return e
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { onChange } }) => (
        <TextField
          {...field}
          onChange={(value) => {
            onChange(formatValue(value))
            complementaryOnChange && complementaryOnChange()
          }}
          label={label}
          type={type || 'text'}
          disabled={disabled || false}
          fullWidth={true}
          error={!!errors[name]}
          helperText={errors[name]?.message}
        />
      )}
    />
  )
}

export { CustomTextField }
