 // API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  Season,
  CreateSeasonPayload,
  GetSeasonsResponse,
  CreateSeasonResponse,
  UpdateSeasonPayload,
  GetSeasonByIdResponse,
  UploadImageResponse
} from '@shared/types/SeasonTypes'
import { GetProductsResponse } from '@shared/types/ProductTypes'

const getSeasonById = (id: string): Promise<GetSeasonByIdResponse> => {
  return marketplaceApi.get(`/backoffice/season/${id}`)
}

const getSeasons = (data?): Promise<GetSeasonsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''
  return marketplaceApi.get(`/backoffice/season/${queryString}`)
}

const createSeasons = (data: CreateSeasonPayload): Promise<CreateSeasonResponse> => {
  return marketplaceApi.post('/backoffice/season', data)
}

const updateSeasons = (data: UpdateSeasonPayload): Promise<CreateSeasonResponse> => {
  return marketplaceApi.patch(`/backoffice/season/`, data)
}

const deleteSeasons = (id: string): Promise<Season> => {
  return marketplaceApi.delete(`/backoffice/season/${id}`)
}

const getProducts = (data?): Promise<GetProductsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/products/${queryString}`)
}

const uploadImage = (formData: FormData): Promise<UploadImageResponse> => {
  return marketplaceApi.post(
    `/backoffice/season/${formData.get('imageSlug')}/image`,
    formData
  )
}

export {
  getSeasonById,
  getSeasons,
  createSeasons,
  updateSeasons,
  deleteSeasons,
  getProducts,
  uploadImage
}