import { useState, useCallback, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Cropper from 'react-easy-crop'
import { Slider, Button, Paper } from '@mui/material'
import { useCropModal } from './useCropModal'
import { Output } from '../Output'
import * as Styled from './styles'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const CropModal = (props) => {
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
  })

  const [zoom, setZoom] = useState<number>(1)
  const [imgCropped, setImgCropped] = useState<string>('')

  const { createImage, getCroppedImg } = useCropModal()

  const { imagePreviewUrl, setImagePreviewUrl, setOpen } = props

  const onCropComplete = useCallback(
    async (_, croppedAreaPixels) => {
      const original = await createImage(imagePreviewUrl)
      const imgCropped64 = getCroppedImg(original, croppedAreaPixels)
      setImgCropped(imgCropped64)
    },
    [createImage, getCroppedImg]
  )

  useEffect(() => {
    setImgCropped(imagePreviewUrl)
  }, [imagePreviewUrl])

  const handleSubmit = () => {
    setImagePreviewUrl(imgCropped)
    setOpen(false)
  }

  return (
    <Styled.cropModalBody>
      <Styled.cropMain>
        <Cropper
          image={imagePreviewUrl}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Styled.cropMain>
      <Item>
        <Output croppedArea={imgCropped} />
      </Item>
      <Item>
        <Styled.footerModal>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, zoom) => setZoom(Number(zoom))}
          />
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Enviar
          </Button>
        </Styled.footerModal>
      </Item>
    </Styled.cropModalBody>
  )
}

export { CropModal }
