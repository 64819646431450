import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// COMPONENTS
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'
import { Loader } from '@components/Loader/Loader'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { CustomRichTextEditor } from '@components/shared/CustomRichTextEditor/CustomRichTextEditor'
import { CoreFields } from './CoreFields/CoreFields'
import { PaymentFields } from './PaymentFields/PaymentFields'
import { ComplementaryFields } from './ComplementaryFields/ComplementaryFields'
import { MetadataFields } from './MetadataFields/MetadataFields'
import { ToggleFields } from './ToggleFields/ToggleFields'

// VALIDATION
import { validationSchema } from './schema'

// SERVICES
import { getCategories } from '@services/Category/CategoryService'
import { getTags } from '@services/Tag/TagService'
import { getTrialTypes } from '@services/TrialType/TrialTypeService'
import { getCurrencies } from '@services/Currency/CurrencyService'
import { getPaymentRestrictions } from '@services/Payment/PaymentService'
import { getRegionsRestrictions } from '@services/Region/RegionService'
import { getPlanTypes } from '@services/PlanType/Product/PlanTypeService'
import { getAccountGroups } from '@services/Account/AccountService'
import {
  createPlan,
  updatePlan,
  getProduct,
  getCampaigns
} from '@services/Product/ProductService'

// TYPES
import { PlansFormFields, RestrictionType, PlanDefaultValues } from './types'
import { Option } from '@components/shared/CustomSelect/types'
import { PaymentRestrictionResponse } from '@shared/types/PaymentTypes'
import { PlanType } from '@shared/types/ProductTypes'
import { AccountGroup } from '@shared/types/AccountTypes'

// HELPERS
import { systems as systemOptions } from '../helpers/SystemsHelper'
import { buildProductPayload } from '../helpers/RequestsHelper'
import { setPlanFormValues, setPlanFormDefaultValues } from '../helpers/FormsHelper'
import * as Alert from '@shared/helpers/Alerts'

const PlansForm = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [categoryOptions, setCategoryOptions] = useState<Option[]>([])
  const [tagOptions, setTagOptions] = useState<Option[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<Option[]>([])
  const [paymentRestrictions, setPaymentRestrictions] = useState<PaymentRestrictionResponse>()
  const [regionRestrictions, setRegionRestrictions] = useState<RestrictionType>()
  const [planTypes, setPlanTypes] = useState<PlanType[]>([])
  const [accountGroups, setAccountGroups] = useState<AccountGroup[]>([])
  const [campaignOptions, setCampaignOptions] = useState([])
  const [trialTypesOptions, setTrialTypesOptions] = useState<any>([])
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'
  const navigate = useNavigate()

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: PlanDefaultValues,
  })

  const { handleSubmit, setValue } = methods

  useEffect(() => {
    if (id) {
      getPlan().then(():void => setIsLoading(false))
    } else {
      getFormSelectOptionsData().then(():void => setIsLoading(false))
    }
  }, [])

  useEffect(() => {
    if (!id) {
      setPlanFormDefaultValues(setValue)
    }
  }, [id])

  const getFormSelectOptionsData = async (): Promise<void> => {
    Promise.all([
      getCategories().then((response) =>
        setCategoryOptions(response.productCategories)
      ),
      getTags().then((response) => setTagOptions(response.tags)),
      getCurrencies().then((response) => setCurrencyOptions(response.currencies)),
      getPaymentRestrictions().then((response) => setPaymentRestrictions(response[0])),
      getRegionsRestrictions().then((response) => setRegionRestrictions(response[0])),
      getPlanTypes().then((response) => setPlanTypes(response.planTypes)),
      getAccountGroups({limit: 1999}).then((response) => setAccountGroups(response.results)),
      getCampaigns().then((response) => setCampaignOptions(response.campaigns)),
      setTrialTypesOptions(getTrialTypes())
    ]).then(() => true)
  }
  

  const getPlan = async (): Promise<void> => {
    try {
      await getFormSelectOptionsData()
      const productData = await getProduct(id)
      setPlanFormValues(productData, setValue)
    } catch (e) {
      Alert.error('Ocorreu um erro ao obter os dados do plano')
    }
  }

  const onSubmit = (data: PlansFormFields): void => {
    if (id) {
      _updatePlan(data)
    } else {
      _createPlan(data)
    }
  }

  const _createPlan = async (data: PlansFormFields): Promise<void> => {
    try {
      const payload = buildProductPayload(data)
      await createPlan(payload)
      Alert.success(
        'Plano criado com sucesso',
        navigate('/apps/marketplace/plans/list')
      )
    } catch (e) {
      Alert.error('Ocorreu um erro ao criar o plano')
    }
  }

  const _updatePlan = async (data: PlansFormFields): Promise<void> => {
    try {
      const payload = buildProductPayload(data, id)
      await updatePlan(payload)
      Alert.success(
        'Plano atualizado com sucesso',
        navigate('/apps/marketplace/plans/list')
      )
    } catch (e) {
      Alert.error('Ocorreu um erro ao atualizar o plano')
    }
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Plano`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  if (isLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Card sx={{ width: '100%'}}>
      <CardHeader title={renderCardHeader()} />
      <CardContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CoreFields categoryOptions={categoryOptions} systemOptions={systemOptions} tagOptions={tagOptions}/>
            <Grid container rowSpacing={4} columnSpacing={2} sx={{ mt: 0.25 }}>
              <Grid item xs={12}>
                <CustomRichTextEditor label="Descrição" name="description" required={true}/>
              </Grid>
              <PaymentFields currencyOptions={currencyOptions} paymentRestrictions={paymentRestrictions}/>
              <ComplementaryFields 
                campaignOptions={campaignOptions} 
                regionRestrictions={regionRestrictions}
                planTypes={planTypes} 
                accountGroups={accountGroups}
                trialTypes={trialTypesOptions} />
              <MetadataFields />
              <ToggleFields />
            </Grid>
            <Grid container>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                >
                  <Button type="submit" variant="contained">
                    {buttonLabelAction}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  )
  
}

export { PlansForm }
