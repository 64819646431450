import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getBanners,
  deleteBanners
} from '@services/Banner/BannerService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { Button, Card, Chip, LinearProgress, styled, Tooltip, Typography } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

// TYPES
import { Banner, GetBannersResponse, GetBannerQueryParams } from '@shared/types/BannerTypes'

// STYLES
import * as Styled from './BannerList.styles'

const BannerList = (): JSX.Element => {
  const [banners, setBanners] = useState<Banner[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = {
      page: 1,
      limit: 10
    }
    _getBanners(queryParams)
  }, [])

  const _getBanners = async (queryParams: GetBannerQueryParams): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const bannersData: GetBannersResponse = await getBanners(queryParams)
      setBanners(bannersData.results)
      setRowCount(bannersData.pagination.totalItems)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _deleteBanners = async (id: string): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir o banner?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteBanners(id)
          Alert.success(
            'Banner excluído com sucesso',
            removeBannersFromState(id)
          )
        }
      })
      .catch((e) => {
        Alert.error('Ocorreu um erro ao excluir o banner')
      })
  }

  const removeBannersFromState = (id: string): void => {
    const newBanner = banners.filter(
      (banner) => banner.id !== id
    )
    setBanners(newBanner)
  }
  
  const renderCustomLoadingOverlay = ():JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    );
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): void => navigate(`/apps/marketplace/banner/${id}`)}
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Editar
        </Button>
        <Button
          onClick={(): Promise<void> => _deleteBanners(id)}
          color="error"
          variant="contained"
        >
          Excluir
        </Button>
      </>
    )
  }

  const CustomChip = styled(Chip)(({ theme }) => ({
    color: 'white',
  }))
  
  const renderIsActiveBanner = (isActive: boolean): JSX.Element => {
    return (
      <CustomChip
        label={isActive ? 'Ativo' : 'Desativado'}
        variant="filled"
        color={isActive ? 'success' : 'error'}
      />
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 200
    },
    {
      field: 'backgroundImage',
      headerName: 'Imagem',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'left',
      align: 'left',
      minWidth: 300,
      renderCell: (params) => (
        <Styled.BannerImage src={params.value} />
      )
    },
    {
      field: 'title',
      headerName: 'Nome',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 250,
      renderCell: (params) => {
        const { description } = params.row

        if (description) {
          return (
            <Tooltip title={description} placement="top">
            <Typography variant="caption" display="block" gutterBottom>
              <Button color="success">
                Passe o mouse para ver a descrição completa
              </Button>
            </Typography>
          </Tooltip>
          )
        }

        return <></>
      },
    },
    {
      field: 'isActive',
      headerName: 'Ativo',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => renderIsActiveBanner(params.row.isActive)
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Banners">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
    {banners?.length > 0 ? (
      <Card
        sx={{
          mt: 4,
          ['& .MuiDataGrid-iconSeparator']: {
            display: 'none'
          }
        }}
      >
        <DataGrid
          paginationMode="server"
          rowCount={rowCount}
          rows={banners}
          columns={columns}
          loading={refreshDataLoading}
          getRowId={(row) => row.id}
          pageSize={10}
          rowsPerPageOptions={[4]}
          autoHeight
          rowHeight={80}
          disableSelectionOnClick
          components={{
            LoadingOverlay: renderCustomLoadingOverlay,
          }}
          sx={{
            ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
              outline: 'none'
            },
            ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
              whiteSpace: 'break-spaces'
            },
          }}
          onPageChange={(page): Promise<void> => _getBanners({
            page: page + 1,
            limit: 10
          })}
        />
      </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { BannerList }
