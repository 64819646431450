// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import { SlotMachineConfigProps } from '@shared/types/SlotMachineTypes'
import { Paginated } from '@shared/types/Paginated'

export const getConfigs = (): Promise<Paginated<SlotMachineConfigProps>> => {
  return marketplaceApi.get(`/backoffice/slotMachine/config`)
}

export const getConfig = (configId: string): Promise<SlotMachineConfigProps> => {
  return marketplaceApi.get(`/backoffice/slotMachine/config/${configId}`)
}

export const createConfig = (
  data: SlotMachineConfigProps
): Promise<SlotMachineConfigProps> => {
  return marketplaceApi.post(`/backoffice/slotMachine/config/`, data)
}

export const updateConfig = (
  data: SlotMachineConfigProps,
  configId: string
): Promise<SlotMachineConfigProps> => {
  return marketplaceApi.patch(`/backoffice/slotMachine/config/${configId}`, data)
}

export const removeConfig = (configId: string): Promise<void> => {
  return marketplaceApi.delete(`/backoffice/slotMachine/config/${configId}`)
}
