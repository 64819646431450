import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

// SERVICES
import {
  getProducts,
  getPartners,
  getCampaign,
  createVoucher,
} from '@services/Voucher/VoucherService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'

import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'

import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

const VoucherForm = (): JSX.Element => {
  const navigate = useNavigate()
  const [campaignId, setCampaignId] = useState<string>('')
  const [partnerId, setPartnerId] = useState<string>('')
  const [productId, setProductId] = useState<string>('')
  const [medalId, setMedalId] = useState<number>()
  const [hashesQuantity, setHashesQuantity] = useState<number>()
  const [slug, setSlug] = useState<string>('')
  const [campaignItems, setCampaignItems] = useState([])
  const [partnersItems, setPartnersItems] = useState([])
  const [productsItems, setProductsItems] = useState([])

  useEffect(() => {
    getCampaign().then((response) => setCampaignItems(response.campaigns))
    getPartners().then((response) => setPartnersItems(response.partners))
    getProducts().then((response) => setProductsItems(response.products))
  }, [])

  useEffect(() => {
    if (!productId) return

    const abc = productsItems.find((product) => product.id === productId)
    setSlug(abc.slug)
  }, [productId])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    _createVoucher()
  }

  const _createVoucher = async (): Promise<void> => {
    try {
      const payload = {
        productId,
        slug,
        campaignId,
        partnerId,
        medalId,
        hashesQuantity,
      }

      const createVoucherResponse = await createVoucher(payload)
      const { inserted } = createVoucherResponse
      Alert.success(
        `${inserted} vouchers criados com sucesso`,
        navigate('/apps/marketplace/voucher')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar a criação do voucher')
    }
  }

  const renderCampaignOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.title}
        </MenuItem>
      ))
    )
  }

  const renderPartnersOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  const renderProductsOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!campaignItems.length) errors.push('O campo Campanha é obrigatório')
    if (!partnersItems.length) errors.push('O campo Parceiro é obrigatório')
    if (!productsItems.length) errors.push('O campo Produto é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Criação de Voucher">
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  return (
    <Card>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="campaign-label">Campanha</InputLabel>
                <Select
                  required
                  key="campaignId"
                  value={campaignId}
                  onChange={(e) => setCampaignId(e.target.value)}
                  input={<OutlinedInput label="Name" />}
                >
                  {renderCampaignOptions(campaignItems)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="product-label">Produto</InputLabel>
                <Select
                  required
                  key="productId"
                  value={productId}
                  onChange={(e) => setProductId(e.target.value)}
                  input={<OutlinedInput label="Name" />}
                >
                  {renderProductsOptions(productsItems)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="partner-label">Parceiro</InputLabel>
                <Select
                  required
                  key="partnerId"
                  value={partnerId}
                  onChange={(e) => setPartnerId(e.target.value)}
                  input={<OutlinedInput label="Name" />}
                >
                  {renderPartnersOptions(partnersItems)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs>
              <InputLabel>Medalha ID</InputLabel>
              <TextField
                key="medalId"
                type="number"
                value={medalId}
                onChange={(e) => setMedalId(Number(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              <InputLabel>Quantas chaves</InputLabel>
              <TextField
                key="hashesQuantity"
                type="number"
                value={hashesQuantity}
                onChange={(e) => setHashesQuantity(Number(e.target.value))}
              />
            </Grid>
            <Grid item xs sx={{ mt: 5 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  Criar voucher
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { VoucherForm }
