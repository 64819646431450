import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import {
  getTagById,
  createTag,
  updateTag
} from '@services/Tag/TagService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  Switch,
  FormControlLabel,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon }  from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

const TagsForm = (): JSX.Element => {
  const [name, setName] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [showInMenu, setShowInMenu] = useState<boolean>(false);
  const [showInStore, setShowInStore] = useState<boolean>(false);
  const [priority, setPriority] = useState<number>();

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    if (id) {
      _getTags()
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    if (id) {
      _updateTags() 
    } else {
      _createTags()
    }
  }

  const _createTags = async (): Promise<void> => {
    try {
      const payload = {
        name,
        slug,
        description,
        showInMenu,
        showInStore,
        priority
      }

      await createTag(payload)

      Alert.success(
        'Tag criada com sucesso',
        navigate('/apps/marketplace/tag/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar a criação da tag')
    }
  }

  const _updateTags = async (): Promise<void> => {
    try {
      const tagId = Number(id)
      const payload = {
        id: tagId,
        name,
        slug,
        description,
        showInMenu,
        showInStore,
        priority
      }

      await updateTag(payload)

      Alert.success(
        'Tag de compra alterada com sucesso',
        navigate('/apps/marketplace/tag/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao alterar a tag')
    }
  }

  const _getTags = async (): Promise<void> => {
    try {
      const tagsData = await getTagById(id)

      if (tagsData) {
        setName(tagsData.name || '')
        setSlug(tagsData.slug || '')
        setDescription(tagsData.description || '')
        setShowInMenu(tagsData.showInMenu || false)
        setShowInStore(tagsData.showInStore || false)
        setPriority(tagsData.priority || null)
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar a tag')
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!name.length) errors.push('O campo Nome é obrigatório')
    if (!slug.length) errors.push('O campo Slug é obrigatório')
    if (!description.length) errors.push('O campo Descrição é obrigatório')


    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Tag`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  return (
    <Card sx={{ width: 600 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{p: 4}}>
        <form onSubmit={handleSubmit}> 
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label="Nome"
                variant="outlined"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Slug"
                variant="outlined"
                fullWidth
                onChange={(e) => setSlug(e.target.value)}
                value={slug}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Descrição"
                variant="outlined"
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs={6}>
              <FormControlLabel 
                control={
                  <Switch
                    checked={showInMenu}
                    onChange={(e) => setShowInMenu(e.target.checked)}
                    color="primary"
                  />
                } 
                label="Mostrar no Menu"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel 
                control={
                  <Switch
                    checked={showInStore}
                    onChange={(e) => setShowInStore(e.target.checked)}
                    color="primary"
                  />
                } 
                label="Mostrar na Loja"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Prioridade"
                variant="outlined"
                fullWidth
                onChange={(e) => setPriority(Number(e.target.value))}
                value={priority}
              />
            </Grid>
          </Grid>
            <Grid container spacing={2} sx={{ mt: 0.2 }}>
              <Grid item xs sx={{ mt: 5 }}>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                  >
                  <Button variant="contained" type="submit">
                    {buttonLabelAction} Tag
                  </Button>
                </Box>
              </Grid>
            </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { TagsForm }
