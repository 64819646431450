import styled from 'styled-components'

// COMPONENTS
import { InputLabel } from '@mui/material'

export const Container = styled.div``

export const Label = styled(InputLabel)`
  margin-top: 16px;
`

export const Checkboxes = styled.div`
  margin-left: 16px;
`

export const SeeAll = styled.span`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`
