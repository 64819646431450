import styled from 'styled-components'

export const modalMain = styled.div`
  width: 90%;
  max-width: 520px;
  padding: 48px 0;
`

export const footerModal = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`

export const cropMain = styled.div`
  position: relative;
  height: 255px;
`

export const cropModalBody = styled.div`
  padding: 8px;
`
