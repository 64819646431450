import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Buffer } from 'buffer'

// SERVICES
import {
  createOnboardingRewards,
  getProducts,
} from '@services/OnboardingRewards/OnboardingRewardsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'
import RichTextEditor from '@shared/helpers/RichTextEditor'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  FormControl,
  Autocomplete,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

// TYPES
import { Product } from '@shared/types/ProductTypes'

const OnboardingRewardsForm = (): JSX.Element => {
  const [productId, setProductId] = useState<Product>()
  const [quantity, setQuantity] = useState<number>()
  const [description, setDescription] = useState('')
  const [productsItems, setProductsItems] = useState<Product[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    getProducts({
      limit: 1999,
    }).then((response) => setProductsItems(response.results))
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }

    _createOnboardingRewards()
  }

  const _createOnboardingRewards = async (): Promise<void> => {
    try {
      const base64Description = Buffer.from(
        description,
        'binary'
        ).toString('base64')
      const payload = {
        productId: productId.id,
        quantity,
        description: base64Description,
      }
      
      await createOnboardingRewards(payload)

      Alert.success(
        'Recompensa criada com sucesso',
        navigate('/apps/marketplace/onboarding/list')
      )
    } catch (err) {
      Alert.error(`${err.errors}`)
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!productId) errors.push('O campo Produto é obrigatório')
    if (!quantity) errors.push('O campo Quantidade é obrigatório')
    if (!description.length) errors.push('O campo Descrição é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`Cadastrar Recompensas`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                onChange={(event: any, newValue: Product) => {
                  setProductId(newValue)
                }}
                options={productsItems}
                getOptionLabel={(option: Product) => `${option.name} (${option.slug})`}
                renderInput={(params) => (
                  <TextField {...params} label="Premiação" />
                )}
              />
            </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Quantidade"
                variant="outlined"
                type="number"
                fullWidth
                onChange={(e) => setQuantity(Number(e.target.value))}
                value={quantity}
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                value={description}
                outputFormat="html"
                onChange={(content) =>
                  setDescription(content)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs sx={{ mt: 3 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  Cadastrar Recompensa
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { OnboardingRewardsForm }
