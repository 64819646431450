import * as yup from 'yup'

const requiredFieldText = (fieldName?: string): string =>
  `O campo ${fieldName} é obrigatório`
const finalDateBiggerThanInitialText: string =
  'Data de Fim precisa ser maior que Data de Início'

export const validationSchema = yup.object().shape({
  finalDate: yup
    .date()
    .min(yup.ref('initialDate'), finalDateBiggerThanInitialText)
    .nullable()
    .required(requiredFieldText('Data de Fim')),
  initialDate: yup
    .date()
    .nullable()
    .required(requiredFieldText('Data de Início')),
  targetPlayerSubscriptionLevel: yup
    .string()
    .required(requiredFieldText('targetPlayerSubscriptionLevel')),
  title: yup
    .string()
    .required(requiredFieldText('Título')),
  slug: yup
    .string()
    .required(requiredFieldText('Slug')),
  type: yup
    .string()
    .required(requiredFieldText('Tipo')),
})