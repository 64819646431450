// STYLES
import * as Styled from './styles'

// TYPES
import { CustomErrorProps as Props } from './types'


const CustomError = (props: Props) => {
  const { message, centered } = props

  return (
    <Styled.CustomErrorWrapper centered={centered}>
      {message}
    </Styled.CustomErrorWrapper>
    
  )
}

export { CustomError }
