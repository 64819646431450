import { useFormContext, Controller } from 'react-hook-form'
import { TextField } from '@mui/material'
import { 
  DatePicker as LegacyDatePicker,
} from '@mui/lab'
import { DateTimePicker, LocalizationProvider, DatePickerProps, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/pt-br'
import dayjs, { Dayjs } from 'dayjs'

export interface CustomDatePickerProps extends DatePickerProps<Dayjs> {
  label: string
  size?: 'small' | 'medium'
}

const CustomDatePicker = (props) => { // eslint-disable-line
  const { name, label, type, disabled, numeric } = props
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Controller
        name={name}
        control={control}
        render={({ field, field: { onChange } }) => (
            <>
                <LegacyDatePicker
                    label={label}
                    value={dayjs(field.value)}
                    onChange={value => onChange(value)}
                    renderInput={params =>
                        <TextField
                            label={label}
                            fullWidth={true}
                            {...params}
                            error={!!errors[name]}
                            helperText={errors[name]?.message} />}
                />
            </>
        )}
        />
    </LocalizationProvider>
  )
}

const CustomDateTimePicker = (props) => { // eslint-disable-line
    const { name, label, type, disabled, numeric } = props
    const {
      control,
      formState: { errors },
    } = useFormContext()
  
    const formatValue = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => {
      if (numeric) {
        e.target.value = e.target.value.replace(/[^\d.]+/g, "")
      }
  
      return e
    }
  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Controller
        name={name}
        control={control}
        render={({ field, field: { onChange } }) => (
          <>
            <DateTimePicker
              ampm={false}
              label={label}
              value={dayjs(field.value)}
              onChange={value => onChange(value)}
            />
          </>
        )} />
      </LocalizationProvider>
    )
  }

  export const NoContextCustomDatePicker: React.FC<CustomDatePickerProps> = ({
    label,
    value,
    onChange,
    disabled,
    minDate,
    size = 'small',
  }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <DatePicker
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          minDate={minDate}
          slotProps={{ textField: { size } }}
        />
      </LocalizationProvider>
    )
  }
  

export { CustomDatePicker, CustomDateTimePicker }
