import { useFormContext } from 'react-hook-form'

import {
  useState,
  useEffect
} from 'react'

// COMPONENTS
import { Grid } from '@mui/material'
import { CustomSelect } from '@components/shared/CustomSelect/CustomSelect'
import { CustomTextField } from '@components/shared/CustomTextField/CustomTextField'

// TYPES
import { PaymentFieldsProps } from './types'
import { RestrictionOption, initialRestrictionOptionState } from '../types'

const PaymentFields = (props: PaymentFieldsProps) => {
  const {
    watch,
    setValue
  } = useFormContext()

  const selectedPaymentMethods = watch('paymentMethods')

  const { currencyOptions, paymentRestrictions } = props
  const paymentMethods = paymentRestrictions?.restrictions || []

  const [binGroups, setBinGroups] = useState<RestrictionOption>(initialRestrictionOptionState)
  const [brands, setBrands] = useState<RestrictionOption>(initialRestrictionOptionState)

  const coinsCurrencies = ['Hard Coin', 'Soft Coin']
  const monetaryCurrencyOptions = currencyOptions.filter((currency) => !coinsCurrencies.includes(currency.name))

  useEffect(() => {
    if (!selectedPaymentMethods.includes('CREDIT_CARD')) {
      setValue('binGroups', [])
      setValue('cardBrands', [])
    }
  }, [selectedPaymentMethods])

  useEffect(() => {
    if (!paymentRestrictions) return

    setBinGroups(
      paymentMethods.find(restriction => restriction.id === 'CREDIT_CARD')
        .options.find(option => option.id === 'binGroup')
    )
    setBrands(
      paymentMethods.find(restriction => restriction.id === 'CREDIT_CARD')
        .options.find(option => option.id === 'brand')
    )
  }, [paymentRestrictions])

  return (
    <>
      <Grid item xs={3}>
        <CustomSelect name="currencyId" id="currencyId" label="Moeda *" options={monetaryCurrencyOptions}/>
      </Grid>
      <Grid item xs={3}>
        <CustomTextField numeric={true} name="price" label="Valor *" />
      </Grid>
      <Grid item xs={6}>
        <CustomSelect multiple={true} name="paymentMethods" id="paymentMethods" label="Métodos de pagamento *" options={paymentMethods}/>
      </Grid>
      {selectedPaymentMethods.includes('CREDIT_CARD') && (
        <>
          <Grid item xs={6}>
            <CustomSelect multiple={true} name="binGroups" id="binGroups" label="Grupos de BIN" options={binGroups.values}/>
          </Grid>
          <Grid item xs={6}>
            <CustomSelect multiple={true} name="cardBrands" id="cardBrands" label="Bandeiras" options={brands.values}/>
          </Grid>
        </>
      )}
    </>
  )
}

export { PaymentFields }