// API
import { paymentApi } from '@shared/api/Payment/PaymentApi'

// TYPES
import {
  PaymentRestrictionResponse
} from '@shared/types/PaymentTypes'

const getPaymentRestrictions = (): Promise<PaymentRestrictionResponse[]> => {
  return paymentApi.get(`/payment/restrictions`)
}

export { getPaymentRestrictions }
