export interface PlansFormFields {
  name: string;
  slug: string;
  systemId: string;
  categoryId: string;
  tags: number[];
  picture: string;
  description: string;
  currencyId: string;
  price: string;
  paymentMethods: string[];
  binGroups: string[];
  cardBrands: string[];
  regions: string[];
  planType: string;
  activationTimeValue?: string;
  monthlyValue?: string;
  accountGroups: string[],
  subscriptionId: string,
  monthlySavings?: string,
  medalId?: string,
  discountText?: string,
  passId?: string,
  isListed: boolean,
  inventoryItem: boolean,
  isActive: boolean,
  giftable: boolean,
  isFeatured: boolean,
  hasNewImage: boolean,
  campaignId: string,
  trialType?: string;
  trialDays?: string;
  nextProductId?: string;
}

export interface RestrictionType {
  id: string;
  name: string;
  restrictions: Restriction[];
}

export interface Restriction {
  id?: string | number;
  name: string;
  options?: RestrictionOption[];
}

export interface RestrictionOption {
  id: string;
  name: string;
  values: RestrictionValue[]
}

export interface RestrictionValue {
  id: string;
  name: string;
}

export const initialRestrictionOptionState: RestrictionOption = {
  id: '',
  name: '',
  values: []
}

export const PlanDefaultValues = {
  name: '',
  slug: '',
  categoryId: '',
  systemId: '',
  tags: [],
  picture: '',
  description: '',
  currencyId: '',
  price: '',
  paymentMethods: [],
  binGroups: [],
  cardBrands: [],
  regions: [],
  planType: '',
  accountGroups: [],
  subscriptionId: '',
  monthlyValue: '',
  activationTimeValue: '',
  monthlySavings: undefined,
  medalId: undefined,
  discountText: undefined,
  passId: undefined,
  isListed: false,
  inventoryItem: false,
  isActive: false,
  giftable: false,
  isFeatured: false,
  hasNewImage: false,
  campaignId: '0',
  trialType: '',
  trialDays: undefined,
  nextProductId: undefined
}