const buildQueryParams = (paramsObj):string => {
  const params = new URLSearchParams()
  Object.keys(paramsObj).forEach((key) => {
      const value = paramsObj[key as keyof object]
      if (Array.isArray(value)) {
      // eslint-disable-next-line
      // @ts-ignore
      value.forEach((v) => {
          params.append(`${key}[]`, v)
      })
      } else {
      if (value) {
          params.append(key, value)
      }
      }
  })
  return params.toString()
}

export { buildQueryParams }