// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  ProfileCampaign,
  GetProfileCampaignsResponse,
  CreateProfileCampaignResponse,
  CreateProfileCampaignPayload,
  UpdateProfileCampaignPayload,
} from '@shared/types/ProfileCampaignTypes'
import { GetProductsResponse } from '@shared/types/ProductTypes'

const getProfileCampaign = (id: string): Promise<ProfileCampaign> => {
  return marketplaceApi.get(`/backoffice/carousel/user/${id}`)
}

const getProfileCampaigns = (data?): Promise<GetProfileCampaignsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''
  return marketplaceApi.get(`/backoffice/carousel/user/${queryString}`)
}

const createProfileCampaign = (
  data: CreateProfileCampaignPayload
): Promise<CreateProfileCampaignResponse> => {
  return marketplaceApi.post('/backoffice/carousel/user', data)
}

const updateProfileCampaign = (
  data: UpdateProfileCampaignPayload
): Promise<CreateProfileCampaignResponse> => {
  return marketplaceApi.put(`/backoffice/carousel/user/${data.id}`, data)
}

const deleteProfileCampaign = (id: string): Promise<ProfileCampaign> => {
  return marketplaceApi.delete(`/backoffice/carousel/user/${id}`)
}

const getProducts = (data?): Promise<GetProductsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''
  return marketplaceApi.get(`/backoffice/products/${queryString}`)
}

export {
  getProfileCampaign,
  getProfileCampaigns,
  createProfileCampaign,
  updateProfileCampaign,
  deleteProfileCampaign,
  getProducts,
}
