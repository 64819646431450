import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Buffer } from 'buffer'

// SERVICES
import {
  getProduct,
  createProduct,
  updateProduct,
  uploadImage
} from '@services/Product/ProductService'
import { getCategories } from '@services/Category/CategoryService'
import { getTags } from '@services/Tag/TagService'
import { getPlanTypes } from '@services/PlanType/Product/PlanTypeService'
import { getRegionsRestrictions } from '@services/Region/RegionService'
import { getAccountGroups } from '@services/Account/AccountService'
import { getPurchaseTerms } from '@services/PurchaseTerm/PurchaseTermService'
import { getCurrencies } from '@services/Currency/CurrencyService'
import { getPaymentRestrictions } from '@services/Payment/PaymentService'

// ENV
import { BACKOFFICE_URL } from '@config/environment'

// COMPONENTS
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  FormControl,
  TextField,
  Typography,
} from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { FormSelect } from './components/FormSelect/FormSelect'
import { RegionRestrictions } from './components/RegionRestrictions/RegionRestrictions'
import { AccountGroups } from './components/AccountGroups/AccountGroups'
import { Bundles } from './components/Bundles/Bundles'
import { Metadata } from './components/Metadata/Metadata'
import { ToggleOptions } from './components/ToggleOptions/ToggleOptions'
import { PurchaseTerms } from './components/PurchaseTerms/PurchaseTerms'
import { PaymentRestrictions } from './components/PaymentRestrictions/PaymentRestrictions'

// TYPES
import {
  Restrictions,
  initialRestrictionsState,
} from '@shared/types/RestrictionsTypes'
import {
  Product,
  initialProductState,
  PurchaseTerm,
  ProductPrice,
  PlanType,
  UploadImageResponse
} from '@shared/types/ProductTypes'
import { Currency, CurrencyEnum } from '@shared/types/CurrencyTypes'
import { BundleProduct } from './components/Bundles/types'
import { productTypes } from './components/Metadata/types'
import { PaymentRestrictionResponse } from '@shared/types/PaymentTypes'
import { Category } from '@shared/types/CategoryTypes'
import { Tag } from '@shared/types/TagTypes'
import { RestrictionList } from '@shared/types/RegionTypes'
import { AccountGroup } from '@shared/types/AccountTypes'

// HELPERS
import { systems } from '@shared/helpers/SystemsHelper'
import { ImageUpload } from '@shared/components/ImageUpload/ImageUpload'
import RichTextEditor from '@shared/helpers/RichTextEditor'
import * as Alert from '@shared/helpers/Alerts'

const ProductForm = (): JSX.Element => {
  const [product, setProduct] = useState<Product>(initialProductState)
  const [categories, setCategories] = useState<Category[]>([])
  const [tags, setTags] = useState<Tag[]>([])
  const [planTypes, setPlanTypes] = useState<PlanType[]>([])
  const [regionRestrictions, setRegionRestrictions] = useState<RestrictionList[]>([])
  const [accountGroups, setAccountGroups] = useState<AccountGroup[]>([])
  const [bundleProducts, setBundleProducts] = useState<BundleProduct[]>([])
  const [restrictions, setRestrictions] = useState<Restrictions>(initialRestrictionsState)
  const [purchaseTerms, setPurchaseTerms] = useState<PurchaseTerm[]>([])
  const [productType, setProductType] = useState<string>('')
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [paymentRestrictions, setPaymentRestrictions] = useState<PaymentRestrictionResponse>()
  const [renderForm, setRenderForm] = useState<boolean>(false)
  const [unknownType, setUnknownType] = useState<boolean>(true)
  const [cloneProduct, setCloneProduct] = useState<boolean>(false)
  const [image, setImage] = useState<File>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [originalMetadata, setOriginalMetadata] = useState<any>()

  const { id } = useParams()
  const formTitleAction = id
    ? (cloneProduct ? 'Clonar' : 'Alterar')
    : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'
  const navigate = useNavigate()

  const updateProductState = (
    productKey: string,
    value: any,
    callback?: () => void
  ): void => {
    setProduct((prev) => {
      return { ...prev, [productKey]: value }
    })
    callback && callback()
  }

  const updateCurrencyValue = (
    currencyType: CurrencyEnum,
    value: string
  ): void => {
    setProduct((prev) => {
      const updateIndex = prev.prices.findIndex(
        (price) => price.currencyId === currencyType
      )
      const newPrices = { ...prev }

      if (updateIndex === -1) {
        newPrices.prices.push({
          currencyId: currencyType,
          price: isMoney(currencyType) ? value.replace(/,/g, '.') : parseInt(value),
        })
      } else {
        if (value === '') {
          newPrices.prices.splice(updateIndex, 1)
        } else {
          newPrices.prices[updateIndex].price = isMoney(currencyType)
            ? value.replace(/,/g, '.')
            : parseInt(value)
        }
      }

      return newPrices
    })
  }

  const isMoney = (currency: number): boolean => {
    return currency !== CurrencyEnum['GOLD'] &&
      currency !== CurrencyEnum['SILVER']
  }

  const goldValue = product.prices.find(
    price => price.currencyId === CurrencyEnum['GOLD']
  )?.price

  const silverValue = product.prices.find(
    price => price.currencyId === CurrencyEnum['SILVER']
  )?.price

  const handleQuantity = (value: number) => {
    if (value >= 0) {
      updateProductState('quantity', value)

      if (goldValue === 0 && silverValue === 0) {
        updateProductState('redeemable', true)
      }
    } else {
      updateProductState('quantity', null)
    }
  }

  const handleUploadImage = (rawFile: File): void => {
    if (!rawFile) return

    const maxSizeAllowed = 15000000
    if (rawFile.size > maxSizeAllowed) {
      Alert.error('A imagem excede o tamanho permitido')
      return
    }

    setImage(rawFile)
  }

  const _uploadImage = async (): Promise<UploadImageResponse> => {
    const formData = new window.FormData()
    formData.append('file', image)
    formData.append('productSlug', product.slug)
    return await uploadImage(formData)
  }

  const handleClone = (): void => {
    setCloneProduct(true)
    setImage(null)
    setProduct(previous => {
      return {
        ...previous,
        name: '',
        pictures: [],
        slug: '',
      }
    })
    window.scrollTo(0, 0)
  }

  const handleResetMetadata = (): void => {
    setProductType(originalMetadata.productType)
    setProduct(prev => ({
      ...prev,
      metadata: originalMetadata
    }))
  }

  const handleSubmit = async (): Promise<void> => {
    if (goldValue === 0 && silverValue === 0) {
      const prizeConfirmation = await Alert.confirm(
        `O preço em golds e silvers é zero.
        Nesse caso o produto será tratado como um produto resgatável/grátis. Continuar?`
      )
      if (prizeConfirmation.isDismissed) return
    }

    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }

    setLoading(true)

    if (id && !cloneProduct) {
      _updateProduct()
    } else {
      if (id && product.isListed) {
        Alert.confirm(
          `Este produto está sendo clonado e será colocado a venda imediatamente.
          Por favor confirme ou altere o campo "Item está sendo exibido"`
        )
        .then((response) => {
          response.isConfirmed ? _createProduct() : setLoading(false)
        })
      } else {
        _createProduct()
      }
    }
  }

  const createPayload = (
    rawProduct: Product,
    newImage?: string
  ): Product => {
    const base64Description = Buffer.from(
      rawProduct.description,
      'binary'
    ).toString('base64')
    const checkChildren = rawProduct.children.length === 0 ? undefined : rawProduct.children
    const filteredPrices = rawProduct.prices
      .filter(price => price.price !== '0')
      .map(price => {
          return {
            ...price,
            price: Number(price.price)
          }
      })
    if (id && cloneProduct) delete rawProduct.id
    delete rawProduct.campaignId
    delete rawProduct.category
    delete rawProduct.childrenProducts
    delete rawProduct.system

    return {
      ...rawProduct,
      children: checkChildren,
      description: base64Description,
      pictures: newImage ? [{ url: newImage }] : rawProduct.pictures,
      prices: filteredPrices,
      purchaseTerms: rawProduct.purchaseTerms
    }
  }

  const _createProduct = async (): Promise<void> => {
    try {
      const newImage = await _uploadImage()
      await createProduct(createPayload(product, newImage.url))
      setProduct(initialProductState)
      Alert.success(
        'Produto criado com sucesso',
        navigate('/apps/marketplace/product/list')
      )
      setLoading(false)
    } catch(err) {
      Alert.error(err.errors[0])
      setLoading(false)
    }
  }

  const _updateProduct = async (): Promise<void> => {
    try {
      const newImage = image ? await _uploadImage() : { url: '' }
      await updateProduct(createPayload(product, newImage.url))
      setProduct(initialProductState)
      Alert.success(
        'Produto alterado com sucesso',
        navigate('/apps/marketplace/product/list')
      )
      setLoading(false)
    } catch(err) {
      Alert.error(err.errors[0])
      setLoading(false)
    }
  }

  const getFormFieldsData = async (): Promise<void> => {
    Promise.all([
      getCategories().then((response) => setCategories(response.productCategories.sort((a, b) => a.name > b.name ? 1 : -1))),
      getTags().then((response) => setTags(response.tags.sort((a, b) => a.name > b.name ? 1 : -1))),
      getPlanTypes().then((response) => setPlanTypes(response.planTypes)),
      getRegionsRestrictions().then((response) => setRegionRestrictions(response)),
      getAccountGroups().then((response) => setAccountGroups(response.results)),
      getPurchaseTerms().then((response) => setPurchaseTerms(response.results)),
      getPaymentRestrictions().then((response) => setPaymentRestrictions(response[0])),
      getCurrencies().then((response) =>
        setCurrencies([
          { id: 0, name: 'N/A', symbol: 'N/A' },
          ...response.currencies,
        ])
      ),
    ])
    .then(() => true)
  }

  const _getProduct = async (): Promise<void> => {
    try {
      getFormFieldsData()
      const productData = await getProduct(id)

      if (productData) {
        setBundleProducts(productData.childrenProducts)
        setOriginalMetadata(productData.metadata)
        setProduct({
          ...productData,
          categoryId: productData.category.id,
          children: productData.childrenProducts,
          purchaseTerms: productData.purchaseTerms.map(term => term.id),
          systemId: productData.system.id,
          tags: productData.tags.map(tag => tag.id)
        })

        if (productData.metadata.productType) setUnknownType(false)
        setProductType(productData.metadata.productType || '')
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar o produto')
    }
  }

  const validateFields = (): string[] => {
    const {
      description,
      pictures,
      name,
      quantity,
      redeemable
    } = product
    const errors = []

    if (!name.trim().length) errors.push('O campo nome é obrigatório')
    if (!description || !description.trim().length) errors.push('O campo descrição é obrigatório')
    if (!image && !pictures.length) errors.push('O produto precisa ter uma imagem')
    if (!productType) errors.push('Selecione o tipo do produto')
    if (!product.prices.length) errors.push('O produto precisa ter um preço')
    if (productType === 'ratingPointsItems' && isNaN(product.metadata?.multiplyFactor)) errors.push('Selecione um multiplicador')
    if (quantity > 0 && redeemable && (goldValue !== 0 || silverValue !== 0)) errors.push('Para um produto com estoque ser grátis é necessário alterar o valor de Golds e Silvers para 0')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Produto`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const getPriceByEnum = (currencyEnum: string): ProductPrice => {
    return product.prices?.find(
      price => price.currencyId === CurrencyEnum[currencyEnum]
    ) ||
    {
      currencyId: CurrencyEnum[currencyEnum],
      price: ''
    }
  }

  // may be used when we have more currencies
  // const getMoneyPrice = (): ProductPrice => {
  //   const moneyPrice = product.prices?.filter(
  //     (price) => isMoney(price.currencyId)
  //   )

  //   if (moneyPrice.length) {
  //     return moneyPrice[0]
  //   } else {
  //     return {
  //       currencyId: 0,
  //       price: undefined,
  //     }
  //   }
  // }

  useEffect(() => {
    if (goldValue === 0 && silverValue === 0) {
      !product.singlePurchase && updateProductState('singlePurchase', true)
      !product.redeemable && updateProductState('redeemable', true)
      !product.redeemableOnly && updateProductState('redeemableOnly', false)
    }
  }, [goldValue, silverValue])

  useEffect(() => {
    updateProductState('restrictions', restrictions)
  }, [restrictions])

  useEffect(() => {
    const moneyPrice = product.prices.filter(
      (price) => isMoney(price.currencyId)
    )

    if (moneyPrice.length === 0) {
      updateProductState('restrictions', {
        ...product.restrictions,
        payment: {
          paymentMethod: [],
        },
      })
    }
  }, [product.prices])

  useEffect(() => {
    if (renderForm && !id) setProduct(initialProductState)
  }, [id])

  useEffect(() => {
    if (id) {
      _getProduct().then(() => setRenderForm(true))
    } else {
      getFormFieldsData().then(() => setRenderForm(true))
    }
  }, [])

  useEffect(() => {
    updateProductState("redeemable", product.redeemableOnly)
  }, [product.redeemableOnly])

  useEffect(() => {
    if (product.metadata?.automaticActivation) {
      const newMetadata = {...product.metadata, generateVoucherOnDemand: false}
      updateProductState('metadata', newMetadata)
    }
  }, [product.metadata?.automaticActivation])

  return (
    renderForm && (
      <Card>
        <CardHeader title={renderCardHeader()} />
        <CardContent>
          <Grid container spacing={2}>

            {/* COLUNA 1 */}
            <Grid item xs={9}>
              <Grid container spacing={2}>

                {/* LINHA 1 */}
                <Grid item xs={6}>
                  <TextField
                    error={cloneProduct}
                    required
                    label="Nome"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateProductState('name', e.target.value)}
                    value={product.name}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    error={cloneProduct}
                    required
                    label="Slug do produto"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateProductState('slug', e.target.value)}
                    value={product.slug}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Estoque"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleQuantity(parseInt(e.target.value))}
                    value={product.quantity || undefined}
                  />
                </Grid>

                {/* LINHA 2 */}
                <Grid item xs={2}>
                  <TextField
                    label="BRL"
                    helperText="Valor"
                    variant={getPriceByEnum('BRL').price ? "outlined" : "filled"}
                    fullWidth
                    key={CurrencyEnum['BRL']}
                    onChange={(e) =>
                      updateCurrencyValue(CurrencyEnum['BRL'], e.target.value)
                    }
                    value={getPriceByEnum('BRL').price || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="USD"
                    helperText="Valor"
                    variant={getPriceByEnum('USD').price ? "outlined" : "filled"}
                    fullWidth
                    key={CurrencyEnum['USD']}
                    onChange={(e) =>
                      updateCurrencyValue(CurrencyEnum['USD'], e.target.value)
                    }
                    value={getPriceByEnum('USD').price || ''}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Gold"
                    helperText="Valor"
                    variant="outlined"
                    fullWidth
                    key={CurrencyEnum['GOLD']}
                    onChange={(e) =>
                      updateCurrencyValue(CurrencyEnum['GOLD'], e.target.value)
                    }
                    value={
                      product.prices.find(
                        price => price.currencyId === CurrencyEnum['GOLD']
                      )?.price
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Silver"
                    helperText="Valor"
                    variant="outlined"
                    fullWidth
                    key={CurrencyEnum['SILVER']}
                    onChange={(e) =>
                      updateCurrencyValue(CurrencyEnum['SILVER'], e.target.value)
                    }
                    value={
                      product.prices.find(
                        price => price.currencyId === CurrencyEnum['SILVER']
                      )?.price
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormSelect
                    required
                    items={tags.sort((a, b) => {
                      return a.name > b.name ? 1 : -1
                    })}
                    label="Tags"
                    multiple
                    productKey="tags"
                    updateProductState={updateProductState}
                    value={product.tags}
                  />
                </Grid>

                {/* LINHA 3 (escondida, a principio) */}
                {(
                  getPriceByEnum('BRL').price !== '' ||
                  getPriceByEnum('USD').price !== ''
                ) && (
                  <Grid item xs={12}>
                    {paymentRestrictions && (
                      <PaymentRestrictions
                        paymentRestrictions={paymentRestrictions}
                        productRestrictions={product.restrictions}
                        setRestrictions={setRestrictions}
                      />
                    )}
                  </Grid>
                )}

                {/* LINHA 4 */}
                <Grid item xs={3}>
                  <FormSelect
                    required
                    items={categories}
                    productKey="categoryId"
                    label="Categoria"
                    updateProductState={updateProductState}
                    value={product.categoryId}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormSelect
                    required
                    items={systems}
                    productKey="systemId"
                    label="Sistema"
                    updateProductState={updateProductState}
                    value={product.systemId}
                  />
                </Grid>
                {restrictions && (
                  <Grid item xs={3}>
                    <RegionRestrictions
                      productId={id}
                      regionRestrictions={regionRestrictions}
                      restrictions={product.restrictions}
                      setRestrictions={setRestrictions}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        clearable
                        renderInput={(props) => (
                          <TextField
                            helperText="Preencha para entrar em destaque."
                            {...props}
                          />
                        )}
                        label="Data de destaque"
                        value={product.highlightDate}
                        onChange={(newValue) => {
                          updateProductState('highlightDate', newValue)
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                {/* LINHA 5 */}
                <Grid item xs={3}>
                  <FormSelect
                    required
                    items={planTypes}
                    productKey="planType"
                    label="Tipo de plano"
                    updateProductState={updateProductState}
                    value={product.planType?.id}
                  />
                </Grid>
                {accountGroups && (
                  <Grid item xs={3}>
                    <AccountGroups
                      accountGroups={accountGroups}
                      restrictions={product.restrictions}
                      setRestrictions={setRestrictions}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        clearable
                        renderInput={(props) => (
                          <TextField
                            helperText="Data de início de vendas."
                            {...props}
                          />
                        )}
                        label="Data de início"
                        value={product.dateOpened}
                        onChange={(newValue) => {
                          updateProductState('dateOpened', newValue)
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        clearable
                        renderInput={(props) => (
                          <TextField
                            helperText="Data para retirar da loja."
                            {...props}
                          />
                        )}
                        label="Data de encerramento"
                        value={product.dateClosed}
                        onChange={(newValue) => {
                          updateProductState('dateClosed', newValue)
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                {/* LINHA 6 */}
                <Grid item xs={3}>
                  <TextField
                    label="Subscription ID"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      updateProductState('subscriptionId', e.target.value)
                    }
                    value={product.subscriptionId}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Limite de compras"
                    helperText="Para não verificados"
                    variant="outlined"
                    type="number"
                    fullWidth
                    inputProps={{
                      min: 0
                    }}
                    onChange={(e) =>
                      updateProductState('unverifiedPlayerBuyLimit', Number(e.target.value))
                    }
                    value={product.unverifiedPlayerBuyLimit}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Bundles
                    bundleProducts={bundleProducts}
                    setBundleProducts={setBundleProducts}
                    updateProductState={updateProductState}
                  />
                </Grid>

                {/* LINHA 7 */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <InputLabel>Descrição</InputLabel>
                          <RichTextEditor
                            height={300}
                            value={product.description}
                            outputFormat="html"
                            onChange={(content) =>
                              updateProductState('description', content)
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <PurchaseTerms
                            productTerms={product.purchaseTerms as number[]}
                            purchaseTerms={purchaseTerms}
                            updateProductState={updateProductState}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ToggleOptions
                            product={product}
                            updateProductState={updateProductState}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* COLUNA 2 */}
            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ImageUpload
                    defaultImage={product.pictures[0]?.url || ''}
                    restrictions=".jpg, .jpeg, .png, .gif"
                    handleSubmit={rawFile => {
                      handleUploadImage(rawFile)
                    }}
                  />
                </Grid>
                <Grid item xs={12} id='productType'>
                  <FormSelect
                    error={!productType}
                    required
                    items={productTypes.sort((a, b) => {
                      return a.name > b.name ? 1 : -1
                    })}
                    label="Tipo do produto"
                    product={product}
                    productKey="productType"
                    setProductType={setProductType}
                    unknownType={unknownType}
                    updateProductState={updateProductState}
                    value={productType}
                  />
                  {!productType ? (
                    <Typography color='red'>
                      Selecione o tipo do produto
                    </Typography>
                  ) : (
                    productType !== originalMetadata?.productType &&
                    originalMetadata?.productType
                  ) && <Typography color='orange'>
                    Você trocou o tipo de produto e os dados abaixo foram removidos. Para recuperá-los <a href='#productType' onClick={() => handleResetMetadata()}>clique aqui</a>
                  </Typography>}
                  <Metadata
                    productType={productType}
                    metadata={product.metadata}
                    updateProductState={updateProductState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* RODAPE */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {!cloneProduct &&
                    <Button variant="contained" onClick={() => handleClone()}>
                      Clonar Produto
                    </Button>
                  }
                </Grid>
                <Grid
                  item
                  display="flex"
                  xs={6}
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                  alignItems='center'
                >
                  {(id && !cloneProduct) &&
                    <a
                      href={`${BACKOFFICE_URL}/marketplace/product/${id}`}
                      target='_blank'
                    >
                      Editar no backoffice antigo
                    </a>
                  }
                  <Button
                    disabled={loading}
                    variant="contained"
                    onClick={() => handleSubmit()}
                  >
                    {loading ? 'Carregando' : `${buttonLabelAction} Produto`}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  )
}

export { ProductForm }
