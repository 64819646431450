// STYLES
import * as Styled from './styles'
import Empty from '@assets/svg/empty.svg'

interface Props {
  title: string
}

const NotFound: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <Styled.NotFoundContent>
      <Styled.ImgBroken src={Empty} alt="broken" />
      <Styled.TitleBroken>{props.title}</Styled.TitleBroken>
    </Styled.NotFoundContent>
  )
}

export { NotFound }
