import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import {
  getPurchaseTermById,
  createPurchaseTerms,
  updatePurchaseTerms,
} from '@services/PurchaseTerm/PurchaseTermService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  TextField,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon }  from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

const PurchaseTermsForm = (): JSX.Element => {
  const [description, setDescription] = useState<string>('');
  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    if (id) {
      _getPurchaseTerms()
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    if (id) {
      _updatePurchaseTerms() 
    } else {
      _createPurchaseTerms()
    }
  }

  const _createPurchaseTerms = async (): Promise<void> => {
    try {
      const payload = {
        description,
      }
      
      await createPurchaseTerms(payload)
      Alert.success(
        'Termo de compra criado com sucesso',
        navigate('/apps/marketplace/purchaseTerms/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar a criação do Termo de compra')
    }
  }

  const _updatePurchaseTerms = async (): Promise<void> => {
    try {
      const termId = Number(id)
      const payload = {
        id: termId,
        description,
      }

      await updatePurchaseTerms(payload)

      Alert.success(
        'Termo de compra alterada com sucesso',
        navigate('/apps/marketplace/purchaseTerms/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao alterar o Termo de compra')
    }
  }

  const _getPurchaseTerms = async (): Promise<void> => {
    try {
      const purchaseTermsData = await getPurchaseTermById(id)

      if (purchaseTermsData) {
        setDescription(purchaseTermsData.description || '')
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar o termo de compra')
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!description.length) errors.push('O campo Descrição é obrigatório')
    
    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Termo de Compra`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  return (
    <Card sx={{ width: 800 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{p: 4}}>
        <form onSubmit={handleSubmit}> 
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label="Descrição do termo de compra"
                variant="outlined"
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Grid>
          </Grid>
            <Grid container spacing={2} sx={{ mt: 0.2 }}>
              <Grid item xs sx={{ mt: 5 }}>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                  >
                  <Button variant="contained" type="submit">
                    {buttonLabelAction} Termo de compra
                  </Button>
                </Box>
              </Grid>
            </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { PurchaseTermsForm }
