import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getProfileCampaigns,
  deleteProfileCampaign,
} from '@services/ProfileCampaign/ProfileCampaignService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'
import { formatDate } from '@shared/utils/FormatDate'

// COMPONENTS
import {
  Button,
  Card,
  Grid,
  TextField,
  Box,
  LinearProgress,
} from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

// TYPES
import {
  ProfileCampaign,
  GetProfileCampaignsResponse,
  GetProfileCampaignsQueryParams,
} from '@shared/types/ProfileCampaignTypes'

// STYLES
import * as Styled from './styles'

const ProfileProductsList = (): JSX.Element => {
  const [profileCampaigns, setProfileCampaigns] = useState<ProfileCampaign[]>(
    []
  )
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({
    searchType: 'name',
    searchText: '',
  })
  const dateFormat = 'PP - KK:mm a'
  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = {
      page: 1,
      limit: 10,
    }
    _getCampaigns(queryParams)
  }, [])

  const _getCampaigns = async (
    queryParams: GetProfileCampaignsQueryParams
  ): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const campaignsData: GetProfileCampaignsResponse =
        await getProfileCampaigns(queryParams)
      setProfileCampaigns(campaignsData.results)
      setRowCount(campaignsData.pagination.totalItems)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _deleteCampaign = async (id: string): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir a vitrine?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteProfileCampaign(id)
          Alert.success(
            'Vitrine excluída com sucesso',
            removeCampaignFromState(id)
          )
        }
      })
      .catch((e) => {
        Alert.error('Ocorreu um erro ao excluir a vitrine')
      })
  }

  const removeCampaignFromState = (id: string): void => {
    const newCampaigns = profileCampaigns.filter(
      (campaign) => campaign.id !== id
    )
    setProfileCampaigns(newCampaigns)
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const queryParams = {
      page: 1,
      limit: 10,
      ...(searchParams.searchText
        ? { [searchParams.searchType]: searchParams.searchText }
        : {}),
    }
    _getCampaigns(queryParams)
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): void =>
            navigate(`/apps/marketplace/profileProducts/${id}`)
          }
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Editar
        </Button>
        <Button
          onClick={(): Promise<void> => _deleteCampaign(id)}
          color="error"
          variant="contained"
        >
          Excluir
        </Button>
      </>
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 50,
    },
    {
      field: 'target',
      headerName: 'Público',
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
    },
    {
      field: 'startDate',
      headerName: 'Inicio da Vitrine',
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      renderCell: (params) => formatDate(params.row.startDate, dateFormat),
    },
    {
      field: 'endDate',
      headerName: 'Fim da Vitrine',
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      renderCell: (params) => formatDate(params.row.endDate, dateFormat),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de vitrines no perfil">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      <Styled.FullWidthForm onSubmit={handleSearchSubmit}>
        <Grid container mt={4} columnSpacing={2}>
          <Grid item xs={7} lg={8}>
            <TextField
              label="Buscar"
              variant="outlined"
              fullWidth
              size={'small'}
              onChange={(e) =>
                setSearchParams({
                  ...searchParams,
                  searchText: e.target.value,
                })
              }
              value={searchParams.searchText}
            />
          </Grid>
          <Grid item xs={2} lg={1}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <Styled.CustomButton variant="contained" type="submit">
                Buscar
              </Styled.CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Styled.FullWidthForm>
      {profileCampaigns?.length > 0 ? (
        <Card
          sx={{
            mt: 4,
            ['& .MuiDataGrid-iconSeparator']: {
              display: 'none',
            },
          }}
        >
          <DataGrid
            paginationMode="server"
            rowCount={rowCount}
            rows={profileCampaigns}
            columns={columns}
            loading={refreshDataLoading}
            getRowId={(row) => row.id}
            pageSize={10}
            rowsPerPageOptions={[4]}
            autoHeight
            rowHeight={80}
            disableSelectionOnClick
            components={{
              LoadingOverlay: renderCustomLoadingOverlay,
            }}
            sx={{
              ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                outline: 'none',
              },
              ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                whiteSpace: 'break-spaces',
              },
            }}
            onPageChange={(page): Promise<void> =>
              _getCampaigns({
                page: page + 1,
                limit: 10,
              })
            }
          />
        </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { ProfileProductsList }
