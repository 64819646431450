import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

// TYPES
import { CustomImageUploadProps as Props } from './types'

// COMPONENTS
import { InputLabel } from '@mui/material'
import { CustomError } from '@components/shared/CustomError/CustomError'
import { ImageUpload } from '@shared/components/ImageUpload/ImageUpload'

const CustomImageUpload = (props: Props) => {
  const { label, name, required } = props
  const [defaultImage, setDefaultImage] = useState('')
  const {
    getValues,
    setValue,
    formState: { errors },
    watch
  } = useFormContext()

  const watchImage = watch(name)

  useEffect(() => {
    setDefaultImage(getValues(name))
  }, [watchImage])

  const requiredSymbol = required ? ' *' : ''

  return (
    <>
      <InputLabel sx={{ mb: 1, color: errors[name] ? '#f44336' : 'rgba(255, 255, 255, 0.7)'}}>{label}{requiredSymbol}</InputLabel>
      <ImageUpload
        defaultImage={defaultImage}
        restrictions=".jpg, .jpeg, .png"
        handleSubmit={(rawFile, readerResult) => {
          setValue(name, readerResult || '')
          setValue('hasNewImage', true)
      }}
      />
      {errors[name] && (
        <CustomError centered={true} message={`${errors[name].message}`} />
      )}
    </>
  )
}

export { CustomImageUpload }