// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'
import { buildQueryParams } from '@shared/helpers/BuildQueryParams'

// TYPES
import {
  GetProductsResponse,
  GetPartnersResponse,
  GetCampaignsResponse,
  GetVoucherResponse,
  CreateVoucherPayload,
  CreateVoucherResponse,
  UploadVoucherResponse,
  GetGiftCardsReportResponse
} from '@shared/types/VoucherTypes'

const getVouchers = (): Promise<GetVoucherResponse> => {
  return marketplaceApi.get(`/backoffice/voucher/`)
}

const createVoucher = (
  data: CreateVoucherPayload
): Promise<CreateVoucherResponse> => {
  return marketplaceApi.post('/backoffice/voucher/createKeys', data)
}

const uploadVoucher = (
  data: FormData
): Promise<UploadVoucherResponse> => {
  return marketplaceApi.post('/backoffice/voucher/upload', data)
}

const getProducts = (): Promise<GetProductsResponse> => {
  return marketplaceApi.get(`/backoffice/products/tagGroup/voucher`)
}

const getPartners = (): Promise<GetPartnersResponse> => {
  return marketplaceApi.get(`/backoffice/partner`)
}

const getCampaign = (): Promise<GetCampaignsResponse> => {
  return marketplaceApi.get(`/backoffice/campaign?includeNotStarted=true`)
}

const getGiftCardsCampaign = (): Promise<GetCampaignsResponse> => {
  return marketplaceApi.get(`/backoffice/campaign/all?type=GIFT_CARD`)
}

const getVouchersCampaigns = (): Promise<GetCampaignsResponse> => {
  return marketplaceApi.get(`/backoffice/campaign/all?type[]=GIFT_CARD&type[]=SALE`)
}

const getGiftCardsReport = (params: object): Promise<GetGiftCardsReportResponse[]> => {
  const queryParams = buildQueryParams(params)
  return marketplaceApi.get(`/backoffice/voucher/byCampaign?${queryParams}`)
}

export {
  getVouchers,
  createVoucher,
  uploadVoucher,
  getProducts,
  getPartners,
  getCampaign,
  getGiftCardsCampaign,
  getVouchersCampaigns,
  getGiftCardsReport
}
