import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import { getConfigs } from '@services/SlotMachine/SlotMachineConfigService'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Chip,
  Button,
  LinearProgress,
  Card
} from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'
import { NotFound } from '@components/shared/NotFound/NotFound'

// TYPES
import { SlotMachineConfigProps } from '@shared/types/SlotMachineTypes'

// ICONS
import { ReportOutlined as ReportIcon } from '@mui/icons-material'

// STYLES
import * as Styled from './SlotMachineConfigList.styles'

const SlotMachineConfigList = (): JSX.Element => {
  const [configs, setConfigs] = useState<SlotMachineConfigProps[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    _getConfigsList()
  }, [])

  const _getConfigsList = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const configsData = await getConfigs()
      setConfigs(configsData.results)
      setRowCount(configsData.results.length)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  // const _removeConfig = async (id: string): Promise<void> => {
  //   Alert.remove('Tem certeza que deseja excluir essa configuração?')
  //     .then(async (result) => {
  //       if (result.isConfirmed) {
  //         await removeConfig(id)
  //         Alert.success(
  //         'Configuração excluída com sucesso',
  //         removeConfigFromState(id)
  //       )
  //       }
  //     })
  //     .catch((e) => {
  //       Alert.error('Ocorreu um erro ao excluir essa configuração')
  //     })
  // }

  // const removeConfigFromState = (id: string): void => {
  //   const newConfigs = configs.filter(
  //     (config) => config.id !== id
  //   )
  //   setConfigs(newConfigs)
  // }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Listagem de configurações">
        <ReportIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const renderActionButtons = (id: number): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): void => navigate(`/apps/marketplace/slotMachine/config/${id}`)}
          color="primary"
          variant="contained"
        >
          Editar
        </Button>
        {/* <Button
          onClick={(): Promise<void> => _removeConfig(id)}
          color="error"
          variant="contained"
          sx={{ ml: 1 }}
        >
          Excluir
        </Button> */}
      </>
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      disableColumnMenu: false,
      flex: 0.25,
      headerAlign: 'left',
      align: 'left',
      minWidth: 100,
    },
    {
      field: 'slug',
      headerName: 'Slug',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'medalId',
      headerName: 'Medalha vinculada',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'active',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => params.row.active ? 'Ativada' : 'Desativada'
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 3 }}>
        {configs?.length > 0 ? (
          <Card
            sx={{
              mt: 4,
              ['& .MuiDataGrid-iconSeparator']: {
                display: 'none',
              },
            }}
          >
            <DataGrid
              paginationMode="server"
              rowCount={rowCount}
              rows={configs}
              columns={columns}
              loading={refreshDataLoading}
              getRowId={(row) => row.id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              autoHeight
              rowHeight={80}
              disableSelectionOnClick
              components={{
                LoadingOverlay: renderCustomLoadingOverlay,
              }}
              sx={{
                ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                  outline: 'none',
                },
                ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                  whiteSpace: 'break-spaces',
                },
              }}
            />
          </Card>
        ) : (
          <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
        )}
      </CardContent>
    </Styled.Container>
  )
}

export { SlotMachineConfigList }
