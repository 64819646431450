import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export function _formatDate(date, customFormat) {
  const currentDate = format(
    parseISO(date),
    customFormat || 'd/LL/yy HH:mm',
    { locale: ptBR }
  )
  return date !== null ? currentDate : ''
}