const $auth = {
  get loggedIn() {
    return !!localStorage.getItem('console:global:authToken')
  },

  get authToken() {
    return localStorage.getItem('console:global:authToken')
  },

  get refreshToken() {
    return localStorage.getItem('console:global:refreshToken')
  },

  get user() {
    return JSON.parse(localStorage.getItem('console:global:user'))
  },

  login({ authToken, refreshToken, user, callback }) {
    if (!authToken) throw new Error('AuthToken required')
    if (!refreshToken) throw new Error('RefreshToken required')
    if (!user) throw new Error('User required')

    localStorage.setItem('console:global:authToken', authToken)
    localStorage.setItem('console:global:refreshToken', refreshToken)
    localStorage.setItem('console:global:user', JSON.stringify(user))

    if (callback) callback()
  },

  logout(ignoreReloadAfter) {
    localStorage.removeItem('console:global:authToken')
    localStorage.removeItem('console:global:refreshToken')
    localStorage.removeItem('console:global:user')

    if (!ignoreReloadAfter) window.location.reload()
  },
}

export default $auth
