import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// SERVICES
import {
  getOffers,
  deleteOffer
} from '@services/Offers/OffersService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// TYPES
import {
  Offer,
  GetOfferQueryParams,
} from '@shared/types/OffersTypes'

// STYLES
import * as Styled from './styles'

// COMPONENTS
import {
  Button,
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  Box,
  LinearProgress,
} from '@mui/material'
import { DataGrid, GridOverlay } from '@mui/x-data-grid'
import { Star as StarIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { Loader } from '@components/Loader/Loader'
import { formatDate } from '@shared/utils/FormatDate'

const OffersList = (): JSX.Element => {
  const [offers, setOffers] = useState<Offer[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [searchParams, setSearchParams] = useState({
    searchType: 'title',
    searchText: '',
  })
  const [firstLoading, setFirstLoading] = useState(true)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)
  const limit = 10
  const queryParams = {
    page: 1,
    limit: limit,
  }
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 300,
      renderCell:
        params => params.row.id
    },
    {
      field: 'type',
      headerName: 'Tipo',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 260,
      renderCell:
        params => params.row.campaign.type
    },
    {
      field: 'title',
      headerName: 'Título',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 260,
      renderCell:
        params => params.row.campaign.title
    },
    {
      field: 'initialDate',
      headerName: 'Data de Início',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 160,
      renderCell: params => {
        const initialDate = new Date(params.row.campaign.initialDate)
        return formatDate(initialDate.toISOString())
      }
    },
    {
      field: 'finalDate',
      headerName: 'Data de Fim',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 160,

      renderCell: params => {
        const finalDate = new Date(params.row.campaign.finalDate)
        return formatDate(finalDate.toISOString())
      }
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      maxWidth: 220,
      renderCell: params =>
        renderActionButtons(params.id),
    },
  ]

  useEffect(() => {
    getOffersList(queryParams)
  }, [])

  const getOffersList = async (
    queryParams: GetOfferQueryParams
  ): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const response = await getOffers(queryParams)
      const results = response.results.map(item => {
        item.id = item.campaign.id
        return item
      })
      setOffers(results)
      setRowCount(response.pagination.totalItems)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const removeOffer = (id: string): void => {
    const confirmationText = 'Tem certeza que deseja excluir a promoção?'
    const errorText = 'Ocorreu um erro ao excluir a promoção'
    const successText = 'Promoção excluída com sucesso'

    Alert.remove(confirmationText)
      .then(async result => {
        if (result.isConfirmed) {
          const response = await deleteOffer(id)
          try {
            Alert.success(successText)
            const _offers = offers.filter(item => item.id !== id)
            setOffers(_offers)
            setRowCount(_offers.length)
          }
          catch {
            Alert.error(errorText)
          } finally {

          }
        }
      })
      .catch(e => Alert.error(errorText))
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const _queryParams = {
      ...queryParams,
      ...(searchParams.searchText
        ? { [searchParams.searchType]: searchParams.searchText }
        : {}),
    }
    getOffersList(_queryParams)
  }

  const handlePageChange = (page): void => {
    const _queryParams = {
      ...queryParams,
      ...(searchParams.searchText
        ? { [searchParams.searchType]: searchParams.searchText }
        : {}),
    }
    _queryParams.page = page + 1

    getOffersList(_queryParams)
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <>
        <Link
          to={`/apps/marketplace/offers/${id}`}
          style={{ textDecoration: 'none' }}>
          <Button
            color="primary"
            variant="contained"
            sx={{ mr: 1 }}>
            Editar
          </Button>
        </Link>
        <Button
          color="error"
          variant="contained"
          sx={{ mr: 1 }}
          onClick={(): void => removeOffer(id)}>
          Excluir
        </Button>
      </>
    )
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Promoções">
        <StarIcon fontSize="large" />
      </HeaderTitle>
        <Styled.FullWidthForm onSubmit={handleSearchSubmit}>
          <Grid container mt={4} columnSpacing={2}>
            <Grid item xs={3}>
              <Select
                value={searchParams.searchType}
                label="Campo"
                fullWidth
                size={'small'}
                onChange={(e) =>
                  setSearchParams({
                    searchType: e.target.value,
                    searchText: '',
                  })
                }>
                <MenuItem value={'title'}>Título</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={7} lg={8}>
              <TextField
                label="Buscar"
                variant="outlined"
                fullWidth
                size={'small'}
                onChange={(e) =>
                  setSearchParams({
                    ...searchParams,
                    searchText: e.target.value,
                  })
                }
                value={searchParams.searchText}
              />
            </Grid>
            <Grid item xs={2} lg={1}>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                sx={{ height: '100%' }}>
                <Styled.CustomButton variant="contained" type="submit">
                  Buscar
                </Styled.CustomButton>
              </Box>
            </Grid>
          </Grid>
        </Styled.FullWidthForm>
        {offers?.length > 0 ? (
          <Card
            sx={{
              mt: 4,
              ['& .MuiDataGrid-iconSeparator']: {
                display: 'none',
              },
            }}>
            <DataGrid
              paginationMode="server"
              rowCount={rowCount}
              rows={offers}
              columns={columns}
              loading={refreshDataLoading}
              pageSize={10}
              rowsPerPageOptions={[4]}
              autoHeight
              rowHeight={80}
              disableSelectionOnClick
              components={{
                LoadingOverlay: renderCustomLoadingOverlay,
              }}
              sx={{
                ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                  outline: 'none',
                },
                ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                  whiteSpace: 'break-spaces',
                },
              }}
              onPageChange={handlePageChange}
            />
          </Card>
        ) : (
          <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
        )}
    </Styled.Container>
  )
}

export { OffersList }