import { useFormContext, Controller } from 'react-hook-form'
import { TextField, MenuItem } from '@mui/material'

// TYPES
import { CustomSelectProps as Props } from './types'

const CustomSelect = (props: Props) => {
  const { name, label, id, options, multiple, customValueKey} = props
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const renderOptions = ():JSX.Element[] => {
    return options.map((option, i) => (
      <MenuItem value={customValueKey ? option[customValueKey] : option.id} key={`${id}-${i}`}>
        {option.name}
      </MenuItem>
    ))
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <TextField
          select
          fullWidth
          label={label}
          defaultValue={multiple ? [] : ''}
          value={value}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          onChange={(value) => onChange(value)}
          SelectProps={{ multiple, MenuProps: {
            style: {
              maxHeight: 400
            }
          } }}
        >
          {renderOptions()}
        </TextField>
      )}
    />
  )
}

export { CustomSelect }
