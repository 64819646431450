import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getProducts,
  getPartners,
  getCampaign,
  uploadVoucher,
} from '@services/Voucher/VoucherService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
  InputLabel,
  Paper
} from '@mui/material'

import { AddCircleOutlineRounded as AddIcon, HelpOutline as HelpOutlineIcon } from '@mui/icons-material'

import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { CsvUpload } from '@shared/components/CsvUpload/CsvUpload'

const VoucherUploadForm = (): JSX.Element => {
  const navigate = useNavigate()
  const [campaignId, setCampaignId] = useState<string>('')
  const [partnerId, setPartnerId] = useState<string>('')
  const [productId, setProductId] = useState<string>('')
  const [slug, setSlug] = useState<string>('')
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const [campaignItems, setCampaignItems] = useState([])
  const [partnersItems, setPartnersItems] = useState([])
  const [productsItems, setProductsItems] = useState([])

  const fileName = useMemo(() => {
    if (!csvFile) {
      return undefined
    } else {
      return csvFile.name
    }
  }, [csvFile])

  useEffect(() => {
    getCampaign().then((response) => setCampaignItems(response.campaigns))
    getPartners().then((response) => setPartnersItems(response.partners))
    getProducts().then((response) => setProductsItems(response.products))
  }, [])

  useEffect(() => {
    if (!productId) return

    const abc = productsItems.find((product) => product.id === productId)
    setSlug(abc.slug)
  }, [productId])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    _uploadVoucher()
  }

  const _uploadVoucher = async (): Promise<void> => {
    try {
      const formData = new window.FormData()
      formData.append('file', csvFile)
      formData.append('productId', productId.toString())
      formData.append('slug', slug.toString())
      formData.append('campaignId', campaignId.toString())
      formData.append('partnerId', partnerId.toString())

      const uploadVoucherResponse = await uploadVoucher(formData)
      const { inserted } = uploadVoucherResponse
      Alert.success(
        `${inserted} vouchers criados com sucesso`,
        navigate('/apps/marketplace/voucher/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar o upload dos vouchers')
    }
  }

  const renderCampaignOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.title}
        </MenuItem>
      ))
    )
  }

  const renderPartnersOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  const renderProductsOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.name}
        </MenuItem>
      ))
    )
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!campaignItems.length) errors.push('O campo Campanha é obrigatório')
    if (!partnersItems.length) errors.push('O campo Parceiro é obrigatório')
    if (!productsItems.length) errors.push('O campo Produto é obrigatório')
    if (!csvFile) errors.push('O arquivo CSV é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Criação de Voucher">
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const handleFileChange = (rawFile, readerResult) => {
    setCsvFile(rawFile)
    console.log(rawFile)
  }

  return (
    <Box>
      <Card sx={{ width: 600 }}>
        <CardHeader title={renderCardHeader()} />
        <CardContent sx={{ p: 4 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="campaign-label">Campanha</InputLabel>
                  <Select
                    required
                    key="campaignId"
                    value={campaignId}
                    onChange={(e) => setCampaignId(e.target.value)}
                    input={<OutlinedInput label="Name" />}
                  >
                    {renderCampaignOptions(campaignItems)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="product-label">Produto</InputLabel>
                  <Select
                    required
                    key="productId"
                    value={productId}
                    onChange={(e) => setProductId(e.target.value)}
                    input={<OutlinedInput label="Name" />}
                  >
                    {renderProductsOptions(productsItems)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="partner-label">Parceiro</InputLabel>
                  <Select
                    required
                    key="partnerId"
                    value={partnerId}
                    onChange={(e) => setPartnerId(e.target.value)}
                    input={<OutlinedInput label="Name" />}
                  >
                    {renderPartnersOptions(partnersItems)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mt: 2 }}>
              <Grid item xs={8}>
                  <Box
                    display="flex"
                    justifyContent={{ xs: 'center' }}
                  >
                    <FormControl fullWidth>
                      <TextField value={fileName} disabled></TextField>
                    </FormControl>
                  </Box>
                </Grid>
              <Grid item xs={4}>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'center' }}
                  alignItems={{ xs: 'center' }}
                  sx={{ mt: 1 }}
                >
                  <CsvUpload
                    restrictions=".csv"
                    handleSubmit={(rawFile, readerResult) => {
                      handleFileChange(rawFile, readerResult)
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0.2 }}>
              <Grid item xs>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'center' }}
                >
                  <Button variant="contained" type="submit">
                    Cadastrar vouchers
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Card sx={{ width: 600, mt: 1 }}>
        <CardHeader title="Exemplos" />
        <CardContent>
          <p>Lista de vouchers sem expiração:</p>
          <Paper elevation={3} sx={{ p: 1, mt: 1, mb: 2 }} >
            <code>
              Chave<br />
              1<br />
              2<br />
              3<br />
              4<br />
              5<br />
              6<br />
            </code>
          </Paper>
          <p>Lista de vouchers com expiração:</p>
          <Paper elevation={3} sx={{ p: 1, mt: 1 }}>
            <code>
              Chave,Expiracao<br />
              1,2023-03-01 00:00:00<br />
              2,2023-03-01 00:00:00<br />
              3,2023-03-01 00:00:00<br />
              4,2023-03-01 00:00:00<br />
              5,2023-03-01 00:00:00<br />
              6,2023-03-01 00:00:00<br />
            </code>
          </Paper>
        </CardContent>
      </Card>
    </Box>
  )
}

export { VoucherUploadForm }
