import { useEffect } from 'react'

// @MATERIAL-UI/COMPONENTS
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material'

// TYPES
import { RegionRestrictionsProps as Props } from './types'

const RegionRestrictions = (props: Props): JSX.Element => {
  const {
    regionRestrictions,
    restrictions,
    setRestrictions
  } = props

  const handleRestrictionsChange = (e, restrictionId): void => {
    const restrictionsArray = e.target.value
    const newRestrictions = { ...restrictions }

    newRestrictions['region'][restrictionId] = restrictionsArray.map(
      (restriction) => {
        return {
          id: restriction,
          options: [],
        }
      }
    )

    setRestrictions(newRestrictions)
  }

  const getRestrictionValue = (id) => {
    const restrictionSelectValue = []

    restrictions['region'][id].forEach((item) => {
      if (!restrictionSelectValue.includes(item)) {
        restrictionSelectValue.push(item.id)
      }
    })
    return restrictionSelectValue
  }

  return (
    <>
      {regionRestrictions?.map((restriction) => (
        <FormControl key={restriction.id} fullWidth>
          <InputLabel>{restriction.name}</InputLabel>
          <Select
            label={`${restriction.id}`}
            onChange={(e) => {
              handleRestrictionsChange(e, restriction.id)
            }}
            multiple
            value={getRestrictionValue(restriction.id)}
          >
            <MenuItem disabled value={-1}>
              {restriction.name}
            </MenuItem>
            {restriction.restrictions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Se vazio, é disponível para todos.</FormHelperText>
        </FormControl>
      ))}
    </>
  )
}

export { RegionRestrictions }
