import { useEffect, useState } from 'react'

// SERVICES
import {
  expireRedeemCodes,
  getRedeemCodes, 
  getRedeemCodesCsv,
} from '@services/RedeemCodes/RedeemCodesService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'
import { formatDate } from '@shared/utils/FormatDate'

// COMPONENTS
import { Card, LinearProgress, Typography, Button } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

// TYPES
import {
  RedeemCodes,
  GetRedeemCodesResponse,
  GetRedeemCodesQueryParams,
} from '@shared/types/RedeemCodes'

// STYLES
import * as Styled from './RedeemCodesList.styles'

const RedeemCodesList = (): JSX.Element => {
  const [redeemCodes, setRedeemCodes] = useState<RedeemCodes[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)
  const dateFormat = 'PP - KK:mm a'

  const queryParams = {
    page: 1,
    limit: 10,
  }

  useEffect(() => {
    _getRedeemCodes(queryParams)
  }, [])

  const _getRedeemCodes = async (
    queryParams: GetRedeemCodesQueryParams
  ): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const redeemCodesData: GetRedeemCodesResponse =
        await getRedeemCodes(queryParams)
        redeemCodesData && setRedeemCodes(redeemCodesData.results)
        redeemCodesData && setRowCount(redeemCodesData.pagination.totalItems)
    } catch(err) {
      Alert.error(err)
    } finally {
        setFirstLoading(false)
        setRefreshDataLoading(false)
    }
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const renderActionButtons = (slug: string): JSX.Element => {
    if(slug === '--') {
      return (
        <Typography variant="caption" display="block" gutterBottom>
          Sem slug do produto
        </Typography>
      )
    }
  const handleCsvDownload = async (slug: string): Promise<void> => {
    await getRedeemCodesCsv(slug).then((response) => {
      try {
        const filename = `redeem_codes_${slug}.csv`;
        var blob = new Blob([response], {type: 'text/csv; charset=utf-8'});
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = filename;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0)
        }
      } catch (exc) {
        Alert.error(exc)
      }
    })
  }

  const _expireRedeemCodes = async (slug: string): Promise<void> => {
    Alert.remove('Tem certeza que deseja expirar os códigos?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await expireRedeemCodes({ productSlug: slug })
          Alert.success(
            'Códigos expirados com sucesso'
          )
          await _getRedeemCodes(queryParams)
        }
      })
      .catch((e) => {
        Alert.error(`Ocorreu um erro ao expirar os códigos, ${e}`)
      })
  }

    return (
      <>
        <Button
          onClick={(): Promise<void> => handleCsvDownload(slug)}
          color="primary"
          variant="contained"
          sx={{ mr: 2 }}
        >
          Baixar CSV
        </Button>
        <Button
          onClick={(): Promise<void> => _expireRedeemCodes(slug)}
          color="error"
          variant="contained"
        >
          Expirar Cupons
        </Button>
      </>
    )
  }

  const columns: GridColumns = [
    {
      field: 'productSlug',
      headerName: 'Slug do Produto',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'left',
      align: 'left',
      minWidth: 120,
    },
    {
      field: 'quantity',
      headerName: 'Quantidade',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
    },
    {
      field: 'redeemed',
      headerName: 'Resgatados',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
    },
    {
      field: 'available',
      headerName: 'Disponíveis',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
    },
    {
      field: 'expired',
      headerName: 'Expirado',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
    },
    {
      field: 'maxExpirationDate',
      headerName: 'Data de expiração',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 120,
      renderCell: (params) => {
        const { maxExpirationDate } = params.row
        if (maxExpirationDate === null) {
          return (
            <Typography variant="caption" display="block" gutterBottom>
              Sem data de expiração
            </Typography>
          )
        }

        return (
          <Typography variant="caption" display="block" gutterBottom>
            {formatDate(params.row.maxExpirationDate, dateFormat)}
          </Typography>
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.productSlug),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  const _renderDataGrid = (): JSX.Element => {
    if (redeemCodes?.length === 0) {
      return (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )
    }
    return (
      <Card
        sx={{
          mt: 4,
          ['& .MuiDataGrid-iconSeparator']: {
            display: 'none',
          },
        }}
      >
        <DataGrid
          paginationMode="server"
          rowCount={rowCount}
          rows={redeemCodes}
          columns={columns}
          loading={refreshDataLoading}
          getRowId={(row) => row.productSlug}
          pageSize={10}
          rowsPerPageOptions={[4]}
          autoHeight
          rowHeight={80}
          disableSelectionOnClick
          components={{
            LoadingOverlay: renderCustomLoadingOverlay,
          }}
          sx={{
            ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
              outline: 'none',
            },
            ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
              whiteSpace: 'break-spaces',
            },
          }}
          onPageChange={(page): Promise<void> =>
            _getRedeemCodes({
              page: page + 1,
              limit: 10,
            })
          }
        />
      </Card>
    )
  }

  return (
    <Styled.Container>
    <HeaderTitle title="Lista de Código de resgate de produto">
      <AssignmentRoundedIcon fontSize="large" />
    </HeaderTitle>
    {_renderDataGrid()}
  </Styled.Container>
  )
}

export { RedeemCodesList }
