import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import {
  getProducts,
  createHighlights,
  updateHighlights,
  getHighlight,
} from '@services/Highlights/HighlightsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Card,
  FormControl,
  TextField,
  Autocomplete,
  Box,
  Typography,
} from '@mui/material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { CsvUpload } from '@shared/components/CsvUpload/CsvUpload'
import { ImageUpload } from '@shared/components/ImageUpload/ImageUpload'

// ICON
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'

//TYPES
import { Product } from '@shared/types/ProductTypes'

const HighlightsForm = (): JSX.Element => {
  const [products, setProducts] = useState<Product[]>([])
  const [banner, setBanner] = useState<string | null>(null)
  const [targetAudience, setTargetAudience] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [productsItems, setProductsItems] = useState<Product[]>([])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [csv, setCsv] = useState()

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    getProducts({
      limit: 1999,
    }).then((response) => setProductsItems(response.results))
  }, [])

  useEffect(() => {
    if (id) {
      _getHighlights()
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }

    if (id) {
      _updateHighlights()
    } else {
      _createHighlights()
    }
  }

  const _createHighlights = async (): Promise<void> => {
    try {
      const formData = new window.FormData()
      formData.append('file', csv)
      formData.append('title', title)
      formData.append('description', description)
      products.forEach((product, index) => {
        formData.append(`products[${index}]`, product.id)
      })
      if (banner) {
        formData.append('banner', banner)
      }
      formData.append('targetAudience', targetAudience)
      formData.append('startDate', startDate.toISOString())
      formData.append('endDate', endDate.toISOString())

      await createHighlights(formData)

      Alert.success(
        'Highlights criada com sucesso',
        navigate('/apps/marketplace/highlights/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar a criação do Highlights')
    }
  }

  const _updateHighlights = async (): Promise<void> => {
    try {
      const productsId = products.map((product) => product.id)
      const payload = {
        id,
        title,
        description,
        products: productsId,
        banner: banner,
        targetAudience,
        startDate,
        endDate,
        csv,
      }

      await updateHighlights(payload)

      Alert.success(
        'Highlights alterada com sucesso',
        navigate('/apps/marketplace/highlights/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao alterar o Highlights')
    }
  }

  const _getHighlights = async (): Promise<void> => {
    try {
      const highlightsData = await getHighlight(id)

      if (highlightsData) {
        setTitle(highlightsData.title || '')
        setDescription(highlightsData.description || '')
        setProducts(highlightsData.products || [])
        setBanner(highlightsData.banner || null)
        setTargetAudience(highlightsData.targetAudience || '')
        setStartDate(highlightsData.startDate)
        setEndDate(highlightsData.endDate)
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar o highlight')
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!products.length) errors.push('O campo Produto é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} highlights`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const handleFileChange = (rawFile, readerResult) => {
    setCsv(rawFile)
  }

  const handleUploadImage = (rawFile, readerResult) => {
    setBanner(readerResult)
  }

  const handleRemoveImage = () => {
    setBanner(null)
  }

  const targetOptions = ['FREE', 'PLUS', 'PREMIUM', 'ALL', 'CUSTOM']

  return (
    <Card sx={{ width: 600 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <TextField
                required
                label="Titulo do highlight"
                variant="outlined"
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Descrição do highlights"
                variant="outlined"
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  value={products}
                  onChange={(event: any, newValue: Product[] | null) => {
                    setProducts(newValue)
                  }}
                  options={productsItems}
                  getOptionLabel={(option: Product) => `${option.name} (${option.slug})`}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Produtos" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" gutterBottom>
                * Para enviar um CSV selecione o público CUSTOM
              </Typography>
              <FormControl fullWidth>
                <Autocomplete
                  onChange={(event: any, newValue: string) => {
                    setTargetAudience(newValue)
                  }}
                  inputValue={targetAudience}
                  options={targetOptions}
                  getOptionLabel={(option) => option || ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Público" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="caption" display="block" gutterBottom>
                  * Tamanho do background deverá ser 820x300
                </Typography>
                <ImageUpload
                  cropped={false}
                  defaultImage={banner}
                  restrictions=".jpg, .jpeg, .png"
                  handleSubmit={(rawFile, readerResult) => {
                    handleUploadImage(rawFile, readerResult)
                  }}
                />
              </FormControl>
            </Grid>
            {banner && (
              <Grid item xs={4}>
                <Button variant="contained" onClick={() => handleRemoveImage()}>
                  Remover imagem
                </Button>
              </Grid>
            )}
            {!id && targetAudience === 'CUSTOM' && (
              <Grid item xs sx={{ ml: 'auto', mt: 'auto' }}>
                <CsvUpload
                  restrictions=".csv"
                  handleSubmit={(rawFile, readerResult) => {
                    handleFileChange(rawFile, readerResult)
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    inputFormat="dd/LL/yyyy KK:mm aaa"
                    label="Inicio do highlights"
                    value={startDate}
                    onChange={(startDate) => {
                      setStartDate(startDate)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    inputFormat="dd/LL/yyyy KK:mm aaa"
                    label="Fim do highlight"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <Grid item xs sx={{ mt: 5 }}>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                >
                  <Button variant="contained" type="submit">
                    {buttonLabelAction} Highlight
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { HighlightsForm }
