// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  Product,
  GetProductsResponse,
  CreateProductResponse,
  CreateProductPayload,
  UploadImageResponse
} from '@shared/types/ProductTypes'

import {
  GetCampaignsResponse
} from '@shared/types/VoucherTypes'

import {
  CreatePlanPayload,
} from '@shared/types/PlanTypes'

const getProduct = (id: string): Promise<Product> => {
  return marketplaceApi.get(`/backoffice/products/${id}`)
}

const getProducts = (data?,categoryNames?): Promise<GetProductsResponse> => {

  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  let queryCategories = ''
  if(categoryNames){
    categoryNames.forEach(c => {
      queryCategories += `&categoryNames=${c}`
    });
  }

  return marketplaceApi.get(`/backoffice/products/${queryString + queryCategories}`)
}

const createProduct = (
  data: CreateProductPayload
): Promise<CreateProductResponse> => {
  return marketplaceApi.post('/backoffice/products', data)
}

const updateProduct = (
  data: CreateProductPayload
): Promise<CreateProductResponse> => {
  return marketplaceApi.patch('/backoffice/products', data)
}

const createPlan = (
  data: CreatePlanPayload
): Promise<CreateProductResponse> => {
  return marketplaceApi.post('/backoffice/products', data)
}

const updatePlan = (
  data: CreatePlanPayload
): Promise<CreateProductResponse> => {
  return marketplaceApi.patch('/backoffice/products', data)
}

const getCampaigns = (): Promise<GetCampaignsResponse> => {
  return marketplaceApi.get(`/backoffice/campaign?includeNotStarted=true`)
}

const uploadImage = (formData: FormData): Promise<UploadImageResponse> => {
  return marketplaceApi.post(
    `/backoffice/products/${formData.get('productSlug')}/image`,
    formData
  )
}

export {
  getProduct,
  getProducts,
  createProduct,
  updateProduct,
  createPlan,
  updatePlan,
  getCampaigns,
  uploadImage
}
