import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import {
  getBannerById,
  createBanners,
  updateBanners,
  uploadImage
} from '@services/Banner/BannerService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon }  from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { ImageUpload } from '@shared/components/ImageUpload/ImageUpload'
import { UploadImageResponse } from '@shared/types/BannerTypes'

const BannerForm = (): JSX.Element => {
  const [title, setTitle] = useState<string | null>(null);
  const [actionLabel, setActionLabel] = useState<string | null>(null);
  const [redirectPage, setRedirectPage] = useState<string>('');
  const [description, setDescription] = useState<string | null>(null);
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const [image, setImage] = useState<File>(null)
  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    if (id) {
      _getBanners()
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
  
    if (id) {
      _updateBanners() 
    } else {
      _createBanners()
    }
  }

  const _createBanners = async (): Promise<void> => {
    try {
      const newImage = await _uploadImage()

      const payload = {
        title,
        actionLabel,
        redirectPage,
        description,
        backgroundImage: newImage.url,
        isActive,
      }

      await createBanners(payload)

      Alert.success(
        'Banner criado com sucesso',
        navigate('/apps/marketplace/banner/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar a criação do banner')
    }
  }

  const _updateBanners = async (): Promise<void> => {
    try {
      let currentImage = backgroundImage
      if (image) {
         const newImage = await _uploadImage()
         currentImage = newImage.url
      }

      const payload = {
        id,
        title,
        actionLabel,
        redirectPage,
        description,
        backgroundImage: currentImage,
        isActive,
      }

      await updateBanners(payload)

      Alert.success(
        'Banner de compra alterada com sucesso',
        navigate('/apps/marketplace/banner/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao alterar o banner')
    }
  }

  const _getBanners = async (): Promise<void> => {
    try {
      const bannersData = await getBannerById(id)

      if (bannersData) {
        setTitle(bannersData.title || '')
        setActionLabel(bannersData.actionLabel || '')
        setRedirectPage(bannersData.redirectPage || '')
        setDescription(bannersData.description || '')
        setBackgroundImage(bannersData.backgroundImage || '')
        setIsActive(bannersData.isActive)
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar o banner')
    }
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Banner`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const handleUploadImage = (rawFile: File): void => {
    if (!rawFile) return

    const maxSizeAllowed = 15000000
    if (rawFile.size > maxSizeAllowed) {
      Alert.error('A imagem excede o tamanho permitido')
      return
    }

    setImage(rawFile)
  }

  const _uploadImage = async (): Promise<UploadImageResponse> => {
    const formData = new window.FormData()
    const timestamp = Date.now()
    formData.append('file', image)
    formData.append('id', id || String(timestamp))
    
    const uploadedImage = await uploadImage(formData)
    if (!uploadedImage.url) {
      Alert.error('Erro ao realizar o upload da imagem do banner')
      return
    }

    return uploadedImage
  }

  const handleRemoveImage = () => {
    setBackgroundImage(null)
  }

  return (
    <Card sx={{ width: 800 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{p: 4}}>
        <form onSubmit={handleSubmit}> 
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <TextField
                label="Nome do Banner"
                variant="outlined"
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Nome do botão"
                variant="outlined"
                fullWidth
                onChange={(e) => setActionLabel(e.target.value)}
                value={actionLabel}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <TextField
                label="Link do Botão"
                variant="outlined"
                fullWidth
                onChange={(e) => setRedirectPage(e.target.value)}
                value={redirectPage}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Descrição"
                variant="outlined"
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label='Habilitar Banner'
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <ImageUpload
                  cropped={false}
                  defaultImage={backgroundImage}
                  restrictions=".jpg, .jpeg, .png"
                  handleSubmit={rawFile => {
                    handleUploadImage(rawFile)
                  }}
                />
              </FormControl>
            </Grid>
            {backgroundImage && 
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={() => handleRemoveImage()}>
                  Remover imagem
                </Button>
              </Grid>
            }
          </Grid>
            <Grid container spacing={2} sx={{ mt: 0.2 }}>
              <Grid item xs sx={{ mt: 5 }}>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                  >
                  <Button variant="contained" type="submit">
                    {buttonLabelAction} Banner
                  </Button>
                </Box>
              </Grid>
            </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { BannerForm }
