import { useEffect, useState } from 'react'
import { parseISO, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

// SERVICES
import { getVouchers } from '@services/Voucher/VoucherService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  Button,
  Card,
  Grid,
  TextField,
  Box,
  LinearProgress,
} from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

// TYPES
import { Voucher, GetVoucherResponse } from '@shared/types/VoucherTypes'

// STYLES
import * as Styled from './VoucherList.styles'

const VouchersList = (): JSX.Element => {
  const [vouchers, setVouchers] = useState<Voucher[]>([])
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  useEffect(() => {
    _getVouchers()
  }, [])

  const _getVouchers = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const vouchersData: GetVoucherResponse = await getVouchers()
      setVouchers(vouchersData.vouchers)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const columns: GridColumns = [
    {
      field: 'campaignId',
      headerName: 'Campanha',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'voucherprefix',
      headerName: 'Prefixo do Voucher',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 50,
    },
    {
      field: 'productSlug',
      headerName: 'Slug do Produto',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
    },
    {
      field: 'total',
      headerName: 'Total Vouchers',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
    },
    {
      field: 'entregue',
      headerName: 'Entregues a uma Account',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Vouchers de Medalha">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      {vouchers?.length > 0 ? (
        <Card
          sx={{
            mt: 4,
            ['& .MuiDataGrid-iconSeparator']: {
              display: 'none',
            },
          }}
        >
          <DataGrid
            paginationMode="server"
            rows={vouchers}
            columns={columns}
            loading={refreshDataLoading}
            getRowId={(row) => row.productSlug}
            pageSize={10}
            rowsPerPageOptions={[4]}
            autoHeight
            rowHeight={80}
            disableSelectionOnClick
            components={{
              LoadingOverlay: renderCustomLoadingOverlay,
            }}
            sx={{
              ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                outline: 'none',
              },
              ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                whiteSpace: 'break-spaces',
              },
            }}
          />
        </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { VouchersList }
