import { useState } from 'react'
import {
  Drawer,
  Divider,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
} from '@mui/material'

import {
  ExpandLess,
  ExpandMore,
  LocalOfferRounded as LocalOfferRoundedIcon,
  AddCircleOutlineRounded as AddCircleOutlineRoundedIcon,
  AssignmentRounded as AssignmentRoundedIcon,
  ShoppingCart as ShoppingCartIcon,
  ArrowBackRounded as ArrowBackRoundedIcon,
  MilitaryTech as MilitaryTechIcon,
  Campaign as CampaignIcon,
  Person as PersonIcon,
  AssignmentTurnedIn as TermsIcon,
  Star as StarIcon,
  ViewCarousel as BannerIcon,
  Tag as TagIcon,
  Today as TodayIcon,
  CurrencyExchange,
  MoneyOff,
  EmojiEvents,
  EventRepeat as EventRepeatIcon,
  ProductionQuantityLimits,
  CasinoOutlined as Casino,
  BurstMode as StickerAlbumIcon,
  CardGiftcard as GiftCardIcon,
  SwapVert as SwapVertIcon,
} from '@mui/icons-material'

// STYLES
import * as Styled from './styles'

const SideBar = (): JSX.Element => {
  const drawerWidth = 250

  const menuItemsWithSubItem = [
    {
      id: 1,
      name: 'Produtos',
      icon: <ShoppingCartIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/product/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar',
          link: '/apps/marketplace/product/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 2,
      name: 'Descontos de produtos',
      icon: <MoneyOff />,
      subItems: [
        {
          name: 'Listar desconto',
          link: '/apps/marketplace/discount/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar',
          link: '/apps/marketplace/discount/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 3,
      name: 'Categorias',
      icon: <LocalOfferRoundedIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/category/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar',
          link: '/apps/marketplace/category/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 4,
      name: 'Voucher',
      icon: <MilitaryTechIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/voucher/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Criar Vouchers',
          link: '/apps/marketplace/voucher/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
        {
          name: 'Criar Vouchers (CSV)',
          link: '/apps/marketplace/voucher/upload',
          icon: <AddCircleOutlineRoundedIcon />,
        },
        {
          name: 'Gerador de Cupons',
          link: '/apps/marketplace/voucher/couponGenerator',
          icon: <LocalOfferRoundedIcon />,
        },
        {
          name: 'Relatório Gift Cards',
          link: '/apps/marketplace/giftCards/report',
          icon: <GiftCardIcon />,
        },
      ],
    },
    {
      id: 5,
      name: 'Highlights',
      icon: <CampaignIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/highlights/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Criar Highlights',
          link: '/apps/marketplace/highlights/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 6,
      name: 'Vitrine no perfil',
      icon: <PersonIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/profileProducts/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Criar Vitrine',
          link: '/apps/marketplace/profileProducts/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 7,
      name: 'Termos de Compra',
      icon: <TermsIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/purchaseTerms/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Criar Termo de compra',
          link: '/apps/marketplace/purchaseTerms/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 8,
      name: 'Banner',
      icon: <BannerIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/banner/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Criar Banner',
          link: '/apps/marketplace/banner/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 9,
      name: 'Tags',
      icon: <TagIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/tag/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Criar Tag',
          link: '/apps/marketplace/tag/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
        {
          name: 'Ordenar Tags',
          link: '/apps/marketplace/tag/order',
          icon: <SwapVertIcon />,
        },
      ],
    },
    {
      id: 10,
      name: 'Season',
      icon: <TodayIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/season/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Criar Season',
          link: '/apps/marketplace/season/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 11,
      name: 'Cupom de Desconto',
      icon: <CurrencyExchange />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/discountCoupons/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Criar Cupom',
          link: '/apps/marketplace/discountCoupons/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 12,
      name: 'Recompensas Onboarding',
      icon: <EmojiEvents />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/onboarding/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Criar recompensa',
          link: '/apps/marketplace/onboarding/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 13,
      name: 'Planos',
      icon: <EventRepeatIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/plans/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar',
          link: '/apps/marketplace/plans/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 14,
      name: 'Promoções',
      icon: <StarIcon />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/offers/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar',
          link: '/apps/marketplace/offers/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 15,
      name: 'Códigos de resgate de produtos',
      icon: <ProductionQuantityLimits />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/redeemCodes/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar',
          link: '/apps/marketplace/redeemCodes/',
          icon: <AddCircleOutlineRoundedIcon />,
        },
      ],
    },
    {
      id: 16,
      name: 'Album de figurinhas',
      icon: <StickerAlbumIcon />,
      subItems: [
        {
          name: 'Trocas',
          link: '/apps/marketplace/stickersAlbum/trades',
          icon: <AssignmentRoundedIcon />,
        }
      ],
    },
    {
      id: 17,
      name: 'Caça-coins',
      icon: <Casino />,
      subItems: [
        {
          name: 'Listagem',
          link: '/apps/marketplace/slotMachine/list',
          icon: <AssignmentRoundedIcon />,
        },
        {
          name: 'Cadastrar',
          link: '/apps/marketplace/slotMachine/config',
          icon: <AddCircleOutlineRoundedIcon />,
        }
      ],
    },
  ]

  const menuItems = [
    // {
    //   id: 1,
    //   name: 'Exemplo',
    //   icon: <Casino />,
    //   link: '/apps/marketplace/exemplo'
    // },
  ]

  const [isMenuVisible, setIsMenuVisible] = useState(
    menuItemsWithSubItem.reduce(
      (menuItem, { id }) => ({
        ...menuItem,
        [id]: false,
      }),
      {}
    )
  )

  const handleClick = (id: number): void => {
    setIsMenuVisible({
      ...isMenuVisible,
      [id]: !isMenuVisible[id],
    })
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'primary.main',
        },
      }}
    >
      <Toolbar sx={{ mt: 2 }} />
      <Styled.MenuContainer>
        <Styled.MenuContent>
          {menuItemsWithSubItem.map((item, itemsIndex) => (
            <List component="nav" key={itemsIndex} disablePadding>
              <ListItemButton onClick={(): void => handleClick(item.id)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.name}
                  disableTypography
                  sx={{ fontWeight: '300' ,fontSize: '0.875rem' }}
                />
                {isMenuVisible[item.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isMenuVisible[item.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem, subItemIndex) => (
                    <Styled.LinkMenu key={subItemIndex} to={subItem.link}>
                      <ListItem button key={subItem.name} sx={{ pl: 4 }}>
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText
                          primary={subItem.name}
                          disableTypography
                          sx={{ fontWeight: '300',fontSize: '0.875rem'  }}
                        />
                      </ListItem>
                    </Styled.LinkMenu>
                  ))}
                </List>
              </Collapse>
            </List>
          ))}

          <List component="nav" disablePadding>
            <List component="div" disablePadding>
              {menuItems.map((item) => (
                <Styled.LinkMenu key={item.id} to={item.link}>
                  <ListItem button sx={{ pl: 2 }}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      disableTypography
                      sx={{ fontWeight: '300' }}
                    />
                  </ListItem>
                </Styled.LinkMenu>
              ))}
            </List>
          </List>
        </Styled.MenuContent>

        <Styled.FooterContainer>
            <Styled.Divider />
          <Styled.Footer>
            &copy; {new Date().getFullYear()}{' '}
            <Styled.FooterLink href="http://gamersclub.com.br" target="_blank">
              GamersClub
            </Styled.FooterLink>{' '}
            made with ❤️ for a better backoffice
          </Styled.Footer>
        </Styled.FooterContainer>
      </Styled.MenuContainer>
    </Drawer>
  )
}

export { SideBar }
