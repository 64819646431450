import styled from 'styled-components'

interface CustomErrorWrapperProps {
  centered?: boolean;
}

export const CustomErrorWrapper = styled.p<CustomErrorWrapperProps>`
  color: #f44336;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 6px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  text-align: ${(props) => props.centered ? 'center' : 'left'}
`