import { createTheme, ThemeProvider } from '@mui/material'
import { blue, blueGrey } from '@mui/material/colors'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// COMPONENTS
import Router from './routes/Router'

// STYLES
import * as Styled from './styles'

export default function App(props) {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: blue[700],
        dark: blueGrey[900],
      },
    },
  })

  return (
    <ThemeProvider theme={darkTheme}>
      <Styled.Container>
        <DndProvider backend={HTML5Backend}>
          <Router />
        </DndProvider>
      </Styled.Container>
    </ThemeProvider>
  )
}
