// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import { RestrictionList } from '@shared/types/RegionTypes'

const getRegionsRestrictions = (): Promise<RestrictionList[]> => {
  return marketplaceApi.get(`/backoffice/products/region/restrictions`)
}

export { getRegionsRestrictions }
