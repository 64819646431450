import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getPurchaseTerms,
  deletePurchaseTerms
} from '@services/PurchaseTerm/PurchaseTermService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { Button, Card, LinearProgress } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

// TYPES
import { PurchaseTerm, PurchaseTermData } from '@shared/types/PurchaseTermTypes'

// STYLES
import * as Styled from './PurchaseTermsList.styles'

const PurchaseTermsList = (): JSX.Element => {
  const [purchaseTerms, setPurchaseTerms] = useState<PurchaseTerm[]>([])
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    _getPurchaseTerms()
  }, [])

  const _getPurchaseTerms = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const purchaseTermsData: PurchaseTermData = await getPurchaseTerms()
      setPurchaseTerms(purchaseTermsData.results)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _deletePurchaseTerms = async (id: number): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir o termo de compra?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deletePurchaseTerms(id)
          Alert.success(
          'Termo de compra excluído com sucesso',
          removePurchaseTermsFromState(id)
        )
        }
      })
      .catch((e) => {
        Alert.error('Ocorreu um erro ao excluir o termo de compra')
      })
  }

  const removePurchaseTermsFromState = (id: number): void => {
    const newPurchaseTerms = purchaseTerms.filter(
      (purchaseTerm) => purchaseTerm.id !== id
    )
    setPurchaseTerms(newPurchaseTerms)
  }
  
  const renderCustomLoadingOverlay = ():JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    );
  }

  const renderActionButtons = (id: number): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): void => navigate(`/apps/marketplace/purchaseTerms/${id}`)}
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Editar
        </Button>
        <Button
          onClick={(): Promise<void> => _deletePurchaseTerms(id)}
          color="error"
          variant="contained"
        >
          Excluir
        </Button>
      </>
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'id',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 320
    },
    {
      field: 'description',
      headerName: 'Descrição',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'left',
      align: 'left',
      minWidth: 350,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Termos de Compra">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
    {purchaseTerms?.length > 0 ? (
      <Card
        sx={{
          mt: 4,
          ['& .MuiDataGrid-iconSeparator']: {
            display: 'none'
          }
        }}
      >
        <DataGrid
          paginationMode="server"
          rows={purchaseTerms}
          columns={columns}
          loading={refreshDataLoading}
          getRowId={(row) => row.id}
          pageSize={10}
          rowsPerPageOptions={[4]}
          autoHeight
          rowHeight={80}
          disableSelectionOnClick
          components={{
            LoadingOverlay: renderCustomLoadingOverlay,
          }}
          sx={{
            ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
              outline: 'none'
            },
            ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
              whiteSpace: 'break-spaces'
            },
          }}
        />
      </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { PurchaseTermsList }
