import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  createRedeemCodes,
} from '@services/RedeemCodes/RedeemCodesService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  FormControl,
  Autocomplete,
} from '@mui/material'
import { Product } from '@shared/types/ProductTypes'
import { getProducts } from '@services/Product/ProductService'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

// ICON
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'

const RedeemCodesForm = (): JSX.Element => {
  const [productsItems, setProductsItems] = useState<Product[]>([])
  const [product, setProduct] = useState<Product>()
  const [quantity, setQuantity] = useState<number>(0)
  const [prefix, setPrefix] = useState<string | null>(null)
  const [expiresInDays, setExpiresInDays] = useState<number | null>()

  const navigate = useNavigate()
  
  useEffect(() => {
    getProducts({
      limit: 1999,
    }).then((response) => setProductsItems(response.results))
  }, [])
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    _createRedeemCodes()
  }

  const _createRedeemCodes = async (): Promise<void> => {
    try {
      const payload = {
        expiresInDays,
        prefix,
        productSlug: product.slug,
        quantity,
      }
      await createRedeemCodes(payload)

      Alert.success(
        'Código de resgate de produto criado com sucesso',
        navigate('/apps/marketplace/redeemCodes/list')
      )
    } catch (err) {
      Alert.error(`${err.errors}`)
    }
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Cadastrar código de resgate de produto">
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  return (
    <Card sx={{ width: 600 }}>
      <CardHeader title={renderCardHeader()}  />
      <CardContent sx={{ p: 4, pt: 0}}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {productsItems.length ? (
                <FormControl fullWidth>
                  <Autocomplete
                    onChange={(event: any, newValue: Product) => {
                      setProduct(newValue)
                    }}
                    options={productsItems}
                    getOptionLabel={(option: Product) => `${option.name} (${option.slug})`}
                    renderInput={(params) => (
                      <TextField {...params} label="Produtos" />
                    )}
                  />
                </FormControl>
              ) : (
                <>Carregando produtos...</>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Prefixo da chave"
                variant="outlined"
                fullWidth
                onChange={(e) => setPrefix(e.target.value)}
                value={prefix}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Dias de expiração"
                variant="outlined"
                type="number"
                inputProps={{
                  min: 0,
                  max: 5000,
                }}
                fullWidth
                onChange={(e) => setExpiresInDays(Number(e.target.value))}
                value={expiresInDays}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Quantidade de cupons"
                variant="outlined"
                type="number"
                helperText="Valor máximo permitido: 5000"
                inputProps={{
                  min: 0,
                  max: 5000,
                }}
                fullWidth
                onChange={(e) => setQuantity(Number(e.target.value))}
                value={quantity}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs sx={{ mt: 3 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  Criar código de resgate de produto
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { RedeemCodesForm }
