import { useState } from 'react'
import faker from 'faker'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  TextField,
  Box,
} from '@mui/material'

import { LocalOfferRounded as LocalOfferRoundedIcon } from '@mui/icons-material'

import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

const VoucherCouponGenerator = (): JSX.Element => {
  const [deniedKeys, setDeniedKeys] = useState<string>('')
  const [prefix, setPrefix] = useState<string>('')
  const [keysCount, setKeysCount] = useState<number>(1)
  const [keys, setKeys] = useState<string[]>([])
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  const __generateKeys = async (
    prefix: string,
    count: number,
    existingKeys: string[] = []
  ): Promise<string[]> => {
    const totalCount = existingKeys.length + count

    // Use logarithm(total, 36) to find code lenth needed.
    // Use 36 considering [a-z] and [0-9]
    // Use *2 to decrease the number of colision
    const neededCodeLength = Math.ceil(Math.log(totalCount) / Math.log(36)) * 2
    const codeLength = Math.max(3, neededCodeLength)

    const keysSet = new Set()

    let key: string = ''
    while (keysSet.size < count) {
      key = `${prefix}${prefix ? '-' : ''}${faker.random.alphaNumeric(codeLength)}`
      
      if(existingKeys.includes(key)) {
        continue
      }
      
      keysSet.add(key.toUpperCase())
    }

    return Array.from(keysSet) as string[]
  }

  const _generateKeys = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)

      const existingKeys = deniedKeys.split('\n')
      const keys: string[] = await __generateKeys(prefix, keysCount, existingKeys)

      setKeys(keys)
    } finally {
      setRefreshDataLoading(false)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = [] //validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }

    _generateKeys()
  }

  const _copyValues = (): void => {
    navigator.clipboard.writeText(`${keys.join('\n')}\n`)
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Gerador de Cupons">
        <LocalOfferRoundedIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  return (
    <Card>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs={12}>
              <InputLabel>Cupons para Ignorar</InputLabel>
              <TextField
                  key="deniedKeys"
                  placeholder="Cupons que não serão gerados novamente"
                  multiline={true}
                  minRows={5}
                  maxRows={10}
                  style={{ width: 350 }}
                  onChange={(e) => setDeniedKeys(e.target.value)}
                  value={deniedKeys}
                  />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="prefix"
                type="text"
                label="Prefixo"
                variant="outlined"
                fullWidth
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key="keysCount"
                type="number"
                label="Quantidade"
                variant="outlined"
                fullWidth
                value={keysCount}
                onChange={(e) => setKeysCount(Number(e.target.value))}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  Gerar Cupons
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>

        <div>
          {keys?.length > 0 ? (
            <Grid container spacing={2} sx={{ mt: 0.2 }}>
              <Grid item xs={12}>
                <InputLabel>Cupons Gerados</InputLabel>
                <TextField
                  key="keys"
                  placeholder="Cupons"
                  multiline={true}
                  maxRows={10}
                  style={{ width: 350 }}
                  value={`${keys.join('\n')}\n`}
                />
              </Grid>
              <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="flex-start"
              >
                <Button
                  variant="contained"
                  onClick={() => _copyValues()}>
                  Copiar
                </Button>
              </Box>
            </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export { VoucherCouponGenerator }
