import { useState } from 'react'

// COMPONENTS
import {
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@mui/material'

// STYLES
import * as Styled from './styles'

// TYPES
import { PurchaseTermsProps as Props } from './types'

const PurchaseTerms = (props: Props): JSX.Element => {
  const [showAll, setShowAll] = useState<boolean>(false)
  const { productTerms, purchaseTerms, updateProductState } = props

  const updatePurchaseTermsState = (termId: number): void => {
    const isSelected = productTerms.includes(termId)
    let newTerms: number[]

    if (isSelected) {
      newTerms = productTerms.filter((term) => term !== termId)
    } else {
      newTerms = [...productTerms, termId]
    }

    updateProductState('purchaseTerms', newTerms)
  }

  const handleClick = (): void => {
    setShowAll(prev => !prev)
  }

  const clickText = showAll ? 'esconder' : 'ver todos'

  return (
    <Styled.Container>
      <Styled.Label>
        Termos de compra (<Styled.SeeAll onClick={() => handleClick()}>{clickText}</Styled.SeeAll>)
      </Styled.Label>
      {showAll && <Styled.Checkboxes>
        {purchaseTerms &&
          purchaseTerms.map((term) => (
            <FormGroup key={term.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={productTerms.includes(term.id)}
                    onChange={() => updatePurchaseTermsState(term.id)}
                    value={term.id}
                  />
                }
                label={term.description}
              />
            </FormGroup>
          ))}
      </Styled.Checkboxes>}
    </Styled.Container>
  )
}

export { PurchaseTerms }
