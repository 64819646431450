import {
  useEffect,
  useState
} from 'react'
import { useFormContext } from 'react-hook-form'

// COMPONENTS
import { CustomTextField } from '@components/shared/CustomTextField/CustomTextField'
import { CustomSelect } from '@components/shared/CustomSelect/CustomSelect'
import { Grid } from '@mui/material'
import { Option } from '@components/shared/CustomSelect/types'

const MetadataFields = () => {
  const {
    watch,
    setValue
  } = useFormContext()

  const planSystem = watch('systemId')

  useEffect(() => {
    if(planSystem.toString() === '1') {
      setValue('discountText', undefined)
      setValue('passId', undefined)
    } else if (planSystem.toString() === '4') {
      setValue('monthlySavings', undefined)
      setValue('medalId', undefined)
    }
  }, [ planSystem ])

  return (
    <>  
      <Grid item xs={6}>
        <CustomTextField name="activationTimeValue" label="Quantidade de dias do plano *" />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField numeric={true} name="monthlyValue" label="Valor mensal *" />
      </Grid>
      {planSystem.toString() === '1' && (
        <>
          <Grid item xs={6}>
            <CustomTextField name="monthlySavings" label="% de economia mensal *" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name="medalId" label="ID da medalha *" />
          </Grid>
        </>
      )}
      {planSystem.toString() === '4' && (
        <>
          <Grid item xs={6}>
            <CustomTextField name="discountText" label="Texto informativo desconto *" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField name="passId" label="ID do passe *" />
          </Grid>
        </>
      )}
    </>
  )
}

export { MetadataFields }