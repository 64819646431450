import axios from 'axios'
import { PAYMENT_API_URL } from '@config/environment'

// SHARED INTERCEPTORS
import { _shared } from './../../../../../root/src/app/shared/shared'
const shared = _shared

const paymentApi = axios.create({
  baseURL: PAYMENT_API_URL,
})

paymentApi.interceptors.request.use(shared.authInterceptor)
paymentApi.interceptors.response.use(
  shared.responseInterceptor,
  shared.checkTokenValidityInterceptor
)

export { paymentApi }
