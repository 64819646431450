import styled from 'styled-components'
import { Button } from '@mui/material'

interface FeaturedTextProps {
  backgroundColor: string
  textColor: string
}

export const Container = styled.div`
  width: 100%;
`

export const ProductImage = styled.img`
  height: 56px;
  width: 56px;
`

export const CustomButton = styled(Button)`
  height: 100%;
`

export const FullWidthForm = styled.form`
  display: flex;
  width: 100%;
`

export const CustomLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`

export const FeaturedText = styled.span<FeaturedTextProps>`
  padding: 2px 6px;
  border-radius: 2px;
  color: ${(props) => (props.textColor ? props.textColor : '#6A6A6A')};
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#EBEBEB'};
`

export const InlineBlockContainer = styled.div`
  display: inline-block;
`
