// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  Offer,
  GetOffersResponse,
  CreateOfferResponse,
  CreateOfferPayload,
} from '@shared/types/OffersTypes'

const getOfferTypes = (): Promise<string[]> =>
  marketplaceApi.get(`/backoffice/campaign/type/list`)

const getOffers = (data?): Promise<GetOffersResponse> => {
  let params = data && new URLSearchParams(data);
  if (params) {
    params.append('type', 'SUBSCRIPTION')
    params.append('type', 'GIFT_CARD')
  }
  const queryParams = params ? `?${params.toString()}` : ''
  return marketplaceApi.get(`/backoffice/campaign/list${queryParams}`)
}

const getOffer = (id: string): Promise<Offer> =>
  marketplaceApi.get(`/backoffice/campaign/${id}`)

const createOffer = (
  data: CreateOfferPayload
): Promise<CreateOfferResponse> => 
  marketplaceApi.post('/backoffice/campaign', data)

const updateOffer = (
  id: string,
  data: CreateOfferPayload
): Promise<CreateOfferResponse> =>
  
  marketplaceApi.patch(`/backoffice/campaign`, {
    id,
    ...data
  })

const deleteOffer = (id: string): Promise<Offer> =>
  marketplaceApi.delete(`/backoffice/campaign/${id}`)

export { getOfferTypes, getOffer, getOffers, createOffer, updateOffer, deleteOffer }
