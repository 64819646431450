import {
  useState,
  useEffect
} from 'react'
import { useFormContext } from 'react-hook-form'

// COMPONENTS
import { Grid, FormHelperText } from '@mui/material'
import { CustomSelect } from '@components/shared/CustomSelect/CustomSelect'
import { CustomTextField } from '@components/shared/CustomTextField/CustomTextField'
import { Loader } from '@components/Loader/Loader'

// TYPES
import { ComplementaryFieldsProps } from './types'
import { Restriction } from '../types'
import { Option } from '@components/shared/CustomSelect/types'

// SERVICES
import { getProducts } from '@services/Product/ProductService'
import { GetProductsResponse } from '@shared/types/ProductTypes'

const ComplementaryFields = (props: ComplementaryFieldsProps) => {
  const [regions, setRegions] = useState<Restriction[]>([])
  const [formattedCampaignOptions, setFormattedCampaignOptions] = useState<Option[]>([])
  const {
    watch,
    setValue
  } = useFormContext()

  const trialType = watch('trialType')
  const { regionRestrictions, planTypes, accountGroups, campaignOptions, trialTypes } = props
  const [
    plansProducts,
    setPlansProducts
  ] = useState<any>([])

  useEffect(() => {
    if (!regionRestrictions) return

    setRegions(regionRestrictions.restrictions)
  }, [regionRestrictions])

  useEffect(() => {
    const newCampaignOptions = [{
      name: 'N/A',
      id: '0'
    }]

    campaignOptions.map((option) => {
      newCampaignOptions.push({
        name: option.title,
        id: option.id
      })
    })

    setFormattedCampaignOptions(newCampaignOptions)
    handleTrialTypeChange(trialType)
  }, [
    campaignOptions,
    trialType
  ])

  const handleTrialTypeChange = (_trialType: string): void => {
    if (!_trialType) {
      setValue('trialDays', undefined)
      setValue('nextProductId', undefined)
    }
    if (_trialType === 'PRICE_DISCOUNT') {
      setValue('trialDays', undefined)
      if (getPlansProducts.length === 0) getPlansProducts()
    }
    if (_trialType === 'FREE_DAYS') setValue('nextProductId', undefined)
  }
  

  const getPlansProducts =  async (): Promise<void> => {
    const queryParams = {
      page: 1,
      limit: 99,
    }
    try {
      const categoryNames = ['subscription-v1']
      const productsData: GetProductsResponse|any = await getProducts(
        queryParams,
        categoryNames
      )
      setPlansProducts(
        [{id: '', name: '-'}]
        .concat(
          productsData.results
          .map(item => {
            item.name = `${item.name} - ${item.slug}`
            return item
          })
        )
      )
    } finally {}
  }

  return (
    <>
      <Grid item xs={6}>
        <CustomSelect name="planType" id="planType" label="Tipo de plano" options={planTypes}/>
      </Grid>
      <Grid item xs={6}>
        <CustomSelect multiple={true} name="regions" id="regions" label="Região Comercial" options={regions}/>
        <FormHelperText>Caso vazio, ficará disponível para todas as regiões.</FormHelperText>
      </Grid>
      <Grid item xs={4}>
        <CustomTextField name="subscriptionId" label="Subscription ID *" />
      </Grid>
      <Grid item xs={4}>
        <CustomSelect name="campaignId" id="campaignId" label="Associar promoção" options={formattedCampaignOptions}/>
      </Grid>
      <Grid item xs={4}>
        <CustomSelect multiple={true} customValueKey="tag" name="accountGroups" id="accountGroups" label="Grupos de usuários" options={accountGroups}/>
      </Grid>
      <Grid item xs={4}>
        <CustomSelect name="trialType" id="trialType" label="Plano é Trial? Qual o tipo?" options={trialTypes} />
      </Grid>
      {trialType === 'FREE_DAYS' && (
        <>
          <Grid item xs={4}>
            <CustomTextField 
              name="trialDays" 
              label="Dias gratuitos em trial *" />
          </Grid>
        </>
      )}
      {trialType === 'PRICE_DISCOUNT' && (
        <>
          <Grid item xs={4}>
            {plansProducts.length === 0 && (
              <Loader type="flex" />
            )}
            {plansProducts.length > 0 && (
              <CustomSelect 
                name="nextProductId" 
                id="nextProductId" 
                label="Plano/produto final após o trial *" 
                options={plansProducts} />
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12}></Grid>
    </>
  )
}

export { ComplementaryFields }