import * as React from 'react'

// MUI
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// TYPES
import { ModalProps } from './types'

// STYLES
import * as S from './styles'

// HELPERS
import { formatDate } from '@shared/utils/FormatDate'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const Modal = ({
  handleClose,
  open,
  params
}: ModalProps): JSX.Element => {
  return (
    <S.ModalContainer>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={false}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Status da troca
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <S.ContentContainer>
            <S.DataContainer>
              <S.DataLine>
                <S.DataTitle>ID da troca: </S.DataTitle>
                <S.DataContent>{params?.id}</S.DataContent>
              </S.DataLine>
              <S.DataLine>
                <S.DataTitle>Status: </S.DataTitle>
                <S.DataContent>{params?.status}</S.DataContent>
              </S.DataLine>
              <S.DataLine>
                <S.DataTitle>Criada em: </S.DataTitle>
                <S.DataContent>{params?.createdAt && formatDate(params?.createdAt)}</S.DataContent>
              </S.DataLine>
              <S.DataLine>
                <S.DataTitle>Troca realizada em: </S.DataTitle>
                <S.DataContent>{params?.tradedAt && formatDate(params?.tradedAt)}</S.DataContent>
              </S.DataLine>
            </S.DataContainer>
            <S.FiguresContainer>
              <S.StickerContainer>
                <S.DataTitle>Enviada: {params?.sticker?.title}</S.DataTitle>
                <S.DataContent>{`(${params?.sticker?.type})`}</S.DataContent>
                <S.Figure src={params?.sticker.image} />
              </S.StickerContainer>
              <S.StickerContainer>
                <S.DataTitle>Recebida: {params?.tradedSticker?.title || ''}</S.DataTitle>
                <S.DataContent>{params?.tradedSticker?.type && `(${params.tradedSticker?.type})`}</S.DataContent>
                {params?.tradedSticker?.image &&
                  <S.Figure src={params?.tradedSticker?.image} />
                }
              </S.StickerContainer>
            </S.FiguresContainer>
          </S.ContentContainer>
        </DialogContent>
      </BootstrapDialog>
    </S.ModalContainer>
  )
}

export { Modal }
