import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { _shared } from './../../../../root/src/app/shared/shared'
export const formatDate = _shared.formatDate
export const FormattedDate = (value: string) => {
  const currentDate = format(parseISO(value), 'PP - KK:mm a', {
    locale: ptBR,
  })
  
  return currentDate
}