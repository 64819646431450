import React from 'react'

// STYLES
import * as Styled from './styles'

// COMPONENTS
import CircularProgress from '@mui/material/CircularProgress'

// TYPES
import { LoaderProps } from './types'

const Loader = (props: LoaderProps): JSX.Element => {
  const { type, size = 64, color = 'primary', thickness = 4 } = props

  if (type === 'flex') {
    return (
      <Styled.FlexContainer>
        <CircularProgress size={size} color={color} thickness={thickness} />
      </Styled.FlexContainer>
    )
  } else {
    return (
      <Styled.OverlayContainer>
        <CircularProgress size={size} color={color} thickness={thickness} />
      </Styled.OverlayContainer>
    )
  }
}

export { Loader }
