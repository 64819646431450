const trialTypes = [
  {
    id: '',
    name: 'Não'
  },
  {
    id: 'FREE_DAYS',
    name: 'Sim, cobrar após X dias gratuitos (freeDays)'
  },
  {
    id: 'PRICE_DISCOUNT',
    name: 'Sim, desconto nos primeiros dias ou meses (priceDiscount)'
  }
]

const getTrialTypes = (): Array<{id: string; name: string;}> => trialTypes

export { getTrialTypes }
