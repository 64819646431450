import { useCallback } from 'react'

export interface CropModalModel {
  height: number
  width: number
  x: number
  y: number
}

interface useCropModalReturn {
  createImage: (url: string) => Promise<HTMLImageElement>
  getCroppedImg: (image: HTMLImageElement, crop: CropModalModel) => string
}

export const useCropModal = (): useCropModalReturn => {
  const createImage = useCallback(
    (url: string): Promise<HTMLImageElement> =>
      new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
      }),
    []
  )

  const getCroppedImg = useCallback(
    (image: HTMLImageElement, crop: CropModalModel): string => {
      const canvas = document.createElement('canvas')
      const pixelRatio = window.devicePixelRatio
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        return ''
      }

      canvas.width = crop.width * pixelRatio * scaleX
      canvas.height = crop.height * pixelRatio * scaleY

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
      ctx.imageSmoothingQuality = 'high'

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      )
      return canvas.toDataURL('image/png')
    },
    []
  )

  return {
    createImage,
    getCroppedImg,
  }
}
