const systems = [
  {
    id: 1,
    name: 'CS:GO',
  },
  {
    id: 2,
    name: 'Coins',
  },
  {
    id: 3,
    name: 'Drops CS:GO',
  },
  {
    id: 4,
    name: 'Multigaming',
  },
]

export { systems }
