import styled from 'styled-components'

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const OverlayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 220px;
  background: #000;
  opacity: 0.75;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
`
