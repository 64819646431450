import { useFormContext, Controller } from 'react-hook-form'

// TYPES
import { CustomRichTextEditorProps as Props } from './types'

// COMPONENTS
import RichTextEditor from '@shared/helpers/RichTextEditor'
import { InputLabel } from '@mui/material'
import { CustomError } from '@components/shared/CustomError/CustomError'

const CustomRichTextEditor = (props: Props) => {
  const { name, label, required } = props
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext()

  const requiredSymbol = required ? ' *' : ''

  return (
    <>
      <InputLabel sx={{ mb: 1, color: errors[name] ? '#f44336' : 'rgba(255, 255, 255, 0.7)'}}>{label}{requiredSymbol}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <RichTextEditor
            value={getValues(name)}
            outputFormat="html"
            onChange={(value) => onChange(value)}
          />
        )}
      />
      {errors[name] && (
        <CustomError message={`${errors[name].message}`} />
      )}
    </>
  )
}

export { CustomRichTextEditor }
