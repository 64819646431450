import { FormGroup, Checkbox, FormControlLabel } from '@mui/material'

const CheckboxComponent = (props): JSX.Element => {
  const {
    keyName,
    checked,
    disabled,
    label,
    value,
    onChange
  } = props

  return (
    <FormGroup>
      <FormControlLabel
        key={keyName}
        disabled={disabled}
        checked={checked}
        control={<Checkbox />}
        label={label}
        value={value}
        onChange={onChange}
      />
    </FormGroup>
  )
}

export { CheckboxComponent }
