import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SideBar } from '@components/SideBar/SideBar'

import routes from './routes'

import * as Styled from './styles'

const objRoutes = routes()

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <SideBar />
      <Styled.Container>
        <Routes>
          {objRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          ))}
        </Routes>
      </Styled.Container>
    </BrowserRouter>
  )
}

export default Router
