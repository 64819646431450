import { _shared } from './../../../../../auth/src/shared/shared'
import axios from 'axios'

export async function _checkTokenValidityInterceptor(response) {
  const $auth = _shared.$auth
  const responseData = response.response.data

  const refreshTokenUrl = `${process.env.MULTIGAMING_API_URL}/backoffice/accounts/refresh-token`

  if (responseData.statusCode === 401) {
    try {
      const refreshTokenRequest = axios.create({})

      const authToken = window.localStorage.getItem('console:global:authToken')
      const refreshToken = window.localStorage.getItem('console:global:refreshToken')

      const headers = {
        Authorization: authToken,
        'X-Refresh-Token': refreshToken,
      }

      const refreshTokenResponse = await refreshTokenRequest.get(refreshTokenUrl, { headers })
      
      localStorage.setItem('console:global:authToken', refreshTokenResponse.headers.authorization)
      localStorage.setItem('console:global:refreshToken', refreshTokenResponse.headers['x-refresh-token'])
    } catch {

      $auth.logout()
      return responseData
    }
  } else if (responseData.statusCode >= 400) {
    throw responseData
  }

  return responseData
}
