import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

// SERVICES
import {
  getCategories,
  deleteCategory,
} from '@services/Category/CategoryService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// TYPES
import { Category, CategoryData } from '@shared/types/CategoryTypes'

// STYLES
import * as Styled from './styles'

// COMPONENTS
import { Button, Card } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { Loader } from '@components/Loader/Loader'

const CategoryList = (): JSX.Element => {
  const [categories, setCategories] = useState<Category[]>([])

  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    _getCategories()
  }, [])

  const _getCategories = async (): Promise<void> => {
    try {
      const categories: CategoryData = await getCategories()
      setCategories(categories.productCategories)
    } finally {
      setIsLoading(false)
    }
  }

  const _deleteCategory = async (id: string): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir a categoria?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteCategory(id)
          Alert.success(
            'Categoria excluída com sucesso',
            removeCategoryFromState(id)
          )
        }
      })
      .catch((e) => {
        Alert.error('Ocorreu um erro ao excluir a categoria')
      })
  }

  const removeCategoryFromState = (id: string): void => {
    const newCategories = categories.filter(
      (category) => category.id !== parseInt(id)
    )
    setCategories(newCategories)
  }

  const actionButtons = (id: string): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): void => navigate(`/apps/marketplace/category/${id}`)}
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Editar
        </Button>
        <Button
          onClick={(): Promise<void> => _deleteCategory(id)}
          color="error"
          variant="contained"
        >
          Excluir
        </Button>
      </>
    )
  }

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.25, minWidth: 100 },
    { field: 'name', headerName: 'Nome', flex: 1, minWidth: 300 },
    { field: 'description', headerName: 'Descrição', flex: 1, minWidth: 500 },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => actionButtons(params.id),
    },
  ]

  if (isLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de categorias">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      {categories?.length > 0 ? (
        <Card
          sx={{
            mt: 4,
            ['& .MuiDataGrid-iconSeparator']: {
              display: 'none',
            },
          }}
        >
          <DataGrid
            rows={categories}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[4]}
            autoHeight
            disableSelectionOnClick
            sx={{
              ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                outline: 'none',
              },
            }}
          />
        </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { CategoryList }
