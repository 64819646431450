import { PaymentRestrictionStructure } from './PaymentTypes'
import { RestrictionStructure } from "./RegionTypes"
import { AccountRestriction } from './AccountTypes'

export interface Restrictions {
  payment: {
    paymentMethod: PaymentRestrictionStructure[]
  },
  region: {
    commercialRegion: RestrictionStructure[]
  }
  account: {
    groupTags: AccountRestriction[]
  }
}

export const initialRestrictionsState: Restrictions = {
  payment: {
    paymentMethod: [],
  },
  region: {
    commercialRegion: [],
  },
  account: {
    groupTags: [],
  },
}
