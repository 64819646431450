// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

import {
  Highlights,
  GetHighlightsResponse,
  CreateHighlightsResponse,
  UpdateHighlightsPayload,
} from '@shared/types/HighlightsType'

import { GetProductsResponse } from '@shared/types/ProductTypes'

const getHighlight = (id: string): Promise<Highlights> => {
  return marketplaceApi.get(`/backoffice/highlights/${id}`)
}

const getHighlights = (data?): Promise<GetHighlightsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/highlights/${queryString}`)
}

const createHighlights = (
  data: FormData
): Promise<CreateHighlightsResponse> => {
  return marketplaceApi.post('/backoffice/highlights', data)
}

const updateHighlights = (
  data: UpdateHighlightsPayload
): Promise<CreateHighlightsResponse> => {
  return marketplaceApi.put(`/backoffice/highlights/${data.id}`, data)
}

const deleteHighlights = (id: string): Promise<Highlights> => {
  return marketplaceApi.delete(`/backoffice/highlights/${id}`)
}

const getProducts = (data?): Promise<GetProductsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/products/${queryString}`)
}

export {
  getHighlight,
  getHighlights,
  createHighlights,
  updateHighlights,
  deleteHighlights,
  getProducts,
}
