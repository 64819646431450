import { useState, Fragment } from 'react'

// @MATERIAL-UI/COMPONENTS
import {
  Button,
  IconButton,
  Box,
  Grid,
  TextareaAutosize,
  InputLabel,
} from '@mui/material'
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'

// TYPES
import { RulesProps as Props } from './types'

const RulesComponent = (props: Props): JSX.Element => {
  const { allRules, setAllRules } = props

  const addRuleInput = (): void => {
    setAllRules((previous) => [...previous, ''])
  }

  const removeRuleInput = (index): void => {
    const newList = [...allRules]
    newList.splice(index, 1)
    setAllRules(newList)
  }

  const updateRules = (value, index): void => {
    const newList = [...allRules]
    newList[index] = value
    setAllRules(newList)
  }

  return (
    <>
      {allRules?.map((rule, ruleIndex) => (
        <Fragment key={ruleIndex}>
          <InputLabel>Regras</InputLabel>
          <TextareaAutosize
            required
            minRows={5}
            placeholder="Regra"
            style={{ width: 250 }}
            onChange={(e) => updateRules(e.target.value, ruleIndex)}
            value={rule}
          />

          <IconButton
            color="primary"
            component="span"
            onClick={() => addRuleInput()}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            color="error"
            component="span"
            onClick={() => removeRuleInput(ruleIndex)}
          >
            <DeleteIcon />
          </IconButton>
        </Fragment>
      ))}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Box display="flex" justifyContent={{ xs: 'flex-start' }}>
          {allRules.length === 0 && (
            <Button variant="contained" onClick={() => addRuleInput()}>
              Criar Regra
            </Button>
          )}
        </Box>
      </Grid>
    </>
  )
}

export { RulesComponent }
