// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  CreateDiscountPayload,
  DiscountProduct,
  DiscountType
} from '@shared/types/DiscountProductsTypes'

export const getDiscounts = (): Promise<DiscountProduct[]> => {
  return marketplaceApi.get(`/backoffice/discount/price`)
}

export const getDiscountTypes = (): Promise<DiscountType[]> => {
  return marketplaceApi.get(`/backoffice/discount/type`)
}

export const deleteDiscount = (id: number): Promise<void> => {
  return marketplaceApi.delete(`/backoffice/discount/price/${id}`)
}

export const createDiscount = (
  data: CreateDiscountPayload
): Promise<CreateDiscountPayload> => {
  return marketplaceApi.post('/backoffice/discount/price', data)
}
