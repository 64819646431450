import styled from 'styled-components'

export const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  img {
    height: 150px;
    margin-bottom: 10px;
  }

  button {
    align-self: flex-start;
  }
`