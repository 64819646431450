import styled from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  input[type='file'] {
    display: none;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
`

export const InputFile = styled.input``

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
`
