import React from 'react'

// ASSETS
import GoldCoinIcon from '@assets/svg/goldCoin.svg'
import SilverCoinIcon from '@assets/svg/silverCoin.svg'

// STYLES
import * as Styled from './styles'

// TYPES
import { PriceValueProps } from './types'

const renderCoin = (symbol: string): JSX.Element | string => {
  if (symbol === 'hard' || symbol === 'soft') {
    return (
      <img src={symbol === 'hard' ? GoldCoinIcon : SilverCoinIcon} alt="Logo" />
    )
  }

  return symbol
}

const renderTransaction = (symbol: string, price: number): JSX.Element => {
  return (
    <>
      {renderCoin(symbol)}
      <Styled.MoneyValue>{price}</Styled.MoneyValue>
    </>
  )
}

export const PriceValue = (props: PriceValueProps): JSX.Element => {
  const { prices } = props

  return (
    <Styled.Container>
      {prices.map((price) => (
        <Styled.PriceContainer key={`${price.price}-${price.symbol}`}>
          {renderTransaction(price.symbol, price.price)}
        </Styled.PriceContainer>
      ))}
    </Styled.Container>
  )
}
