// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import { PlanTypeData } from '@shared/types/PlanTypeTypes'

const getPlanTypes = (): Promise<PlanTypeData> => {
  return marketplaceApi.get(`/backoffice/plan-type/`)
}

export { getPlanTypes }
