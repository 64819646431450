// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  Category,
  CategoryData,
  CreateCategoryResponse,
  CreateCategoryPayload,
  UpdateCategoryPayload,
} from '@shared/types/CategoryTypes'

const getCategory = (id: string): Promise<Category> => {
  return marketplaceApi.get(`/backoffice/category/${id}`)
}

const createCategory = (
  data: CreateCategoryPayload
): Promise<CreateCategoryResponse> => {
  return marketplaceApi.post('/backoffice/category', data)
}

const updateCategory = (
  data: UpdateCategoryPayload
): Promise<CreateCategoryResponse> => {
  return marketplaceApi.patch('/backoffice/category', data)
}

const deleteCategory = (id: string): Promise<Category> => {
  return marketplaceApi.delete(`/backoffice/category/${id}`)
}

const getCategories = (data?): Promise<CategoryData> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/category${queryString}`)
}

export {
  getCategory,
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
}
