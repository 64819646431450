// @MATERIAL-UI/COMPONENTS
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material'

// TYPES
import { AccountGroupsProps as Props } from './types'

const AccountGroups = (props: Props): JSX.Element => {
  const { accountGroups, restrictions, setRestrictions } = props

  const handleAccountGroupsChange = (e): void => {
    const selectedGroups = e.target.value
    const groupTagsArray = []

    selectedGroups.forEach((groupTag) => {
      const groupObject = {
        id: groupTag,
      }
      groupTagsArray.push(groupObject)
    })

    setRestrictions({
      ...restrictions,
      account: {
        groupTags: groupTagsArray,
      },
    })
  }

  const getAccountGroupsValue = (): string[] => {
    const accountGroupsValue = []

    restrictions.account?.groupTags.forEach((item) => {
      accountGroupsValue.push(item.id)
    })

    return accountGroupsValue
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="accountGroupsSelect">Grupo de usuários</InputLabel>
      <Select
        labelId="accountGroupsSelect"
        label="Grupo de usuários"
        onChange={(e) => {
          handleAccountGroupsChange(e)
        }}
        multiple
        value={getAccountGroupsValue()}
      >
        <MenuItem disabled value={-1}>
          Grupo de usuários
        </MenuItem>
        {accountGroups.map((option) => (
          <MenuItem key={option.id} value={option.tag}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export { AccountGroups }
