import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { validationSchema } from './schema'
import { Buffer } from 'buffer'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import {
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  InputLabel
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { CustomTextField } from '@components/shared/CustomTextField/CustomTextField'
import { CustomDateTimePicker } from '@components/shared/CustomDatePicker/CustomDatePicker'
import { Loader } from '@components/Loader/Loader'
import { CustomRichTextEditor } from '@components/shared/CustomRichTextEditor/CustomRichTextEditor'
import { CustomToggle } from '@components/shared/CustomToggle/CustomToggle'
import { CustomImageUpload } from '@components/shared/CustomImageUpload/CustomImageUpload'
import { CustomSelect } from '@components/shared/CustomSelect/CustomSelect'
import { Subtitles } from '@components/Offers/OffersForm/Subtitles/Subtitles'

// STYLES
import * as Styled from './styles'

// REQUESTS
import { getOfferTypes, createOffer, updateOffer, getOffer } from '@services/Offers/OffersService'

const OffersForm = (): JSX.Element => {
  const formDefaultValues = {
    title: '',
    type: 'SUBSCRIPTION',
    initialDate: null,
    finalDate: null,
    targetPlayerSubscriptionLevel: 'FREE',
    slug: '',
    terms: '',
    hideFromMainList: false,
    image: '',
    tag: '',
    color: '',
    imageMobile: '',
    featuredText: '',
    subtitle: '',
    partner: ''
  }

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: formDefaultValues,
  })

  const [
    loading,
    setLoading
  ] = useState(true)

  const [
    subtitles,
    setSubtitles
  ] = useState([] as string[])

  const [
    offerTypes,
    setOfferTypes,
  ] = useState<{ id: string, name: string }[]>([])

  const { handleSubmit } = form
  const { id } = useParams()
  const navigate = useNavigate()
  const formName = 'Promoção'
  const formTitleAction = id ? 'Editar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'
  const successText = `${formName} ${id ? 'editada' : 'cadastrada'} com sucesso`
  const offersListPath = '/apps/marketplace/offers/list'

  useEffect(() => {
    getFormFieldsData()
    id
      ? getOfferData(id)
      : setOfferData()
  }, [id])

  const setOfferData = (data?) => {
    form.setValue('title', data ? data.title : formDefaultValues.title)
    form.setValue('type', data ? data.type : formDefaultValues.type)
    form.setValue('initialDate', data ? new Date(data.initialDate) : new Date())
    form.setValue('finalDate', data ? new Date(data.finalDate) : new Date())
    form.setValue(
      'targetPlayerSubscriptionLevel',
      data
        ? data.targetPlayerSubscriptionLevel
        : formDefaultValues.targetPlayerSubscriptionLevel
    )
    form.setValue('slug', data ? data.slug : formDefaultValues.slug)
    form.setValue('terms', data ? data.terms : formDefaultValues.terms)
    form.setValue('hideFromMainList', data ? data.hideFromMainList : formDefaultValues.hideFromMainList)
    form.setValue('image', data ? data.image : formDefaultValues.image)
    form.setValue('tag', data ? data.tag : formDefaultValues.tag)
    form.setValue('color', data ? data.color : formDefaultValues.color)
    form.setValue('imageMobile', data ? data.imageMobile : formDefaultValues.imageMobile)
    form.setValue('featuredText', data ? data.featuredText : formDefaultValues.featuredText)
    form.setValue('partner', data ? data.partner : formDefaultValues.partner)

    const subtitlesData = data ? data.subtitle : formDefaultValues.subtitle
    _setSubtitles(subtitlesData)

    if (loading) setLoading(false)
  }

  const _setSubtitles = (subtitlesData: string):void => {
    if (subtitlesData && subtitlesData.length > 0) {
      const subtitleFields = subtitlesData.split('|')
      setSubtitles(subtitleFields)
    }
  }

  const getFormFieldsData = async (): Promise<void> => {
    Promise.all([
      getOfferTypes().then((response) => setOfferTypes(
        response.map(response => ({id: response, name: response}))))
    ])
    .then(() => true)
  }

  const getOfferData = async (id: string): Promise<void> => {
    setLoading(true)
    try {
      const response = await getOffer(id)
      setOfferData(response.campaign)
    } catch {
      Alert.error(`Erro ao carregar dados da ${formName}`)
    }
    finally { setLoading(false) }
  }

  const save = async (data): Promise<void> => {
    const finalPayload = Object.assign({}, {
      ...data,
      terms: Buffer.from(data.terms || '', 'binary').toString('base64')
    });
    delete finalPayload.subtitle

    const subtitleKeysArray = Object.keys(finalPayload).filter((i => i.startsWith("subtitle")))
    const subtitleValuesArray = Object.entries(finalPayload).filter(i => i[0].startsWith("subtitle") && i[1] && i[1] !== '').map(i => i[1])

    const subtitleFields = subtitleValuesArray.join('|')

    finalPayload.subtitle = subtitleFields

    subtitleKeysArray.forEach((item) => {
      delete finalPayload[item]    
    })

    setLoading(true)
    try {
      id
        ? await updateOffer(id, finalPayload)
        : await createOffer(finalPayload)

      Alert.success(successText)
      navigate(offersListPath)
    } catch(error) {
      Alert.error(error.errors.join('.\n'))
    }
    finally { setLoading(false) }
  }

  const onSubmit = data => save(data)

  return (
    <Card>
      {loading && <Loader type="overlay" />}
      <CardContent sx={{ p: 2 }}>
        <HeaderTitle title={`${formTitleAction} ${formName}`}>
          <AddIcon fontSize="large" />
        </HeaderTitle>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <CustomTextField
                  name="title"
                  label="Título *" />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  name="slug"
                  label="Slug *" />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  name="tag"
                  label="Tag" />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  name="color"
                  label="Cor Principal" />
              </Grid>
              <Grid item xs={3}>
                <CustomSelect
                  id="type"
                  name="type"
                  label="Tipo da Promoção *"
                  options={offerTypes}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  name="targetPlayerSubscriptionLevel"
                  label="Público-alvo *"
                  disabled />
              </Grid>
              <Grid item xs={3}>
                <CustomDateTimePicker
                  name="initialDate"
                  label="Data de Início *" />
              </Grid>
              <Grid item xs={3}>
                <CustomDateTimePicker
                  name="finalDate"
                  label="Data de Fim *" />
              </Grid>
              <Grid item xs={3}>
                <Styled.ImageUploadContainer>
                  <CustomImageUpload label="Imagem" name="image"/>
                </Styled.ImageUploadContainer>
              </Grid>
              <Grid item xs={3}>
                <Styled.ImageUploadContainer>
                  <CustomImageUpload label="Imagem Mobile" name="imageMobile"/>
                </Styled.ImageUploadContainer>
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  name="featuredText"
                  label="Texto em destaque para os planos" />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  name="partner"
                  label="Parceiro" />
              </Grid>
              <Subtitles subtitles={subtitles} setSubtitles={setSubtitles}/>
              <Grid item xs={12}>
                <CustomRichTextEditor label="Termos" name="terms" />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Opções da promoção:</InputLabel>
                <CustomToggle label="Esconder da listagem principal" name="hideFromMainList"/>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Box
                  display="flex"
                  justifyContent={{
                    xs: 'flex-start',
                    sm: 'flex-end'
                  }}>
                  <Button
                    variant="contained"
                    type="submit">
                    {buttonLabelAction} {formName}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  )
}
export { OffersForm }