import React, {
  useState, useEffect
} from 'react'

// @MATERIAL-UI/COMPONENTS
import {
  Button,
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

// TYPES
import {
  BundleProductProps as Props,
  BundleProduct
} from './types'
import { Product } from '@shared/types/ProductTypes'

// SERVICES
import { getProducts } from '@services/Product/ProductService'

const Bundles = (props: Props): JSX.Element => {
  const {
    bundleProducts,
    setBundleProducts,
    updateProductState
  } = props
  
  const [allProducts, setAllProducts] = useState<Product[]>([])
  const [initialState, setInitialState] = useState<BundleProduct>()

  useEffect(() => {
    getProducts({
      limit: 1999,
    }).then((response) => {
      setAllProducts(response.results.sort((a, b) => a.name > b.name ? 1 : -1))
      setInitialState({
        quantity: 1,
        slug: response.results[0].slug,
      })
    })
  }, [])

  useEffect(() => {
    updateProductState('children', bundleProducts)
  }, [bundleProducts])

  const addProductInput = (): void => {
    setBundleProducts((previous) => [...previous, initialState])
  }

  const removeProductInput = (index): void => {
    const newList = [...bundleProducts]
    newList.splice(index, 1)
    setBundleProducts(newList)
  }

  const updateSlug = (productSlug, index): void => {
    const newList = [...bundleProducts]
    newList[index] = { quantity: newList[index].quantity, slug: productSlug }
    setBundleProducts(newList)
  }

  const updateQuantity = (quantity, index): void => {
    const newList = [...bundleProducts]
    newList[index] = { quantity, slug: newList[index].slug }
    setBundleProducts(newList)
  }

  const DropdownStyles = {
    PaperProps: {
      style: {
        maxHeight: 500,
      },
    },
  }

  const bundlesStyles = {
    backgroundColor: 'primary.dark',
    borderRadius: '5px',
    padding: '5px',
    margin: '1px 0 0 1px',
    width: '100%',
  }

  return (
    <Grid container spacing={2} sx={bundlesStyles}>
      {
        bundleProducts?.map((product, productIndex) => (
          <React.Fragment key={productIndex}>
            <Grid item xs={8}>
              <Select
                required
                value={product.slug}
                label="Produto"
                MenuProps={DropdownStyles}
                onChange={(e) => updateSlug(e.target.value, productIndex)}
                fullWidth
              >
                {
                  allProducts?.map(product => (
                    <MenuItem  key={product.id} value={product.slug}>
                      {product.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </Grid>
            <Grid item xs={2}>
              <TextField
                required
                label="Quantidade"
                variant="filled"
                fullWidth
                onChange={(e) => updateQuantity(parseInt(e.target.value), productIndex)}
                value={product.quantity}
              />
            </Grid>
            <Grid item xs={2}>
              <Box
                display="flex"
                justifyContent={{ xs: 'center', sm: 'center' }}
                alignItems={{ xs: 'center', sm: 'center' }}
                height={{ xs: 1, sm: 1 }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => removeProductInput(productIndex)}
                >
                  Remover
                </Button>
              </Box>
            </Grid>
          </React.Fragment>
        ))
      }
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
        >
          <Button variant="contained" onClick={() => addProductInput()}>
            {bundleProducts.length === 0 && 'Criar bundle'}
            {bundleProducts.length > 0 && 'Adicionar produto'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export { Bundles }
