import { ProductMetadata } from '@shared/types/ProductTypes'

export interface MetadataProps {
  productType: string
  metadata: ProductMetadata
  updateProductState: (productKey: string, value: any) => void
}

export const productTypes = [
  { id: 'medal', name: 'Medalha' },
  { id: 'sorteio', name: 'Sorteio' },
  { id: 'goldCustom', name: 'Gold Custom' },
  { id: 'goldPackage', name: 'Pacote Gold' },
  { id: 'freePassLobby', name: 'Freepass Lobby' },
  { id: 'ratingPointsItems', name: 'Desafio/Freeze RP' },
  { id: 'subscription', name: 'Assinatura' },
  { id: 'subscriptionMg', name: 'Assinatura na MG' },
  { id: 'vault', name: 'Cofre' },
  { id: 'vaultPassword', name: 'Senha de cofre' },
  { id: 'nickChanger', name: 'Alteração de Nick' },
  { id: 'olheiros', name: 'Olheiros' },
  { id: 'medalSlot', name: 'Slot de Medalha' },
  { id: 'avatarFrame', name: 'Moldura' },
  { id: 'personalizedAvatarChristmas', name: 'Avatar Personalizado de Natal' },
  { id: 'personalizedAvatar', name: 'Avatar Personalizado' },
  { id: 'clothes', name: 'Roupa' },
  { id: 'honor', name: 'Honra' },
  { id: 'highlightMedal', name: 'Medalha em Destaque' },
  { id: 'animatedProfileAvatars', name: 'Avatar Animado' },
  { id: 'dotSay', name: '.Say' },
  { id: 'profileBorder', name: 'Borda de Perfil' },
  { id: 'pin', name: 'Pin' },
  { id: 'combo', name: 'Combo' },
  { id: 'missionPass', name: 'Passe de Missão' },
  { id: 'slotMachineSpin', name: 'Giro de Caça Coins' },
  { id: 'stickersPack', name: 'Pacote de figurinhas' }
]
