import styled from 'styled-components'

export const InputContainer = styled.div`
  input[type='file'] {
    display: none;
  }
`

export const ImageContainer = styled.div``

export const InputFile = styled.input``

export const Image = styled.img`
  height: 200px;
`
