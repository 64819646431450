import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import update from 'immutability-helper'

// SERVICES
import {
  getTags,
  orderTags,
} from '@services/Tag/TagService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { Button, Card, CardContent, FormControl, Grid, Box } from '@mui/material'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'
import { TagDragDropCard } from './components/TagDragDropCard';

// TYPES
import { Tag, TagData } from '@shared/types/TagTypes'

// STYLES
import * as Styled from './TagsOrder.styles'

const TagsOrder = (): JSX.Element => {
  const [tags, setTags] = useState<Tag[]>([])
  const [orderedTags, setOrdered] = useState<Tag[]>([])
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    _getTags()
  }, [])

  const _getTags = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const tagsData: TagData = await getTags()
      setTags(tagsData.tags.filter((tag) => tag.showInMenu || tag.showInStore))
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    _orderTags()
  }

  const _orderTags = async (): Promise<void> => {
    try {
      await orderTags(tags.map((tag) => tag.id))

      Alert.success(
        'Tags ordenadas com sucesso',
        navigate('/apps/marketplace/tag/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao ordenar tags')
    }
  }

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setTags((prevCards: Tag[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Tag],
        ],
      }),
    )
  }, [])

  const renderCard = useCallback(
    (tag: Tag, index: number) => {
      const uses = tag.showInMenu && tag.showInStore ? 'Menu e Loja' : tag.showInMenu ? 'Menu' : 'Loja'
      return (
      <Fragment key={index}>
        <TagDragDropCard 
          id={tag.id}
          index={index}
          name={tag.name}
          uses={uses}
          moveCard={moveCard}
        />
      </Fragment>
      )
    },
    [orderedTags, tags],
  )

  return (
    <Styled.Container>
      <HeaderTitle title="Ordenar Tags">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      {tags?.length > 0
        ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant='outlined' sx={{ width: '100%', p: 1 }}>
                <form onSubmit={handleSubmit}>
                  <CardContent>
                    <Grid item xs={10} sx={{ mb: 4 }}>
                      <FormControl fullWidth>
                      {!tags.length ? (
                        'Carregando tags...'
                        ) : (
                        <>
                          <div>
                          <Grid
                            container
                            spacing={2} sx={{ marginBottom: '20px', marginTop: '2px', paddingLeft: '50px' }}
                          >
                            <Grid item sm={1}></Grid>
                            <Grid item sm={3}>Tag</Grid>
                            <Grid item sm={3}>Usado em</Grid>
                          </Grid>
                            {tags.map((tag, i) => renderCard(tag, i))}
                          </div>
                          </>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 0.2 }}>
                      <Grid item xs sx={{ mt: 5 }}>
                        <Box
                          display="flex"
                          justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                          >
                          <Button variant="contained" type="submit">
                            Salvar
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <NotFound title="Ops... Não encontramos nenhuma tag visível na loja/menu." />
        )
      }
    </Styled.Container>
  )
}

export { TagsOrder }
