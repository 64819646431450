import { SlotMachineConfigProps } from "@shared/types/SlotMachineTypes"

export const slotMachineConfigInitialState: SlotMachineConfigProps = {
  active: true,
  consolationPrizeValue: 0,
  countFreeSpins: 0,
  id: '',
  medalId: null,
  prizes: [],
  slug: '',
  winRate: 0
}
