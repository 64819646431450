import { Menu } from '@mui/icons-material'
import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import { Product } from '@shared/types/ProductTypes'
import type { Identifier, XYCoord } from 'dnd-core'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

export const ItemTypes = {
  CARD: 'card',
}

export interface CardProps {
  id: any
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  availableProducts: Product[]
}

interface DragItem {
  index: number
  id: string
  type: string
}

const CustomAutoComplete = ({ 
  id, 
  index, 
  moveCard, 
  selectedProducts, 
  setSelectedProducts, 
  availableProducts,
  removePrize
 }): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const handleUpdateProduct = (newValue, index): void => {
    setSelectedProducts(prev => {
      const newSelectedProducts = [...prev]
      newSelectedProducts[index] = newValue
      return newSelectedProducts
    });
  }

  return (
      <Grid
        key={index}
        container
        justifyContent='center'
        alignItems='center'
        spacing={2} sx={{ marginBottom: '2px', marginTop: '2px' }}
        ref={ref}
        style={{ opacity }}
        data-handler-id={handlerId}
      >
        <Grid item xs={2}>
          <Menu key={index} />
        </Grid>

        <Grid item xs={8}>
          <Autocomplete
            key={index}
            value={availableProducts.find(product => product.id === selectedProducts[index].id) || {}}
            onChange={(event: any, newValue: Product | null) => {
              handleUpdateProduct(newValue, index)
            }}
            disableClearable
            options={availableProducts}
            getOptionLabel={(option: Product) => `${option.name || 'Escolha seu produto'}`}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} label="Produtos" />}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            color='error'
            variant='outlined'
            sx={{ height: '100%' }}
            onClick={(): void => removePrize(index)}
          >
            Excluir
          </Button>
        </Grid>
      </Grid>
  )
}

export { CustomAutoComplete }
