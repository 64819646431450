import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// SERVICES
import { getProducts } from '@services/Product/ProductService'

// TYPES
import {
  Product,
  GetProductsResponse,
  GetProductQueryParams,
} from '@shared/types/ProductTypes'

// STYLES
import * as Styled from './styles'

// COMPONENTS
import {
  Button,
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  Box,
  LinearProgress,
  Pagination,
} from '@mui/material'
import { DataGrid, GridOverlay } from '@mui/x-data-grid'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { Loader } from '@components/Loader/Loader'
import { PriceValue } from '@components/shared/PriceValue/PriceValue'

const ProductList = (): JSX.Element => {
  const [products, setProducts] = useState<Product[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [pagesCount, setPagesCount] = useState<number>(0)
  const [firstLoading, setFirstLoading] = useState<boolean>(true)
  const [refreshDataLoading, setRefreshDataLoading] = useState<boolean>(false)
  const [paginationPage, setPaginationPage] = useState<number>(1)
  const [searchParams, setSearchParams] = useState({
    searchType: 'name',
    searchText: '',
  })

  useEffect(() => {
    const queryParams = {
      page: 1,
      limit: 10,
    }
    _getProducts(queryParams)
  }, [])

  const _getProducts = async (
    queryParams: GetProductQueryParams
  ): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const productsData: GetProductsResponse = await getProducts(queryParams)
      setProducts(productsData.results)
      setRowCount(productsData.pagination.totalItems)
      setPagesCount(productsData.pagination.totalPages)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const queryParams = {
      page: 1,
      limit: 10,
      ...(searchParams.searchText
        ? { [searchParams.searchType]: searchParams.searchText }
        : {}),
    }
    _getProducts(queryParams)
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <Link
        to={`/apps/marketplace/product/${id}`}
        style={{ textDecoration: 'none' }}
      >
        <Button
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Editar
        </Button>
      </Link>
    )
  }

  const renderImage = (pictures): JSX.Element | null => {
    if (!pictures.length) {
      return null
    }

    return (
      <Styled.ProductImage
        alt="Imagem do Produto"
        src={pictures[0].url}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src =
            'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
        }}
      />
    )
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const CustomPagination = (): JSX.Element => {
    return <Pagination
      page={paginationPage}
      count={pagesCount}
      showFirstButton
      showLastButton
      onChange={(event, page): Promise<void> => {
        setPaginationPage(page)
        return _getProducts({
          limit: 10,
          name: searchParams.searchText,
          page: page,
        })}
      }
    />
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'pictures',
      headerName: 'Imagem',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      minWidth: 100,
      renderCell: (params) => renderImage(params.row.pictures),
    },
    {
      field: 'name',
      headerName: 'Nome',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 220,
    },
    {
      field: 'category',
      headerName: 'Categoria',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.75,
      minWidth: 180,
      renderCell: (params) => (
        <Styled.InlineBlockContainer>
          <Styled.FeaturedText>{params.row.category.name}</Styled.FeaturedText>
        </Styled.InlineBlockContainer>
      ),
    },
    {
      field: 'slug',
      headerName: 'Slug',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      minWidth: 180,
      renderCell: (params) => (
        <Styled.InlineBlockContainer>
          <Styled.FeaturedText>{params.row.slug}</Styled.FeaturedText>
        </Styled.InlineBlockContainer>
      ),
    },
    {
      field: 'prices',
      headerName: 'Valor',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => <PriceValue prices={params.row.prices} />,
    },
    {
      field: 'quantity',
      headerName: 'Estoque',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      minWidth: 120,
      renderCell: (params) => {
        return params.row.quantity ? (
          <span>{params.row.quantity}</span>
        ) : (
          <Styled.FeaturedText
            textColor={'#E15109'}
            backgroundColor={'#FAE6C8'}
          >
            Sem Estoque
          </Styled.FeaturedText>
        )
      },
    },
    {
      field: 'isListed',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      minWidth: 120,
      renderCell: (params) => {
        return params.row.isListed ? (
          <Styled.FeaturedText
            textColor={'#0CA143'}
            backgroundColor={'#DCFADE'}
          >
            Ativo
          </Styled.FeaturedText>
        ) : (
          <Styled.FeaturedText>Arquivado</Styled.FeaturedText>
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Produtos">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      {products?.length > 0 ? (
        <>
          <Styled.FullWidthForm onSubmit={handleSearchSubmit}>
            <Grid container mt={4} columnSpacing={2}>
              <Grid item xs={3}>
                <Select
                  value={searchParams.searchType}
                  label="Tipo"
                  fullWidth
                  size={'small'}
                  onChange={(e) =>
                    setSearchParams({
                      searchType: e.target.value,
                      searchText: '',
                    })
                  }
                >
                  <MenuItem value='name'>Nome</MenuItem>
                  <MenuItem value='slug'>Slug</MenuItem>
                  <MenuItem value='id'>ID</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={7} lg={8}>
                <TextField
                  label="Buscar"
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  onChange={(e) =>
                    setSearchParams({
                      ...searchParams,
                      searchText: e.target.value,
                    })
                  }
                  value={searchParams.searchText}
                />
              </Grid>
              <Grid item xs={2} lg={1}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{ height: '100%' }}
                >
                  <Styled.CustomButton variant="contained" type="submit">
                    Buscar
                  </Styled.CustomButton>
                </Box>
              </Grid>
            </Grid>
          </Styled.FullWidthForm>
          <Card
            sx={{
              mt: 4,
              ['& .MuiDataGrid-iconSeparator']: {
                display: 'none',
              },
            }}
          >
            <DataGrid
              paginationMode="server"
              rowCount={rowCount}
              rows={products}
              columns={columns}
              loading={refreshDataLoading}
              pageSize={10}
              rowsPerPageOptions={[4]}
              autoHeight
              rowHeight={80}
              disableSelectionOnClick
              components={{
                LoadingOverlay: renderCustomLoadingOverlay,
                Pagination: CustomPagination
              }}
              sx={{
                ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                  outline: 'none',
                },
                ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                  whiteSpace: 'break-spaces',
                },
              }}
              onPageChange={(page): Promise<void> =>
                _getProducts({
                  limit: 10,
                  name: searchParams.searchText,
                  page: page + 1,
                })
              }
            />
          </Card>
        </>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { ProductList }
