import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import {
  getProducts,
  createProfileCampaign,
  updateProfileCampaign,
  getProfileCampaign,
} from '@services/ProfileCampaign/ProfileCampaignService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// MATERIAL UI
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Card,
  FormControl,
  TextField,
  Autocomplete,
  Box,
} from '@mui/material'

// COMPONENTS
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

// ICONS
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'

// TYPES
import { Product } from '@shared/types/ProductTypes'

const ProfileProductsForm = (): JSX.Element => {
  const [products, setProducts] = useState<Product[]>([])
  const [target, setTarget] = useState('')
  const [availableProducts, setAvailableProducts] = useState<Product[]>([])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  useEffect(() => {
    getProducts({
      limit: 1999,
    }).then((response) => setAvailableProducts(response.results))
  }, [])

  useEffect(() => {
    if (id) {
      _getCampaigns()
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }

    if (id) {
      _updateCampaign()
    } else {
      _createCampaign()
    }
  }

  const _createCampaign = async (): Promise<void> => {
    try {
      const productsId = products.map((product) => product.id)
      const payload = {
        startDate,
        endDate,
        target,
        products: productsId,
      }

      await createProfileCampaign(payload)

      Alert.success(
        'Vitrine criada com sucesso',
        navigate('/apps/marketplace/profileProducts/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar a criação do Vitrine')
    }
  }

  const _updateCampaign = async (): Promise<void> => {
    try {
      const productsId = products.map((product) => product.id)

      const payload = {
        id,
        startDate,
        endDate,
        target,
        products: productsId,
      }

      await updateProfileCampaign(payload)

      Alert.success(
        'Vitrine alterada com sucesso',
        navigate('/apps/marketplace/profileProducts/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao alterar a vitrine')
    }
  }

  const _getCampaigns = async (): Promise<void> => {
    try {
      const campaignData = await getProfileCampaign(id)

      if (campaignData) {
        setProducts(campaignData.products)
        setTarget(campaignData.target)
        setStartDate(campaignData.startDate)
        setEndDate(campaignData.endDate)
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar a vitrine')
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!products.length) errors.push('O campo Produto é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} vitrine`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const targetOptions = ['FREE', 'PLUS', 'PREMIUM', 'ALL']

  return (
    <Card sx={{ width: 600 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  value={products}
                  onChange={(event: any, newValue: Product[] | null) => {
                    setProducts(newValue)
                  }}
                  options={availableProducts}
                  getOptionLabel={(option: Product) => `${option.name} (${option.slug})`}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Produtos" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  onChange={(event: any, newValue: string) => {
                    setTarget(newValue)
                  }}
                  inputValue={target}
                  options={targetOptions}
                  getOptionLabel={(option) => option || ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Público" />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Inicio da vitrine"
                    inputFormat="dd/LL/yyyy KK:mm aaa"
                    value={startDate}
                    onChange={(startDate) => {
                      setStartDate(startDate)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Fim da vitrine"
                    inputFormat="dd/LL/yyyy KK:mm aaa"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <Grid item xs sx={{ mt: 5 }}>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                >
                  <Button variant="contained" type="submit">
                    {buttonLabelAction} vitrine
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { ProfileProductsForm }
