import {
  useEffect,
  useState,
  useMemo
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import { getCurrencies } from '@services/Currency/CurrencyService'
import { getProducts } from '@services/Product/ProductService'
import { getCategories } from '@services/Category/CategoryService'
import {
  getDiscountTypes,
  createDiscount
} from '@services/DiscountProducts/DiscountProductsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// TYPES
import { Currency } from '@shared/types/CurrencyTypes'
import { Product } from '@shared/types/ProductTypes'
import { Category } from '@shared/types/CategoryTypes'
import {
  ProductDiscountNewPrice,
  CreateDiscountPayload,
  DiscountType,
  Rule
} from '@shared/types/DiscountProductsTypes'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Chip,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material'
import {
  AddCircleOutlineRounded as AddIcon,
  ShoppingCart as ShoppingCartIcon,
  MoneyOff as DiscountIcon,
  List as ListIcon
} from '@mui/icons-material'
import {
  DateTimePicker,
  LocalizationProvider
} from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

const DiscountProductsForm = (): JSX.Element => {
  const [discountTypesList, setDiscountTypesList] = useState<DiscountType[]>([])
  const [currenciesList, setCurrenciesList] = useState<Currency[]>([])
  const [categoriesList, setCategoriesList] = useState<Category[]>([])
  const [productsList, setProductsList] = useState<Product[]>([])
  
  const [medalInput, setMedalInput] = useState<string>('')
  const [discountPrices, setDiscountPrices] = useState<ProductDiscountNewPrice[]>([])
  const [discountTypeId, setDiscountTypeId] = useState<string | null>(null)

  const [selectedQuantity, setSelectedQuantity] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>('n/a')
  const [selectedProduct, setSelectedProduct] = useState<string>('')
  const [selectedRules, setSelectedRules] = useState<Rule[]>([])
  const [selectedDate, setSelectedDate] = useState<Date>(new Date)
  const [selectedMedals, setSelectedMedals] = useState<number[]>([])

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  const {
    DISCOUNT_BY_DATE_AND_QTY,
    DISCOUNT_BY_QTY,
    DISCOUNT_BY_DATE,
    DISCOUNT_FOR_SUBS,
    DISCOUNT_BY_MEDAL
  } = process.env

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    
    _createDiscount(createPayload())
  }

  const createPayload = (): CreateDiscountPayload => {
    let payloadRules: Rule[] = []

    if (discountTypeId === DISCOUNT_BY_DATE_AND_QTY && selectedDate && selectedQuantity) {
      payloadRules = selectedRules.filter(rule =>
        rule.ruleName === 'EXPIRATIONLIMIT' ||
        rule.ruleName === 'LIMITEDPURCHASE'
      )
    }
    if (discountTypeId === DISCOUNT_BY_QTY && selectedQuantity) {
      payloadRules = selectedRules.filter(rule =>
        rule.ruleName === 'LIMITEDPURCHASE'
      )
    }
    if (discountTypeId === DISCOUNT_BY_DATE && selectedDate) {
      payloadRules = selectedRules.filter(rule =>
        rule.ruleName === 'EXPIRATIONLIMIT'
      )
    }
    if (discountTypeId === DISCOUNT_FOR_SUBS) {
      payloadRules.push({
        ruleName: 'SUBSCRIBERSONLY',
        ruleValue: true
      })
    }
    if (discountTypeId === DISCOUNT_BY_MEDAL && selectedMedals) {
      payloadRules = selectedRules.filter(rule =>
        rule.ruleName === 'MEDAL_ONLY'
      )
    }

    const parsedPrices: ProductDiscountNewPrice[] = discountPrices.map(price => {
      return {
        currencyId: price.currencyId,
        newPrice: Number(price.newPrice)
      }
    })

    return {
      active: true,
      productId: selectedProduct,
      rules: payloadRules,
      discountTypeId,
      currencies: parsedPrices
    }
  }

  const _createDiscount = async (payload: CreateDiscountPayload): Promise<void> => {
    try {
      await createDiscount(payload)

      Alert.success(
        'Desconto criado com sucesso',
        navigate('/apps/marketplace/discount/list')
      )
    } catch(err) {
      Alert.error(`Ocorreu um erro ao criar o desconto: ${err.errors.toString()}`)
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!discountPrices.length) errors.push('Selecione ao menos um valor para desconto')
    if (discountTypeId == DISCOUNT_BY_MEDAL && selectedMedals.length < 1) errors.push('Selecione ao menos uma medalha')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Desconto de Produto`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  const handleSelectedProduct = (productSlug: string): void => {
    const newProduct = productsList.find(product => product.id === productSlug)
    setSelectedProduct(newProduct.id)
  }

  const changePrice = (value: string, id: number): void => {
    setDiscountPrices(prev => {
      const newPrices = [...prev]
      const hasCurrency = prev.find(price => price.currencyId === id)
      const payload: ProductDiscountNewPrice = {
        currencyId: id,
        newPrice: value
      }

      if (hasCurrency) {
        const currencyIndex = newPrices.indexOf(hasCurrency)
        if (value === '') {
          newPrices.splice(currencyIndex, 1)
        } else {
          newPrices[currencyIndex] = payload
        }
      } else {
        newPrices.push(payload)
      }

      return newPrices
    })
  }

  const dateSelector = useMemo((): JSX.Element => (
    <Grid item xs={6}>
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            clearable
            label="Data de expiração"
            renderInput={(props) => (
              <TextField required {...props} />
            )}
            value={selectedDate}
            inputFormat="dd/MM/yyyy HH:mm"
            onChange={(newDate) => setSelectedDate(newDate)}
          />
        </LocalizationProvider>
      </FormControl>
    </Grid>
  ), [selectedDate])

  const quantitySelector = useMemo((): JSX.Element => (
    <Grid item xs={6}>
      <TextField
        label="Quantidade"
        variant="outlined"
        key="quantity"
        fullWidth
        required
        onChange={(e) => setSelectedQuantity(e.target.value)}
        value={selectedQuantity}
      />
    </Grid>
  ), [selectedQuantity])

  const medalSelector = useMemo((): JSX.Element => (
    <>
      <Grid item xs={3}>
        <TextField
          label="ID da medalha"
          variant="outlined"
          key="medal"
          type='number'
          fullWidth
          onChange={(e) => setMedalInput(e.target.value)}
          value={medalInput}
        />
      </Grid>
      <Grid item xs={4} justifyContent='center' alignContent='center' alignItems='center'>
        <Button
          variant="outlined"
          onClick={(): void => {
            if (!medalInput) return
            setSelectedMedals(prev => {
              const hasMedal = selectedMedals.some(medal => medal === Number(medalInput))
              if (!hasMedal) return [...prev, Number(medalInput)]
            })
            setMedalInput('')
          }}
        >
          Adicionar medalha
        </Button>
      </Grid>
      <Grid item xs={5}>
        {selectedMedals.map(medal => (
          <Chip
            key={medal}
            label={medal}
            sx={{ mr: 1, mb: 1 }}
            onDelete={() => setSelectedMedals(prev => {
              return prev.filter(selectedMedal => selectedMedal !== medal)
            })}
          />
        ))}
      </Grid>
    </>
  ), [medalInput, selectedMedals])

  useEffect(() => {
    setSelectedRules(prev => {
      const newRules = [...prev]
      const hasDate = newRules.find(rule => rule.ruleName === 'EXPIRATIONLIMIT')
      const payload: Rule = {
        ruleName: 'EXPIRATIONLIMIT',
        ruleValue: selectedDate
      }

      if (hasDate) {
        const ruleIndex = newRules.indexOf(hasDate)
        if (!selectedDate) {
          newRules.splice(ruleIndex, 1)
        } else {
          newRules[ruleIndex] = payload
        }
      } else {
        newRules.push(payload)
      }

      return newRules
    })
  }, [selectedDate])

  useEffect(() => {
      setSelectedRules(prev => {
        const newRules = [...prev]
        const hasQuantity = newRules.find(rule => rule.ruleName === 'LIMITEDPURCHASE')
        const payload: Rule = {
          ruleName: 'LIMITEDPURCHASE',
          ruleValue: Number(selectedQuantity)
        }

        if (hasQuantity) {
          const ruleIndex = newRules.indexOf(hasQuantity)
          if (!selectedQuantity) {
            newRules.splice(ruleIndex, 1)
          } else {
            newRules[ruleIndex] = payload
          }
        } else {
          newRules.push(payload)
        }

        return newRules
      })
  }, [selectedQuantity])

  useEffect(() => {
    setSelectedRules(prev => {
      const newRules = [...prev]
      const hasMedal = newRules.find(rule => rule.ruleName === 'MEDAL_ONLY')
      const payload: Rule = {
        ruleName: 'MEDAL_ONLY',
        ruleValue: `[${selectedMedals}]`
      }

      if (hasMedal) {
        const ruleIndex = newRules.indexOf(hasMedal)
        if (!selectedMedals) {
          newRules.splice(ruleIndex, 1)
        } else {
          newRules[ruleIndex] = payload
        }
      } else {
        newRules.push(payload)
      }

      return newRules
    })
}, [selectedMedals])

  useEffect(() => {
    getCurrencies().then(response => setCurrenciesList(response.currencies))
    getDiscountTypes().then(response => setDiscountTypesList(response))
    getCategories().then(response => setCategoriesList(
      response.productCategories.sort((a, b) => a.name > b.name ? 1 : -1))
    )
    getProducts({
      limit: 1999,
    }).then(response => setProductsList(response.results))
  }, [])

  return (
    <Card sx={{ width: 800 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>

            {/* PRODUCT */}
            <Card variant='outlined' sx={{ width: '100%', marginBottom: 1 }}>
              <HeaderTitle title='Selecionar Produto'>
                <ShoppingCartIcon fontSize="medium" />
              </HeaderTitle>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Categoria</InputLabel>
                      {categoriesList &&
                        <Select
                          label='Categoria'
                          value={selectedCategory}
                          onChange={(e): void => setSelectedCategory(String(e.target.value))}
                          MenuProps={{
                            sx: {
                              maxHeight: '500px'
                            }
                          }}
                        >
                          <MenuItem value={'n/a'}>N/A</MenuItem>
                          {categoriesList.map(category => (
                            <MenuItem
                              key={category.name}
                              value={category.name}
                            >
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Produto selecionado</InputLabel>
                      {productsList &&
                        <Select
                          required
                          label='Produto selecionado'
                          value={selectedProduct}
                          onChange={(e): void => handleSelectedProduct(String(e.target.value))}
                          MenuProps={{
                            sx: {
                              maxHeight: '500px'
                            }
                          }}
                        >
                          <MenuItem value={null}>N/A</MenuItem>
                          {productsList
                          .filter(product => product.category.name === selectedCategory)
                          .map(product => (
                            <MenuItem
                              key={product.id}
                              value={product.id}
                            >
                              {product.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* DISCOUNT TYPE */}
            <Card variant='outlined' sx={{ width: '100%', marginBottom: 1 }}>
              <HeaderTitle title='Tipo de desconto'>
                <ListIcon fontSize="medium" />
              </HeaderTitle>
              <CardContent>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Tipo de desconto</InputLabel>
                    {productsList &&
                      <Select
                        required
                        label='Tipo de desconto'
                        value={discountTypeId}
                        onChange={(e): void => setDiscountTypeId(e.target.value as string)}
                        MenuProps={{
                          sx: {
                            maxHeight: '500px'
                          }
                        }}
                      >
                        <MenuItem value={null}>N/A</MenuItem>
                        {discountTypesList
                        .map(type => (
                          <MenuItem
                            key={type.id}
                            value={type.id}
                          >
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  </FormControl>
                  {discountTypeId && <>
                    <Typography
                      align='center'
                      gutterBottom
                    >
                      {discountTypesList.find(type => type.id === discountTypeId).description}
                    </Typography>
                    <Grid container spacing={2}>
                      {(discountTypeId === DISCOUNT_BY_DATE_AND_QTY || discountTypeId === DISCOUNT_BY_DATE) && dateSelector}
                      {(discountTypeId === DISCOUNT_BY_DATE_AND_QTY || discountTypeId === DISCOUNT_BY_QTY) && quantitySelector}
                      {discountTypeId === DISCOUNT_BY_MEDAL && medalSelector}
                    </Grid>
                  </>}
                </Grid>
              </CardContent>
            </Card>

            {/* DISCOUNT VALUES */}
            <Card variant='outlined' sx={{ width: '100%' }}>
              <HeaderTitle title='Valor com desconto'>
                <DiscountIcon fontSize="medium" />
              </HeaderTitle>
              <CardContent>
                <Grid container spacing={2}>
                  {currenciesList?.map(currency => (
                    <Grid
                      key={currency.id}
                      item xs={4}
                    >
                      <TextField
                        label={currency.name}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => changePrice(e.target.value, currency.id)}
                        value={discountPrices.find(price => price.currencyId === currency.id)?.newPrice || ''}
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* FOOTER */}
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs sx={{ mt: 5 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  {buttonLabelAction} Desconto
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { DiscountProductsForm }
