import { useEffect, useState, createRef, RefObject } from 'react'
import { CSVLink } from "react-csv";

// SERVICES
import {
  getGiftCardsCampaign, getGiftCardsReport,
} from '@services/Voucher/VoucherService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// TYPES
import { GetGiftCardsReportResponse } from '@shared/types/VoucherTypes'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel
} from '@mui/material'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'

const GiftCardReport = (): JSX.Element => {
  const [campaignId, setCampaignId] = useState<string>('')
  const [giftCardUsed, setGiftCardUsed] = useState<string>('all')
  const [campaignItems, setCampaignItems] = useState([])
  const [giftCardsData, setGiftCardsData] = useState([])
  const [reportLoading, setReportLoading] = useState(false)
  const csvLinkRef: RefObject<CSVLink> = createRef()

  const statusItems = [{
    title: 'Todos',
    value: 'all'
  },
  {
    title: 'Utilizado',
    value: 'true'
  }, 
  {
    title: 'Disponível',
    value: 'false'
  }]

  const headers = [
    { label: "Campanha", key: "campaign" },
    { label: "Período", key: "period" },
    { label: "Produto", key: "product" },
    { label: "Parceiro", key: "partner" },
    { label: "Código", key: "code" },
    { label: "Expiração", key: "expiration" },
    { label: "Status", key: "status" },
  ];

  useEffect(() => {
    getGiftCardsCampaign().then((response) => setCampaignItems(response.campaigns))
  }, [])

  useEffect(() => {
    if (giftCardsData.length) {
      csvLinkRef.current.link.click()
      setGiftCardsData([])
    }
  }, [giftCardsData])

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault()
    const errors = validateFields()
  
    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }

    try {
      setReportLoading(true)
      const queryStringParams = {
        used: giftCardUsed,
        campaign: campaignId
      }
  
      const reportResponse = await getGiftCardsReport(queryStringParams)

      if (!reportResponse.length) {
        Alert.error('Não foram encontrados resultados para esses filtros')
        setGiftCardsData([])
        return
      }
      
      setGiftCardsData(formatGiftCardReportResponse(reportResponse))
    } catch (e){
      if (e.statusCode === 403) {
        Alert.error('Você não tem permissão para gerar este relatório')
      }
    } finally {
      setReportLoading(false)
    }
  }

  const formatGiftCardReportResponse = (reportResponse: GetGiftCardsReportResponse[]): object[] => {
    return reportResponse.map((item) => {
      const date = new Date(item.campaign.initialDate)
      const month = date.toLocaleString('pt-BR', {month: 'long'})
      const year = date.getFullYear()

      const csvLine = {
        campaign: item.campaign.title,
        period: `${month}/${year}`,
        product: item.product.name,
        partner: item.campaign.partner,
        code: item.voucher,
        expiration: item.expiresIn,
        status: item.used ? 'Utilizado' : 'Disponível'
      }

      return csvLine
    })
  }

  const renderCampaignOptions = (items: any): JSX.Element => {
    return (
      items &&
      items.map((obj) => (
        <MenuItem key={obj.id} value={obj.id}>
          {obj.title}
        </MenuItem>
      ))
    )
  }

  const renderStatusOptions = (items: any): JSX.Element => {
    return (
      items &&
      items.map((obj) => (
        <MenuItem key={`status${obj.value}`} value={obj.value}>
          {obj.title}
        </MenuItem>
      ))
    )
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!campaignId) errors.push('O campo Campanha é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title="Relatório de Gift Cards">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  return (
    <Box>
      <Card sx={{ width: 600 }}>
        <CardHeader title={renderCardHeader()} />
        <CardContent sx={{ p: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="campaign-label">Campanha</InputLabel>
                  <Select
                    required
                    key="campaignId"
                    value={campaignId}
                    onChange={(e) => setCampaignId(e.target.value)}
                    input={<OutlinedInput label="Campanha" />}
                  >
                    {renderCampaignOptions(campaignItems)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    required
                    key="status"
                    value={giftCardUsed}
                    onChange={(e) => setGiftCardUsed(e.target.value)}
                    input={<OutlinedInput label="Status" />}
                  >
                    {renderStatusOptions(statusItems)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0.2 }}>
              <Grid item xs>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'center' }}
                >
                  <Button variant="contained" type="button" onClick={(e):Promise<void> => handleSubmit(e)} disabled={reportLoading}>
                    {reportLoading ? 'Gerando relatório...' : 'Gerar relatório'}
                  </Button>
                  <CSVLink
                    headers={headers}
                    data={giftCardsData}
                    filename="gift-cards.csv"
                    className="hidden"
                    ref={csvLinkRef}
                  />
                </Box>
              </Grid>
            </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export { GiftCardReport }
