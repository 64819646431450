import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// SERVICES
import { getProducts } from '@services/Product/ProductService'

// TYPES
import { Product, GetProductsResponse } from '@shared/types/ProductTypes'

// STYLES
import * as Styled from './styles'

// COMPONENTS
import {
  Button,
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  Box,
  LinearProgress,
} from '@mui/material'
import { DataGrid, GridOverlay } from '@mui/x-data-grid'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { Loader } from '@components/Loader/Loader'
import { PriceValue } from '@components/shared/PriceValue/PriceValue'

const PlansList = (): JSX.Element => {
  const [products, setProducts] = useState<Product[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [searchParams, setSearchParams] = useState({
    searchType: 'name',
    searchText: '',
  })

  const [firstLoading, setFirstLoading] = useState(true)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  useEffect(() => {
    const queryParams = {
      page: 1,
      limit: 10,
    }
    _getProducts(queryParams)
  }, [])

  const _getProducts = async (queryParams): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const categoryNames = ['subscription-v1', 'subscription-mg']
      const productsData: GetProductsResponse = await getProducts(
        queryParams,
        categoryNames
      )
      setProducts(productsData.results)
      setRowCount(productsData.pagination.totalItems)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const queryParams = {
      page: 1,
      limit: 10,
      ...(searchParams.searchText
        ? { [searchParams.searchType]: searchParams.searchText }
        : {}),
    }
    _getProducts(queryParams)
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <Link
        to={`/apps/marketplace/plans/${id}`}
        style={{ textDecoration: 'none' }}
      >
        <Button color="primary" variant="contained" sx={{ mr: 1 }}>
          Editar
        </Button>
      </Link>
    )
  }

  const renderImage = (pictures): JSX.Element | null => {
    if (!pictures.length) {
      return null
    }

    return (
      <Styled.ProductImage
        alt="Imagem do Plano"
        src={pictures[0].url}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src =
            'https://assets.gamersclub.com.br/header/img/gamers_club_logo_small.8a9ff3c5.svg'
        }}
      />
    )
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'pictures',
      headerName: 'Imagem',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      minWidth: 100,
      renderCell: (params) => renderImage(params.row.pictures),
    },
    {
      field: 'name',
      headerName: 'Nome',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 220,
    },
    {
      field: 'category',
      headerName: 'Categoria',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.75,
      minWidth: 180,
      renderCell: (params) => (
        <Styled.InlineBlockContainer>
          <Styled.FeaturedText
            backgroundColor={
              params.row.category.name === 'subscription-v1'
                ? '#08B5C0'
                : '#7822FF'
            }
            textColor={'#fff'}
          >
            {params.row.category.name}
          </Styled.FeaturedText>
        </Styled.InlineBlockContainer>
      ),
    },
    {
      field: 'slug',
      headerName: 'Slug',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      minWidth: 180,
      renderCell: (params) => (
        <Styled.InlineBlockContainer>
          {params.row.slug}
        </Styled.InlineBlockContainer>
      ),
    },
    {
      field: 'prices',
      headerName: 'Valor',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Styled.TextBold>
            <PriceValue prices={params.row.prices} />
          </Styled.TextBold>
        )
      },
    },
    {
      field: 'isListed',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      minWidth: 120,
      renderCell: (params) => {
        return params.row.isListed ? (
          <Styled.FeaturedText
            textColor={'#0CA143'}
            backgroundColor={'#DCFADE'}
          >
            Ativo
          </Styled.FeaturedText>
        ) : (
          <Styled.FeaturedText>Arquivado</Styled.FeaturedText>
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Planos">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      <Styled.FullWidthForm onSubmit={handleSearchSubmit}>
        <Grid container mt={4} columnSpacing={2}>
          <Grid item xs={3}>
            <Select
              value={searchParams.searchType}
              label="Tipo"
              fullWidth
              size={'small'}
              onChange={(e) =>
                setSearchParams({
                  searchType: e.target.value,
                  searchText: '',
                })
              }
            >
              <MenuItem value={'name'}>Nome</MenuItem>
              <MenuItem value={'id'}>ID</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={7} lg={8}>
            <TextField
              label="Buscar"
              variant="outlined"
              fullWidth
              size={'small'}
              onChange={(e) =>
                setSearchParams({
                  ...searchParams,
                  searchText: e.target.value,
                })
              }
              value={searchParams.searchText}
            />
          </Grid>
          <Grid item xs={2} lg={1}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <Styled.CustomButton variant="contained" type="submit">
                Buscar
              </Styled.CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Styled.FullWidthForm>
      {products?.length > 0 ? (
        <Card
          sx={{
            mt: 4,
            ['& .MuiDataGrid-iconSeparator']: {
              display: 'none',
            },
          }}
        >
          <DataGrid
            paginationMode="server"
            rowCount={rowCount}
            rows={products}
            columns={columns}
            loading={refreshDataLoading}
            pageSize={10}
            rowsPerPageOptions={[4]}
            autoHeight
            rowHeight={80}
            disableSelectionOnClick
            components={{
              LoadingOverlay: renderCustomLoadingOverlay,
            }}
            sx={{
              ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                outline: 'none',
              },
              ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                whiteSpace: 'break-spaces',
              },
            }}
            onPageChange={(page): Promise<void> =>
              _getProducts({
                page: page + 1,
                limit: 10,
              })
            }
          />
        </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { PlansList }
