import {
  useEffect,
  useState
} from 'react'

// COMPONENTS
import {
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize
} from '@mui/material'
import { CheckboxComponent } from "./components/Checkbox/Checkbox"
import { DropdownComponent } from './components/Dropdown/Dropdown'
import { RulesComponent } from './components/Rules/Rules'
import { TextFieldComponent } from './components/TextField/TextField'

// TYPES
import { MetadataProps as Props } from './types'
import { getVouchersCampaigns } from '@services/Voucher/VoucherService'

const Metadata = (props: Props): JSX.Element => {
  const { productType, metadata, updateProductState } = props
  const [campaignItems, setCampaignItems] = useState([])

  const [allRules, setAllRules] = useState<string[]>([])
  const [activation, setActivation] = useState({
    unit: '',
    value: '',
  })

  const updateActivationTime = (key: string, value: any): void => {
    setActivation(prev => {
      return { 
        ...prev, 
        [key]: value 
      }
    })

    updateProductState('metadata', {
      ...metadata,
      activationTime: activation,
    })
  }

  const updateMetadata = (metadataKey: string, value: any): void => {
    const newMetadata = { ...metadata }
    if (metadataKey === 'multiplyFactor') {
      newMetadata[metadataKey] = Number(value)
    } else {
      newMetadata[metadataKey] = value
    }

    updateProductState('metadata', newMetadata)
  }

  useEffect(() => {
    metadata.rules && setAllRules(metadata.rules)
  }, [])

  useEffect(() => {
    getVouchersCampaigns().then((response) => setCampaignItems(response.campaigns))
  }, [])

  useEffect(() => {
    if (allRules.length > 0) {
      updateMetadata('rules', allRules)
    } else {
      const newMetadata = { ...metadata }
      delete newMetadata.rules
      updateProductState('metadata', newMetadata)
    }
  }, [allRules])

  // RENDER FUNCTIONS
  const renderCheckbox = (
    slug: string,
    label: string,
    handler: (slug: string, value: boolean) => void = updateMetadata
  ): JSX.Element => (
    <CheckboxComponent
      keyName={slug}
      label={label}
      onChange={() => handler(slug, !metadata[slug])}
      checked={metadata[slug]}
    />
  )

  const renderDropdown = (
    slug: string,
    label: string,
    handler: (slug: string, value: any) => void = updateMetadata
  ): JSX.Element => (
    <DropdownComponent
      keyName={slug}
      label={label}
      value={metadata[slug] || ''}
      updateMetadata={handler}
    />
  )

  const renderTextField = (
    slug: string,
    label: string,
    handler: (slug: string, value: any) => void = updateMetadata
  ): JSX.Element => (
      <TextFieldComponent
        keyName={slug}
        label={label}
        onChange={(e) => handler(slug, e.target.value)}
        value={metadata[slug] || ''}
      />
    )

  const renderNumberField = (
    slug: string,
    label: string,
    handler: (slug: string, value: any) => void = updateMetadata
  ): JSX.Element => (
      <TextFieldComponent
        keyName={slug}
        label={label}
        type='number'
      onChange={(e) => handler(slug, parseInt(e.target.value))}
      value={parseInt(metadata[slug]) || 0}
      />
    )

  const renderTextarea = (
    slug: string,
    label: string,
    placeholder: string,
    handler: (slug: string, value: any) => void = updateMetadata,
    minRows: number = 5,
    width: number = 350,
  ): JSX.Element => (
    <>
      <InputLabel>{label}</InputLabel>
      <TextareaAutosize
        key={label}
        minRows={minRows}
        placeholder={placeholder}
        style={{ width }}
        onChange={(e) => handler(slug, e.target.value)}
        value={metadata[slug] || ''}
      />
    </>
  )

  class Selectable {
    id: string | number
    name?: string
    title?: string
    label?: string
  }

  const renderSelect = (
    slug: string,
    label: string,
    items: Selectable[],
    handler: (slug: string, value: any) => void = updateMetadata,
    width = '100%',
  ): JSX.Element => (
    <>
      <InputLabel>{label}</InputLabel>
      <Select
        required
        key={label}
        value={metadata[slug] || ''}
        onChange={(e) => handler(slug, e.target.value)}
        input={<OutlinedInput label={label} />}
        style={{ width }}
      >
        {(items &&
          items.map((obj) => (
            <MenuItem key={obj.id} value={obj.id}>
              {obj.title || obj.name || obj.label}
            </MenuItem>
          ))
        )}
      </Select>
    </>
  )

  // DROPDOWN
  const activationType = renderDropdown('activationType', 'Activation Type')

  // CHECKBOX
  const isStaff = renderCheckbox('isStaff', 'Apenas staff')
  const freeForSubs = renderCheckbox('freeForSubs', 'Grátis para Assinantes')
  const automaticActivation = renderCheckbox('automaticActivation', 'Ativação automática', (slug: string, value: boolean) => {
    if(value) {
      updateMetadata('generateVoucherOnDemand', false)
    }
    updateMetadata(slug, value)
  })
  const customSlot = renderCheckbox('customSlot', 'Slot Custom')
  const hideFromSubs = renderCheckbox('hideFromSubs', 'Esconder dos assinantes')
  const bestPrice = renderCheckbox('bestPrice', 'Melhor preço')
  const isFeatured = renderCheckbox('isFeatured', 'Melhor Escolha')
  const mostPopular = renderCheckbox('mostPopular', 'Mais popular')
  const encryptVouchers = renderCheckbox('encryptVouchers', 'Criptografar vouchers no banco de dados')
  const generateVoucherOnDemand = metadata.activationType === 'VOUCHER' && (metadata?.productType != 'medal' || !metadata?.automaticActivation) && renderCheckbox('generateVoucherOnDemand', 'Gerar voucher sob demanda')

  // TEXTFIELD
  const actionIcon = renderTextField('actionIcon', 'Action Icon URL')
  const sidebarBorder = renderTextField('sidebarBorder', 'Sidebar Border')
  const coverBorder = renderTextField('coverBorder', 'Cover Border')
  const actionLabel = renderTextField('actionLabel', 'Action Label (texto do botão)')
  const missionMedalId = renderTextField('missionMedalId', 'ID da medalha')
  const actionLink = renderTextField('actionLink', 'Action Link (link do botão)')
  const textFieldLabel = renderTextField('textFieldLabel', 'Text Field')
  const hardCoinsAmount = renderNumberField('hardCoinsAmount', 'Valor em gold')
  const softCoinsAmount = renderNumberField('softCoinsAmount', 'Valor em silver')
  const minValue = renderTextField('minValue', 'Valor mínimo')
  const maxValue = renderTextField('maxValue', 'Valor máximo')
  const redirectLabel = renderTextField('redirectLabel', 'Rediret Label')
  const referenceModal = renderTextField('referenceModal', 'Reference Modal')
  const redirectLink = renderTextField('redirectLink', 'Redirect Link')
  const redirectUrl = renderTextField('redirectUrl', 'Redirect Url')
  const ctaText = renderTextField('ctaText', 'Cta Text')
  const ctaTitle = renderTextField('ctaTitle', 'Cta Title')
  const ctaTrigger = renderTextField('ctaTrigger', 'Cta Trigger')
  const activationValue = renderTextField('activationValue', 'Activation Value')
  const titleRules = renderTextField('titleRules', 'Title Rules')
  const postbackUrl = renderTextField('postbackUrl', 'Postback URL')
  const expirationPostBackUrl = renderTextField('expirationPostBackUrl', 'Expiration Postback URL')
  const shareModalText = renderTextField('shareModalText', 'Share Modal Text')
  const monthlySavings = renderTextField('monthlySavings', 'Monthly Savings')
  const monthlyValue = renderTextField('monthlyValue', 'Monthly Value')
  const bonusTime = renderTextField('bonusTime', 'Bonus Time')
  const medalId = renderTextField('medalId', 'Medal ID')
  const passId = renderTextField('passId', 'Pass ID')
  const name = renderTextField('name', 'Nome do Item')
  const animation = renderTextField('animation', 'Animação do cofre')
  const pinAnimation = renderTextField('pinAnimation', 'Animação do PIN')
  const albumSlug = renderTextField('albumSlug', 'Slug do Álbum')
  const stickerQuantity = renderTextField('stickerQuantity', 'Quantidade de figurinhas')
  const voucherPrefix = generateVoucherOnDemand && metadata?.generateVoucherOnDemand ? renderTextField('voucherPrefix', 'Préfixo do voucher') : <></>

  // NUMBERFIELD
  const drpMultiplier = renderNumberField('multiplyFactor', 'Multiplicador')
  const voucherMedalId = generateVoucherOnDemand && metadata?.generateVoucherOnDemand ? renderNumberField('medalId', 'ID da Medalha') : <></>
  const automaticActivationMedalId = metadata?.productType === 'medal' && metadata?.automaticActivation ? renderNumberField('medalId', 'ID da Medalha') : <></>
  
  // TEXTAREA
  const modalDescription = renderTextarea('modalDescription', 'Modal Description', 'Descrição do item')
  const introDescription = renderTextarea('introDescription', 'Intro Description', 'Descrição do FreePass')
  const modalText = renderTextarea('modalText', 'Modal Text', 'Descrição da Modal')

  // SELECT
  const campaign = generateVoucherOnDemand && metadata?.generateVoucherOnDemand ? renderSelect('campaignId', 'Campanha (*)', campaignItems) : <></>

  // MISC
  const rules = <RulesComponent allRules={allRules} setAllRules={setAllRules} />

  const activationTime = (
    <>
      <TextFieldComponent
        keyName="activationTimeUnit"
        label="ActivationTime Unit"
        value={activation.unit}
        onChange={(e) => updateActivationTime('unit', e.target.value)}
      />

      <TextFieldComponent
        keyName="activationTimeValue"
        label="ActivationTime Value"
        value={activation.value}
        onChange={(e) => updateActivationTime('value', e.target.value)}
      />
    </>
  )

  const productComponents = {
    goldCustom: [
      actionIcon,
      actionLabel,
      actionLink,
      hardCoinsAmount,
      softCoinsAmount,
      maxValue, 
      minValue,
      bestPrice,
      mostPopular
    ],
    goldPackage: [
      actionIcon,
      actionLabel,
      actionLink,
      hardCoinsAmount,
      softCoinsAmount,
      bestPrice,
      mostPopular
    ],
    medal: [
      actionLabel,
      referenceModal,
      activationType,
      redirectLabel,
      redirectLink,
      modalDescription,
      textFieldLabel,
      redirectUrl,
    ],
    missionPass: [
      missionMedalId,
      actionLabel,
      referenceModal,
      activationType,
      redirectLabel,
      redirectLink,
      modalDescription,
      textFieldLabel,
      redirectUrl,
    ],
    sorteio: [
      actionLabel,
      referenceModal,
      activationType,
      redirectLabel,
      redirectLink,
      modalDescription,
      textFieldLabel,
      encryptVouchers,
    ],
    freePassLobby: [
      actionLabel,
      actionLink,
      activationTime,
      activationType,
      ctaText,
      ctaTitle,
      ctaTrigger,
      introDescription,
    ],
    ratingPointsItems: [
      rules,
      titleRules,
      actionLabel,
      actionLink,
      activationType,
      activationValue,
      drpMultiplier,
      postbackUrl,
      referenceModal,
    ],
    subscription: [
      activationType,
      activationTime,
      monthlySavings,
      monthlyValue,
      bonusTime,
      medalId,
    ],
    vault: [
      animation,
      actionLabel,
      actionLink,
      activationType
    ],
    vaultPassword: [
      activationType,
      actionLabel,
      actionLink
    ],
    nickChanger: [
      actionLabel,
      actionLink,
      activationType
    ],
    subscriptionMg: [
      activationType,
      activationTime,
      passId,
      monthlyValue
    ],
    olheiros: [
      passId
    ],
    customUrl: [
      actionLabel,
      actionLink,
      activationTime,
      activationType,
      modalText,
      titleRules,
      rules,
      shareModalText,
      name,
      expirationPostBackUrl,
    ],
    medalSlot: [
      actionLink,
      actionLabel,
      activationType,
      customSlot
    ],
    avatarFrame: [
      activationType,
      actionLabel,
      actionLink
    ],
    personalizedAvatarChristmas: [
      activationType,
      referenceModal,
      actionLabel
    ],
    personalizedAvatar: [
      activationType,
      referenceModal,
      actionLabel
    ],
    honor: [
      actionLabel,
      actionLink,
      activationType
    ],
    highlightMedal: [
      actionLink,
      actionLabel
    ],
    animatedProfileAvatars: [
      activationType,
      actionLabel,
      actionLink,
      activationValue,
    ],
    dotSay: [
      actionLabel,
      actionLink,
      activationType,
      activationValue
    ],
    clothes: [],
    profileBorder: [
      sidebarBorder,
      coverBorder,
      activationType,
      actionLabel,
      actionLink
    ],
    pin: [
      actionLabel,
      referenceModal,
      activationType,
      redirectLabel,
      redirectLink,
      modalDescription,
      pinAnimation,
    ],
    combo: [
      actionLabel,
      actionLink
    ],
    slotMachineSpin: [
      activationType
    ],
    stickersPack: [
      albumSlug,
      actionLabel,
      actionLink,
      stickerQuantity,
      activationType
    ]
  }

  const productComponentsGenerics = {
    all: [
      isFeatured,
      isStaff,
      freeForSubs,
      hideFromSubs,
      automaticActivation,
      automaticActivationMedalId,
    ],
  }

  const activationTypeComponents = {
    VOUCHER: [
      generateVoucherOnDemand,
      campaign,
      voucherMedalId,
      voucherPrefix,
    ]
  }

  const renderComponent = (productType, activationType?) => {
    return Object.assign([
      productComponents[productType]?.map((component) => component),
      productComponentsGenerics.all?.map((component) => component),
      activationTypeComponents[activationType]?.map((component) => component),
    ])
  }

  return renderComponent(productType, metadata?.activationType)
}

export { Metadata }
