import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// SERVICES
import {
  getDiscountCouponsById,
  createDiscountCoupons,
  updateDiscountCoupons,
  getCSPlansProducts,
  // getAccountGroups
} from '@services/DiscountCoupons/DiscountCouponsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'
import dayjs, { Dayjs } from 'dayjs'

// COMPONENTS
import {
  CardHeader,
  CardContent,
  Grid,
  Button,
  Box,
  Card,
  TextField,
  FormControl,
  Typography,
  Switch,
  FormControlLabel,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material'
import { AddCircleOutlineRounded as AddIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { NoContextCustomDatePicker } from '@components/shared/CustomDatePicker/CustomDatePicker'

//TYPES
import { DiscountIntendedForExpiringSubscription } from '@shared/types/DiscountCouponsTypes'
import { Product } from '@shared/types/ProductTypes'

// import { AccountGroup } from '@shared/types/DiscountCouponsTypes'
// TODO: Integrar o account group descomentando essas linhas quando a task ST-5040 começar a ser feita.
const DiscountCouponsForm = (): JSX.Element => {
  const [code, setCode] = useState<string>('')
  const [startDate, setStartDate] = useState<Dayjs>(null)
  const [endDate, setEndDate] = useState<Dayjs>(null)
  const [percentage, setPercentage] = useState<number>()
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isVerifiedPlayersOnly, setIsVerifiedPlayersOnly] =
    useState<boolean>(false)
  const [isAllowedOncePerMonth, setIsAllowedOncePerMonth] =
    useState<boolean>(false)
  const [targetAudience, setTargetAudience] = useState<string | null>('')
  //const [tagGroupAccounts, setTagGroupAccounts] = useState<AccountGroup[]>([]);
  const [accountGroup, setAccountGroup] = useState<string | null>(null)
  const [maxUsage, setMaxUsage] = useState<string | null>('')
  const [
    isIntendedForExpiringSubscriptions,
    setIsIntendedForExpiringSubscriptions,
  ] = useState<boolean>(false)
  const [
    plansWithDiscountForExpiringSubscription,
    setPlansWithDiscountForExpiringSubscription,
  ] = useState<DiscountIntendedForExpiringSubscription[]>([
    {
      productId: null,
      minDaysBeforeSubscriptionExpire: null,
      maxDaysBeforeSubscriptionExpire: null,
      id: '',
    },
  ])
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    Product[] | undefined
  >(undefined)

  const navigate = useNavigate()
  const { id } = useParams()
  const formTitleAction = id ? 'Alterar' : 'Cadastrar'
  const buttonLabelAction = id ? 'Salvar' : 'Cadastrar'

  const targetOptions = ['FREE', 'PLUS', 'PREMIUM', 'ALL']

  useEffect(() => {
    if (id) {
      _getDiscountCoupons()
    }

    _getCSPlansProducts()
  }, [])

  // useEffect(() => {
  //   if(targetAudience === 'CUSTOM') {
  //     getAccountGroups({
  //       limit: 1999,
  //     }).then((response) => setTagGroupAccounts(response.results))
  //   }
  // }, [targetAudience])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const errors = validateFields()

    if (errors.length) {
      const errorMessages = errors.join('\r\n')
      Alert.error(errorMessages)
      return
    }
    if (id) {
      _updateDiscountCoupons()
    } else {
      _createDiscountCoupons()
    }
  }

  const _createDiscountCoupons = async (): Promise<void> => {
    try {
      const payload = {
        code,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        percentage,
        isVerifiedPlayersOnly,
        isAllowedOncePerMonth,
        targetAudience: targetAudience === '' ? null : targetAudience,
        tagGroupAccounts: accountGroup,
        maxUsage: Number(maxUsage),
        targetProducts: plansWithDiscountForExpiringSubscription
      }

      await createDiscountCoupons(payload)

      Alert.success(
        'Cupom de desconto criado com sucesso',
        navigate('/apps/marketplace/discountCoupons/list')
      )
    } catch (err) {
      Alert.error(`${err.errors}`)
    }
  }

  const _updateDiscountCoupons = async (): Promise<void> => {
    try {
      const payload = {
        id,
        code,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        percentage,
        isActive,
        isVerifiedPlayersOnly,
        isAllowedOncePerMonth,
        targetAudience: targetAudience === '' ? null : targetAudience,
        tagGroupAccounts: accountGroup,
        maxUsage: Number(maxUsage),
        targetProducts: plansWithDiscountForExpiringSubscription
      }

      await updateDiscountCoupons(payload)

      Alert.success(
        'Cupom de desconto alterado com sucesso',
        navigate('/apps/marketplace/discountCoupons/list')
      )
    } catch {
      Alert.error('Ocorreu um erro ao realizar a criação do Cupom de desconto')
    }
  }

  const _getDiscountCoupons = async (): Promise<void> => {
    try {
      const discountCouponsData = await getDiscountCouponsById(id)

      if (discountCouponsData) {
        setCode(discountCouponsData.code || '')
        setStartDate(dayjs(discountCouponsData.startDate) || null)
        setEndDate(dayjs(discountCouponsData.endDate) || null)
        setPercentage(discountCouponsData.percentage || null)
        setIsActive(discountCouponsData.isActive || null)
        setIsVerifiedPlayersOnly(
          discountCouponsData.isVerifiedPlayersOnly || null
        )
        setIsAllowedOncePerMonth(
          discountCouponsData.isAllowedOncePerMonth || null
        )
        setTargetAudience(discountCouponsData.targetAudience || null)
        setAccountGroup(discountCouponsData.tagGroupAccounts || null)
        setMaxUsage(discountCouponsData.maxUsage || null)
        if (discountCouponsData.targetProducts && discountCouponsData.targetProducts.length > 0) {
          setPlansWithDiscountForExpiringSubscription(
            discountCouponsData.targetProducts
          )
          setIsIntendedForExpiringSubscriptions(true)
        }
      }
    } catch {
      Alert.error('Ocorreu um erro ao carregar o Cupom de desconto')
    }
  }

  const _getCSPlansProducts = async (): Promise<void> => {
    const plansData = await getCSPlansProducts({
      page: '1',
      limit: '1000',
      offset: '1000'
    })

    if (plansData.results && plansData.results.length > 0) {
      const availablePlans = plansData.results.filter(plan => plan.isListed && !plan.deletedAt && !plan.campaignId)
      setSubscriptionPlans(availablePlans)
    }
  }

  const validateFields = (): string[] => {
    const errors = []
    if (!code.length) errors.push('O campo Código é obrigatório')
    if (!startDate) errors.push('O campo Data inicial é obrigatório')
    if (!endDate) errors.push('O campo Data final é obrigatório')
    if (!percentage) errors.push('O campo Porcentagem é obrigatório')

    return errors
  }

  const renderCardHeader = (): JSX.Element => {
    return (
      <HeaderTitle title={`${formTitleAction} Cupom de desconto`}>
        <AddIcon fontSize="large" />
      </HeaderTitle>
    )
  }

  // const renderTagGroupAccountsOptions = (object: any): JSX.Element => {
  //   return (
  //     object &&
  //     object.map((obj) => (
  //       <MenuItem key={obj.id} value={obj.tag}>
  //         {obj.tag}
  //       </MenuItem>
  //     ))
  //   )
  // }

  const renderTargetAudienceOptions = (object: any): JSX.Element => {
    return (
      object &&
      object.map((obj, i) => (
        <MenuItem key={i} value={obj}>
          {obj}
        </MenuItem>
      ))
    )
  }

  const renderPlansOptions = (plans: Product[]): JSX.Element[] => {
    return (
      plans &&
      plans.map((obj, i) => (
        <MenuItem key={i} value={obj.id}>
          {`${obj.name} (${obj.slug})`}
        </MenuItem>
      ))
    )
  }

  const addDiscountForPlanWithExpiringSubscription = (): void => {
    setPlansWithDiscountForExpiringSubscription((prevState) => {
      const updatedPlans = prevState
      updatedPlans.push({
        productId: '',
        minDaysBeforeSubscriptionExpire: null,
        maxDaysBeforeSubscriptionExpire: null,
        id: '',
      })
      return [...updatedPlans]
    })
  }

  const removeDiscountForPlanWithExpiringSubscription = (index: number): void => {
    setPlansWithDiscountForExpiringSubscription((prevState) => {
      const updatedPlans = prevState
      updatedPlans.splice(index, 1)
      return [...updatedPlans]
    })
  }

  return (
    <Card sx={{ width: 600 }}>
      <CardHeader title={renderCardHeader()} />
      <CardContent sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {!id && (
              <Grid item xs={12}>
                <TextField
                  required
                  label="Cupom de desconto"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setCode(e.target.value)}
                  value={code.toUpperCase()}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  * O nome do cupom não pode ser o mesmo de um que já exista!
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Quantidade de cupons"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                onChange={(e) => setMaxUsage(e.target.value)}
                value={maxUsage}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <NoContextCustomDatePicker
                  label="Data inicial"
                  value={startDate}
                  onChange={(value) => setStartDate(value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <NoContextCustomDatePicker
                  label="Data final"
                  value={endDate}
                  onChange={(value) => setEndDate(value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Porcentagem de desconto"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                onChange={(e) => setPercentage(Number(e.target.value))}
                value={percentage}
              />
              <Typography variant="caption" display="block" gutterBottom>
                * Insira apenas números na porcentagem
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            {id && (
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label={isActive ? 'Cupom ativo' : 'Cupom desativado'}
                />
              </Grid>
            )}
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isVerifiedPlayersOnly}
                    onChange={(e) => setIsVerifiedPlayersOnly(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                  />
                }
                label="Exclusivo para player verificado"
              />
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllowedOncePerMonth}
                    onChange={(e) => setIsAllowedOncePerMonth(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                  />
                }
                label="Limite de uso de uma vez por mês"
              />
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isIntendedForExpiringSubscriptions}
                    onChange={(e) =>
                      setIsIntendedForExpiringSubscriptions(e.target.checked)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                  />
                }
                label="Exclusivo para assinaturas prestes a vencer"
              />
            </Grid>
          </Grid>

          {isIntendedForExpiringSubscriptions && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              {plansWithDiscountForExpiringSubscription.map((item, idx) => (
                <Grid
                  container
                  flexDirection={'row'}
                  width={'100%'}
                  justifyContent={'space-between'}
                >
                  <FormControl sx={{ mt: 5 }}>
                    <Grid container>
                      <Grid item width="100%">
                        <InputLabel>
                          Selecione um plano de assinatura
                        </InputLabel>
                        <Select
                          fullWidth
                          required
                          label="Selecione o plano de assinatura"
                          key={`plansWithDiscountForExpiringSubscription_${idx}`}
                          value={item.productId}
                          onChange={(e) => {
                            const updatedPlans = [
                              ...plansWithDiscountForExpiringSubscription,
                            ]
                            updatedPlans[idx].productId = e.target.value

                            setPlansWithDiscountForExpiringSubscription(
                              updatedPlans
                            )
                          }}
                        >
                          {renderPlansOptions(subscriptionPlans)}
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 3 }}>
                      <Grid
                        item
                        width="100%"
                        display="flex"
                        alignItems="center"
                        gap={'6px'}
                        justifyContent="space-evenly"
                      >
                        <TextField
                          label="Mínimo de dias faltando para expirar"
                          variant="outlined"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            const updatedPlans = [
                              ...plansWithDiscountForExpiringSubscription,
                            ]
                            updatedPlans[idx].minDaysBeforeSubscriptionExpire =
                              Math.abs(Number(e.target.value))
                            setPlansWithDiscountForExpiringSubscription(
                              updatedPlans
                            )
                          }}
                          value={item.minDaysBeforeSubscriptionExpire}
                        />
                        <TextField
                          label="Máximo de dias faltando para expirar"
                          variant="outlined"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            const updatedPlans = [
                              ...plansWithDiscountForExpiringSubscription,
                            ]
                            updatedPlans[idx].maxDaysBeforeSubscriptionExpire =
                              Math.abs(Number(e.target.value))
                            setPlansWithDiscountForExpiringSubscription(
                              updatedPlans
                            )
                          }}
                          value={item.maxDaysBeforeSubscriptionExpire}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                  <Grid
                    item
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 5 }}
                  >
                    <Button
                      title='Remover'
                      style={{ height: '100%' }}
                      variant="text"
                      type="button"
                      onClick={() => removeDiscountForPlanWithExpiringSubscription(idx)}
                    >
                      <img
                        style={{ width: '22px', height: '22px' }}
                        src={`https://static.gamersclub.com.br/assets/icons/trash-icon.svg`}
                        alt="Trash can"
                      />
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Grid container>
                <Grid item xs sx={{ mt: 3 }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant="contained"
                      type="button"
                      onClick={addDiscountForPlanWithExpiringSubscription}
                    >
                      Adicionar mais um plano
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} sx={{ mt: 5 }}>
            <FormControl fullWidth>
              <InputLabel>Público</InputLabel>
              <Select
                required
                label="Público"
                key="targetAudience"
                value={targetAudience}
                onChange={(e) => setTargetAudience(e.target.value)}
              >
                {renderTargetAudienceOptions(targetOptions)}
              </Select>
              <Typography variant="caption" display="block" gutterBottom>
                * Ao selecionar o público CUSTOM, selecione o grupo de usuários.
              </Typography>
            </FormControl>
          </Grid>
          {/* {targetAudience === 'CUSTOM' && (
            <Grid item xs={12} sx={{ mt: 2}}>
            <FormControl fullWidth>
              <InputLabel id="accountGroupsSelect">Grupo de usuários</InputLabel>
              <Select
                required
                label="Grupo de usuários"
                key="accountGroup"
                value={accountGroup}
                onChange={(e) => setAccountGroup(e.target.value)}
              >
                {renderTagGroupAccountsOptions(tagGroupAccounts)}
              </Select>
              </FormControl>
            </Grid>
          )} */}
          <Grid container spacing={2}>
            <Grid item xs sx={{ mt: 3 }}>
              <Box
                display="flex"
                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              >
                <Button variant="contained" type="submit">
                  {buttonLabelAction} Cupom de desconto
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export { DiscountCouponsForm }
