// COMPONENTS
import {
  Grid,
  InputLabel,
  Switch,
  Typography
} from '@mui/material'

// TYPES
import { ToggleOptionsProps as Props } from './types'
import { TARGET_AUDIENCES } from '@shared/types/ProductTypes'
import { CurrencyEnum } from '@shared/types/CurrencyTypes'

// COMPONENTS
import { FormSelect } from '../FormSelect/FormSelect'

const ToggleOptions = (props: Props): JSX.Element => {
  const { product, updateProductState } = props

  const goldValue = product.prices.find(
    price => price.currencyId === CurrencyEnum['GOLD']
  )?.price
  const silverValue = product.prices.find(
    price => price.currencyId === CurrencyEnum['SILVER']
  )?.price
  const isRedeemableByPrice = goldValue === 0 && silverValue === 0 && product.quantity > 0

  const updateSwitchState = (productKey: string): void => {
    if (productKey === "redeemable" && product.redeemableOnly) return
    if (productKey === "redeemable" &&  isRedeemableByPrice) return
    if (productKey === "redeemableOnly" && isRedeemableByPrice) return
    if (productKey === "singlePurchase" && goldValue === 0 && silverValue === 0) return

    updateProductState(productKey, !product[productKey])
  }

  const Toggle = ({ label, productKey }): JSX.Element => {
    return (
      <Grid>
        <Switch
          checked={product[productKey]}
          onChange={() => updateSwitchState(productKey)}
          color="primary"
        />
        <label>{label}</label>
      </Grid>
    )
  }

  return (
    <>
      <InputLabel>Opções</InputLabel>
        <Toggle label="Produto físico" productKey="isPhysical" />
        <Toggle label="Está sendo exibido" productKey="isListed" />
        <Toggle label="Compra única" productKey="singlePurchase" />
        <Toggle label="Item de inventário" productKey="inventoryItem" />
        <Toggle label="Está em destaque" productKey="isHighlighted" />
        <Toggle label="Exclusivo para assinantes" productKey="subscribersOnly" />
        <Toggle label="Agrupável (.say)" productKey="stackable" />
        <Toggle label="Presenteável" productKey="giftable" />
        <Toggle label="Bloqueado para lvl 0" productKey="blockedForLevelessPlayer" />
        <Toggle label="Resgatável por código" productKey="redeemableByCode" />
        <Toggle label="Exclusivamente resgatável" productKey="redeemableOnly" />
        <Toggle label="Grátis *" productKey="redeemable" />
        {product.redeemable && !isRedeemableByPrice && (
          <>
            <Typography variant="caption" display="block" gutterBottom>
              * Para selecionar um público, o item PRECISA ser grátis.
            </Typography>
            <FormSelect
              items={TARGET_AUDIENCES}
              productKey="redeemTarget"
              label="Público alvo (resgatável)"
              updateProductState={updateProductState}
              value={product.redeemTarget}
            />
          </>
        )}
        {isRedeemableByPrice && (
          <Typography variant="caption" color="orange" display="block" gutterBottom>
          * Produtos com estoque, preço 0 em silvers E preço 0 em golds serão obrigatoriamente "Grátis".
        </Typography>
        )}
    </>
  )
}

export { ToggleOptions }
