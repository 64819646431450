// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  OnboardingRewards,
  CreateOnboardingRewardsResponse,
  CreateOnboardingRewardsPayload,
} from '@shared/types/OnboardingRewarsdsTypes'
import { GetProductsResponse } from '@shared/types/ProductTypes'

const getAllOnboardingRewards = (): Promise<OnboardingRewards[]> => {
  return marketplaceApi.get(`/backoffice/onboard`)
}

const createOnboardingRewards = (data: CreateOnboardingRewardsPayload): Promise<CreateOnboardingRewardsResponse> => {
  return marketplaceApi.post(`/backoffice/onboard`, data)
}

const deleteOnboardingRewards = (id: number): Promise<OnboardingRewards> => {
  return marketplaceApi.delete(`/backoffice/onboard/${id}`)
}

const getProducts = (data?): Promise<GetProductsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/products/${queryString}`)
}

export {
  getAllOnboardingRewards,
  createOnboardingRewards,
  deleteOnboardingRewards,
  getProducts
}
