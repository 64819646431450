// STYLES
import * as Styled from './styles'
import WelcomeIcon from '@assets/svg/welcome.svg'

const Welcome: React.FC = (): JSX.Element => {
  return (
    <Styled.NotFoundContent>
      <Styled.ImgBroken src={WelcomeIcon} alt="broken" />
    </Styled.NotFoundContent>
  )
}

export { Welcome }
