import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

const DropdownComponent = (props): JSX.Element => {
  const { keyName, label, value, updateMetadata } = props

  return (
    <>
      <InputLabel>&nbsp;</InputLabel>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Activation Type"
          onChange={(e) => updateMetadata(keyName, e.target.value)}
        >
          <MenuItem value="VOUCHER">VOUCHER</MenuItem>
          <MenuItem value="REUSABLE">REUSABLE</MenuItem>
          <MenuItem value="REUSABLE_ITEM">REUSABLE_ITEM</MenuItem>
          <MenuItem value="CONSUMABLE">CONSUMABLE</MenuItem>
          <MenuItem value="TIMER">TIMER</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

export { DropdownComponent }
