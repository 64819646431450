// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import { CurrencyDataResults } from '@shared/types/CurrencyTypes'

const getCurrencies = (): Promise<CurrencyDataResults> => {
  return marketplaceApi.get(`/backoffice/currencies`)
}

export { getCurrencies }
