import { useEffect, useState } from 'react'

// SERVICES
import {
  deleteOnboardingRewards,
  getAllOnboardingRewards,
} from '@services/OnboardingRewards/OnboardingRewardsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'
import { formatDate } from '@shared/utils/FormatDate'

// COMPONENTS
import {
  Button,
  Card,
  LinearProgress,
  Typography,
  Chip,
  Tooltip,
} from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

// TYPES
import { OnboardingRewards } from '@shared/types/OnboardingRewarsdsTypes'

// STYLES
import * as Styled from './OnboardingRewardsList.styles'

const OnboardingRewardsList = (): JSX.Element => {
  const [onboardingRewards, setOnboardingRewards] = useState<OnboardingRewards[]>([])
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)
  const dateFormat = 'PP - KK:mm a'

  useEffect(() => {
    _getOnboardingRewards()
  }, [])

  const _getOnboardingRewards = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const OnboardingRewardsData =
        await getAllOnboardingRewards()
      setOnboardingRewards(OnboardingRewardsData)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _deleteOnboardingRewards = async (id: number): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir essa recompensa?')
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteOnboardingRewards(id)
          Alert.success(
            'Cupom de desconto excluído com sucesso',
            removeOnboardingRewardsFromState(id)
          )
        }
      })
      .catch((e) => {
        Alert.error('Ocorreu um erro ao excluir o cupom de desconto')
      })
  }

  const removeOnboardingRewardsFromState = (id: number): void => {
    const newOnboardingRewards = onboardingRewards.filter(
      (reward) => reward.id !== id
    )
    setOnboardingRewards(newOnboardingRewards)
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const renderSlug = (slug: string): JSX.Element => {
    return <Chip label={slug} color="default" />
  }

  const renderActionButtons = (id: number): JSX.Element => {
    return (
      <Button
        onClick={(): Promise<void> => _deleteOnboardingRewards(id)}
        color="error"
        variant="contained"
      >
        Remover
      </Button>
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 90,
    },
    {
      field: 'slug',
      headerName: 'Slug',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      renderCell: (params) => renderSlug(params.row.product.slug),
    },
    {
      field: 'description',
      headerName: 'Descrição',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      renderCell: (params) => {
        if (!params.row.description) {
          return <span>Sem descrição cadastrada</span>
        }
        
        return (
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: params.row.description}}/>} placement="top">
            <Typography variant="caption" display="block" gutterBottom>
              <Button color="success">
                Passe o mouse para ver a mensagem
              </Button>
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      field: 'createdAt',
      headerName: 'Data de criação',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      renderCell: (params) => formatDate(params.row.createdAt, dateFormat)
    },
    {
      field: 'quantity',
      headerName: 'Quantidade',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      minWidth: 220,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Resgate de recompensa">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>

      {onboardingRewards?.length > 0 ? (
        <>
          <Card
            sx={{
              mt: 4,
              ['& .MuiDataGrid-iconSeparator']: {
                display: 'none',
              },
            }}
          >
            <DataGrid
              paginationMode="server"
              rows={onboardingRewards}
              columns={columns}
              loading={refreshDataLoading}
              getRowId={(row) => row.createdAt}
              pageSize={10}
              rowsPerPageOptions={[4]}
              autoHeight
              rowHeight={80}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
              }}
              disableSelectionOnClick
              components={{
                LoadingOverlay: renderCustomLoadingOverlay,
              }}
              sx={{
                ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                  outline: 'none',
                },
                ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                  whiteSpace: 'break-spaces',
                },
              }}
            />
          </Card>
        </>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { OnboardingRewardsList }
