import { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'

// SERVICES
import {
  getDiscounts,
  deleteDiscount
} from '@services/DiscountProducts/DiscountProductsService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { Button, Card, Chip, LinearProgress, styled } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

// TYPES
import { DiscountProduct } from '@shared/types/DiscountProductsTypes'

// STYLES
import * as Styled from './DiscountProductsList.styles'

const DiscountProductsList = (): JSX.Element => {
  const [discountProducts, setDiscountProducts] = useState<DiscountProduct[]>(
    []
  )
  const [rowCount, setRowCount] = useState(0)
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)

  useEffect(() => {
    _getDiscounts()
  }, [])

  const _getDiscounts = async (): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const discountData = await getDiscounts()
      setDiscountProducts(discountData)
      // setRowCount(discountData.results.length)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _deleteDiscount = async (id: number, productName: string): Promise<void> => {
    Alert.remove(`Tem certeza que deseja excluir o desconto para o produto '${productName}'?`)
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteDiscount(id)
          Alert.success(
            'Desconto excluído com sucesso',
            removeDiscountFromState(id)
          )
        }
      })
      .catch((e) => {
        Alert.error('Ocorreu um erro ao excluir o desconto')
      })
  }

  const removeDiscountFromState = (id: number): void => {
    const newDiscounts = discountProducts.filter(
      (discount) => discount.id !== id
    )
    setDiscountProducts(newDiscounts)
  }

  const renderCustomLoadingOverlay = (): JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    )
  }

  const renderActionButtons = (id: number, productName: string): JSX.Element => {
    return (
      <Button
        onClick={(): Promise<void> => _deleteDiscount(id, productName)}
        color="error"
        variant="contained"
      >
        Excluir Desconto
      </Button>
    )
  }

  const CustomChip = styled(Chip)(({ theme }) => ({
    color: 'white',
  }))

  const renderActiveDiscount = (active: boolean): JSX.Element => {
    return (
      <CustomChip
        label={active ? 'Ativo' : 'Inativo'}
        variant="filled"
        color={active ? 'success' : 'error'}
      />
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 100,
    },
    {
      field: 'product.name',
      headerName: 'Produto com desconto',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      renderCell: (params) => <span>{params.row.product.name}</span>,
    },
    {
      field: 'discountType.name',
      headerName: 'Tipo de desconto',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      renderCell: (params) => <span>{params.row.discountType?.name}</span>,
    },
    {
      field: 'active',
      headerName: 'Desconto ativo',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 300,
      renderCell: (params) =>
        renderActiveDiscount(params.row.discountType?.name),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      minWidth: 200,
      renderCell: (params) => renderActionButtons(params.row.id, params.row.product.name),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Desconto em produtos">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      {discountProducts?.length > 0 ? (
        <Card
          sx={{
            mt: 4,
            ['& .MuiDataGrid-iconSeparator']: {
              display: 'none',
            },
          }}
        >
          <DataGrid
            paginationMode="server"
            rowCount={rowCount}
            rows={discountProducts}
            columns={columns}
            loading={refreshDataLoading}
            getRowId={(row) => row.id}
            pageSize={10}
            rowsPerPageOptions={[4]}
            autoHeight
            rowHeight={80}
            disableSelectionOnClick
            components={{
              LoadingOverlay: renderCustomLoadingOverlay,
            }}
            sx={{
              ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                outline: 'none',
              },
              ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                whiteSpace: 'break-spaces',
              },
            }}
            // onPageChange={(page): Promise<void> => _getBanners({
            //   page: page + 1,
            //   limit: 10
            // })}
          />
        </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { DiscountProductsList }
