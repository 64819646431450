// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  GetRedeemCodesResponse,
  RedeemCodeResponse,
  CreateRedeemCodesPayload,
  ExpireRedeemCodesPayload,
} from '@shared/types/RedeemCodes'

const getRedeemCodes = (data?): Promise<GetRedeemCodesResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/redeemCodes/aggregated${queryString}`)
}

const createRedeemCodes = (data: CreateRedeemCodesPayload): Promise<RedeemCodeResponse> => {
  return marketplaceApi.post('/backoffice/redeemCodes', data)
}

const expireRedeemCodes = (data: ExpireRedeemCodesPayload): Promise<void> => {
  return marketplaceApi.post('/backoffice/redeemCodes/expire', data)
}

const getRedeemCodesCsv = (productSlug: string): Promise<BlobPart> => {
  return marketplaceApi.get(`/backoffice/redeemCodes/export?productSlug=${productSlug}`)
}

export {
  getRedeemCodes,
  createRedeemCodes,
  expireRedeemCodes,
  getRedeemCodesCsv
}
