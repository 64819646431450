import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'

function RichTextEditor(props) {
  const {
    height,
    onChange,
    initialState,
    value
  } = props

  const editorRef = useRef(null)
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent())
    }
  }

  const handleChange = (content) => {
    onChange(content)
  }

  return (
    <>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialState}
        value={value}
        onEditorChange={handleChange}
        init={{
          height: height || 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
      {/*
        Descomentar caso seja necessário utilizar
        <button onClick={log}>Log editor content</button>
      */}
    </>
  )
}

export default RichTextEditor
