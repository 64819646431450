export interface Currency {
  id: number;
  name: string;
  symbol: string;
}

export interface CurrencyDataResults {
  currencies: Currency[];
}

export enum CurrencyEnum {
  SILVER = 1,
  GOLD = 2,
  BRL = 3,
  USD = 4
}