import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { parseISO, format }  from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

// SERVICES
import {
  getSeasons,
  deleteSeasons
} from '@services/Season/SeasonService'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'
import { formatDate } from '@shared/utils/FormatDate'

// COMPONENTS
import { Button, Card, LinearProgress } from '@mui/material'
import { DataGrid, GridColumns, GridOverlay } from '@mui/x-data-grid'
import { NotFound } from '@components/shared/NotFound/NotFound'
import { AssignmentRounded as AssignmentRoundedIcon } from '@mui/icons-material'
import { HeaderTitle } from '@components/shared/HeaderTitle/HeaderTitle'
import { Loader } from '@components/Loader/Loader'

// TYPES
import { Season, GetSeasonsResponse, GetSeasonQueryParams } from '@shared/types/SeasonTypes'

// STYLES
import * as Styled from './SeasonList.styles'

const SeasonList = (): JSX.Element => {
  const [seasons, setSeasons] = useState<Season[]>([])
  const [firstLoading, setFirstLoading] = useState(false)
  const [refreshDataLoading, setRefreshDataLoading] = useState(false)
  const dateFormat = 'PP - KK:mm a'
  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = {
      page: 1,
      limit: 10
    }
    _getSeasons(queryParams)
  }, [])

  const _getSeasons = async (queryParams: GetSeasonQueryParams): Promise<void> => {
    try {
      setRefreshDataLoading(true)
      const seasonData: GetSeasonsResponse = await getSeasons(queryParams)
      setSeasons(seasonData.seasons)
    } finally {
      setFirstLoading(false)
      setRefreshDataLoading(false)
    }
  }

  const _deleteSeasons = async (id: string): Promise<void> => {
    Alert.remove('Tem certeza que deseja excluir a season?')
      .then(async (result) => {
        if (result.isConfirmed) {
         await deleteSeasons(id)
         Alert.success(
          'Season excluída com sucesso',
          removeSeasonsFromState(id)
        )
        }
      })
      .catch((e) => {
        Alert.error('Ocorreu um erro ao excluir a season')
      })
  }

  const removeSeasonsFromState = (id: string): void => {
    const newSeason = seasons.filter(
      (season) => season.id !== id
    )
    setSeasons(newSeason)
  }

  const renderCustomLoadingOverlay = ():JSX.Element => {
    return (
      <GridOverlay>
        <Styled.CustomLoaderContainer>
          <LinearProgress />
        </Styled.CustomLoaderContainer>
      </GridOverlay>
    );
  }

  const renderActionButtons = (id: string): JSX.Element => {
    return (
      <>
        <Button
          onClick={(): void => navigate(`/apps/marketplace/season/${id}`)}
          color="primary"
          variant="contained"
          sx={{ mr: 1 }}
        >
          Editar
        </Button>
        <Button
          onClick={(): Promise<void> => _deleteSeasons(id)}
          color="error"
          variant="contained"
        >
          Excluir
        </Button>
      </>
    )
  }

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 50
    },
    {
      field: 'title',
      headerName: 'Título',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
    },
    {
      field: 'initialDate',
      headerName: 'Início',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      renderCell: (params) => formatDate(params.row.initialDate, dateFormat),
    },
    {
      field: 'finalDate',
      headerName: 'Encerramento',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 220,
      renderCell: (params) => formatDate(params.row.finalDate, dateFormat),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => renderActionButtons(params.row.id),
    },
  ]

  if (firstLoading) {
    return <Loader type="overlay" />
  }

  return (
    <Styled.Container>
      <HeaderTitle title="Lista de Seasons">
        <AssignmentRoundedIcon fontSize="large" />
      </HeaderTitle>
      {seasons?.length > 0 ? (
        <Card
          sx={{
            mt: 4,
            ['& .MuiDataGrid-iconSeparator']: {
              display: 'none'
            }
          }}
        >
          <DataGrid
            paginationMode="server"
            rows={seasons}
            columns={columns}
            loading={refreshDataLoading}
            getRowId={(row) => row.id}
            pageSize={10}
            rowsPerPageOptions={[4]}
            autoHeight
            rowHeight={80}
            disableSelectionOnClick
            components={{
              LoadingOverlay: renderCustomLoadingOverlay,
            }}
            sx={{
              ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
                outline: 'none'
              },
              ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
                whiteSpace: 'break-spaces'
              },
            }}
            onPageChange={(page): Promise<void> => _getSeasons({
              page: page + 1,
              limit: 10
            })}
          />
        </Card>
      ) : (
        <NotFound title="Ops... Não encontramos nenhum dado nessa lista." />
      )}
    </Styled.Container>
  )
}

export { SeasonList }
