// TYPES
import { initialRestrictionsState, Restrictions } from './RestrictionsTypes'
import { BundleProduct } from '@components/Product/ProductForm/components/Bundles/types'

// ********************************************
//                 INTERFACES
// ********************************************
export interface Product {
  blockedForLevelessPlayer: boolean
  campaignId: string
  category?: ProductCategory
  categoryId?: ProductCategory | number | ''
  children?: BundleProduct[]
  childrenProducts?: BundleProduct[]
  dateClosed: Date
  dateOpened: Date
  deletedAt?: Date
  description?: string
  giftable: boolean
  hashesQuantity: number
  highlightDate?: Date | null
  id?: string
  inventoryItem: boolean
  isActivation: boolean
  isActive?: boolean
  isBundle: boolean
  isHighlighted?: boolean
  isListed: boolean
  isPhysical: boolean
  medalId: number
  metadata?: any
  name: string
  partnerId: string
  pictures?: ProductImage[]
  planType?: PlanType
  prices?: ProductPrice[]
  purchaseTerms?: PurchaseTerm[] | number[]
  quantity?: number
  redeemable: boolean
  redeemableByCode: boolean
  redeemableOnly: boolean
  redeemTarget: string
  restrictions?: Restrictions
  seasons?: Season[]
  singlePurchase: boolean
  slug: string
  stackable: boolean
  subscribersOnly?: boolean
  subscriptionId?: string
  system?: System
  systemId: System | number | ''
  tags?: Tag[] | number[]
  unverifiedPlayerBuyLimit: number
  productPlanConfig?: {
    trialType?: string;
    trialDays?: string;
    nextProductId?: string;
  }
}

export interface Tag {
  id: number
  name: string
  slug: string
  description: string
  showInMenu: boolean
  showInStore: boolean
  priority: number | null
}

export interface ProductPrice {
  currencyId: number
  price: number | string
}

export interface ProductImage {
  url: string
}

export interface System {
  id?: number
  name: string
  prizeWorkerKey?: string
}

export interface ProductCategory {
  id?: number
  name: string
  description: string
}

export interface ProductMetadata {
  [key: string]: any
}

export interface PlanType {
  id?: number
  name: string
  days: number
  category: PlanCategory
}

export interface PlanCategory {
  id?: number
  name: string
  system: System
}

export interface PurchaseTerm {
  id?: number
  description: string
}

export interface Season {
  id?: string
  title: string
  slug: string
  initialDate: Date
  finalDate: Date
  products?: Product[]
  pageBackground: string
  productsBackground: string
  bannerBackground: string
  seasonBanner: string
  deletedAt?: Date
}

// ********************************************
//            REQUESTS AND RESPONSES
// ********************************************
export interface GetProductsResponse {
  pagination: {
    totalPages: number
    currentPage: number
    totalItems: number
  }
  results: Product[]
}

export interface GetProductQueryParams {
  page: number
  name?: string
  limit: number
}

export interface CreateProductResponse {
  product: Product
}

export interface UploadImageResponse {
  url: string
}

export type CreateProductPayload = Omit<Product, 'id'>

// ********************************************
//                 CONSTANTS
// ********************************************

export const TARGET_AUDIENCES = [
  {
    id: 'FREE',
    name: 'FREE',
  },
  {
    id: 'PLUS',
    name: 'PLUS',
  },
  {
    id: 'PREMIUM',
    name: 'PREMIUM',
  },
  {
    id: 'ALL',
    name: 'ALL',
  },
]

// ********************************************
//               INITIAL STATES
// ********************************************
export const initialProductState: Product = {
  blockedForLevelessPlayer: false,
  campaignId: '',
  categoryId: '',
  children: undefined,
  dateClosed: null,
  dateOpened: null,
  description: '',
  giftable: false,
  hashesQuantity: undefined,
  highlightDate: null, //dateFeatured
  inventoryItem: false,
  isActivation: false,
  isActive: false,
  isBundle: false,
  isHighlighted: false,
  isListed: false,
  isPhysical: false,
  medalId: undefined,
  metadata: {},
  name: '',
  partnerId: '',
  pictures: [
    {
      url: '',
    },
  ],
  planType: undefined,
  prices: [],
  purchaseTerms: [],
  quantity: undefined,
  redeemable: false,
  redeemableByCode: false,
  redeemableOnly: false,
  redeemTarget: '',
  restrictions: initialRestrictionsState,
  singlePurchase: false,
  slug: '',
  stackable: false,
  subscribersOnly: false,
  subscriptionId: undefined,
  systemId: '', //this should be system
  tags: [],
  unverifiedPlayerBuyLimit: undefined,
  productPlanConfig: null,
}
