// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  PurchaseTerm,
  PurchaseTermData,
  CreatePurchaseTermResponse,
  PurchaseTermPayload,
  UpdatePurchaseTermPayload
} from '@shared/types/PurchaseTermTypes'


const getPurchaseTermById = (id: string): Promise<PurchaseTerm> => {
  return marketplaceApi.get(`/backoffice/purchaseTerm/${id}`)
}

const getPurchaseTerms = (): Promise<PurchaseTermData> => {
  return marketplaceApi.get(`/backoffice/purchaseTerm`)
}

const createPurchaseTerms =
  (data: PurchaseTermPayload): Promise<CreatePurchaseTermResponse> => {
    return marketplaceApi.post('/backoffice/purchaseTerm/', data)
}

const updatePurchaseTerms = (data: UpdatePurchaseTermPayload): Promise<CreatePurchaseTermResponse> => {
  return marketplaceApi.patch(`/backoffice/purchaseTerm/`, data)
}

const deletePurchaseTerms = (id: number): Promise<PurchaseTermData> => {
  return marketplaceApi.delete(`/backoffice/purchaseTerm/${id}`)
}

export {
  getPurchaseTermById,
  getPurchaseTerms,
  createPurchaseTerms,
  updatePurchaseTerms,
  deletePurchaseTerms
}
