export interface PaymentRestrictionResponse {
  id: RestrictionListIdEnum;
  name: string;
  restrictions: PaymentRestrictionStructure[]
}

export enum RestrictionListIdEnum {
  paymentMethod = 'paymentMethod',
}

export interface PaymentRestrictionStructure {
  id: string;
  name: string;
  options?: RestrictionOptionStructure[]
}

export interface RestrictionOptionStructure {
  id: string
  name: string
  values: RestrictionOptionValueStructure[]
}

export interface RestrictionOptionValueStructure {
  id: string
  name: string
}

export const initialRestrictionOptionState: RestrictionOptionStructure = {
  id: '',
  name: '',
  values: []
}
