import { _checkTokenValidityInterceptor } from './interceptors/tokenValidityInterceptor'
import { _responseInterceptor } from './interceptors/responseInterceptor'
import { _authInterceptor } from './interceptors/authInterceptor'
import { _xProductInterceptor } from './interceptors/xProductInterceptor'
import { _formatDate } from './helpers/formatDate'

export const _shared = {
  checkTokenValidityInterceptor: _checkTokenValidityInterceptor,
  responseInterceptor: _responseInterceptor,
  authInterceptor: _authInterceptor,
  xProductInterceptor: _xProductInterceptor,
  formatDate: _formatDate
}
