import { useCallback, useEffect, useRef, useState } from 'react'

// TYPES
import { ImageUploadProps as Props } from './types'

// STYLES
import * as Styled from './styles'

// COMPONENTS
import { Box, Button, Modal, Typography } from '@mui/material'
import { CropModal } from './components/CropModal/CropModal'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const ImageUpload = (props: Props) => {
  const { addButtonProps, restrictions, handleSubmit, defaultImage, cropped } =
    props

  const [open, setOpen] = useState(false)
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>(defaultImage)

  useEffect(() => {
    setImagePreviewUrl(props.defaultImage)
  }, [props.defaultImage])

  useEffect(() => {
    if (open) {
      setImagePreviewUrl('')
    }
  }, [open])

  const fileInput = useRef<any>()

  const handleImageChange = (e) => {
    e.preventDefault()

    const rawFile = e.target.files[0]

    if (!rawFile) return

    const reader = new window.FileReader()

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result)
      handleSubmit(rawFile, reader.result)
    }

    if (rawFile) {
      reader.readAsDataURL(rawFile)
    }
  }

  const handleClick = () => {
    fileInput.current.value = ''
    fileInput.current.click()
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  return (
    <Styled.InputContainer>
      <Styled.InputFile
        type="file"
        onChange={handleImageChange}
        ref={fileInput}
        accept={restrictions}
      />
      {imagePreviewUrl && (
        <Styled.ImageContainer>
          <Styled.Image src={imagePreviewUrl} alt="..." />
          {cropped && (
            <Modal open={open} onClose={handleClose}>
              <Box sx={style}>
                <Typography variant="h6" component="h2">
                  Text in a modal
                </Typography>
                <CropModal
                  imagePreviewUrl={imagePreviewUrl}
                  setImagePreviewUrl={setImagePreviewUrl}
                  setOpen={setOpen}
                />
              </Box>
            </Modal>
          )}
        </Styled.ImageContainer>
      )}
      <Button
        {...addButtonProps}
        variant="contained"
        onClick={() => handleClick()}
      >
        {imagePreviewUrl ? 'Alterar imagem' : 'Selecionar imagem'}
      </Button>
    </Styled.InputContainer>
  )
}

export { ImageUpload }
