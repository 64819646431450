// COMPONENTS
import { Grid, InputLabel } from '@mui/material'
import { CustomToggle } from '@components/shared/CustomToggle/CustomToggle'

const ToggleFields = () => {
  return (
    <>  
      <Grid item xs={12}>
        <InputLabel>Opções do plano:</InputLabel>
        <CustomToggle name="isListed" label="Item de exibição"/>
        <CustomToggle name="inventoryItem" label="Item de inventário"/>
        <CustomToggle name="isActive" label="Item está ativo"/>
        <CustomToggle name="giftable" label="Item presenteável"/>
        <CustomToggle name="isFeatured" label="Item destacado (featured)"/>
      </Grid>
    </>
  )
}

export { ToggleFields }