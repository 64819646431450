import axios from 'axios'
import { MARKETPLACE_API_URL } from '@config/environment'
import { _shared } from './../../../../../root/src/app/shared/shared'
const shared = _shared

const marketplaceApi = axios.create({
  baseURL: MARKETPLACE_API_URL,
})

marketplaceApi.interceptors.request.use(shared.authInterceptor)
marketplaceApi.interceptors.response.use(
  shared.responseInterceptor,
  shared.checkTokenValidityInterceptor
)
export { marketplaceApi }
