// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  Banner,
  GetBannersResponse,
  CreateBannerResponse,
  CreateBannerPayload,
  UpdateBannerPayload,
  UploadImageResponse
} from '@shared/types/BannerTypes'

const getBannerById = (id: string): Promise<Banner> => {
  return marketplaceApi.get(`/backoffice/banners/${id}`)
}

const getBanners = (data?): Promise<GetBannersResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/banners/${queryString}`)
}

const createBanners = (data: CreateBannerPayload): Promise<CreateBannerResponse> => {
    return marketplaceApi.post('/backoffice/banners', data)
  }

const updateBanners = (data: UpdateBannerPayload): Promise<CreateBannerResponse> => {
  return marketplaceApi.patch(`/backoffice/banners/${data.id}`, data)
}

const deleteBanners = (id: string): Promise<Banner> => {
  return marketplaceApi.delete(`/backoffice/banners/${id}`)
}

const uploadImage = (formData: FormData): Promise<UploadImageResponse> => {
  return marketplaceApi.post(
    `/backoffice/banners/${formData.get('id')}/image`,
    formData
  )
}

export {
  getBannerById,
  getBanners,
  createBanners,
  updateBanners,
  deleteBanners,
  uploadImage
}
