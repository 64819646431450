// API
import { marketplaceApi } from '@shared/api/Marketplace/MarketplaceApi'

// TYPES
import {
  Tag,
  TagData,
  GetTagsResponse,
  CreateTagPayload,
  CreateTagResponse,
  UpdateTagPayload,
} from '@shared/types/TagTypes'

const getTags = async (): Promise<TagData> => {
  return marketplaceApi.get(`/backoffice/tag/all`)
}

const getTagById = (id: string): Promise<Tag> => {
  return marketplaceApi.get(`/backoffice/tag/${id}`)
}

const getTagsWithParams = (data?): Promise<GetTagsResponse> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return marketplaceApi.get(`/backoffice/tag/${queryString}`)
}

const createTag = (data: CreateTagPayload): Promise<CreateTagResponse> => {
    return marketplaceApi.post('/backoffice/tag', data)
  }

const updateTag = (data: UpdateTagPayload): Promise<CreateTagResponse> => {
  return marketplaceApi.patch(`/backoffice/tag/`, data)
}

const deleteTag = (id: number): Promise<Tag> => {
  return marketplaceApi.delete(`/backoffice/tag/${id}`)
}

const orderTags = async (ids: number[]): Promise<void> => {
  return marketplaceApi.post(`/backoffice/tag/order`, { ids })
}

export {
  getTags,
  getTagById,
  getTagsWithParams,
  createTag,
  updateTag,
  deleteTag,
  orderTags
}
