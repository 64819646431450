// API
import { accountApi } from '@shared/api/Account/AccountApi'

// TYPES
import {
  AccountGroupData,
  GetPlayerInfoField,
  GetPlayersInfoResponse
} from '@shared/types/AccountTypes'

const getAccountGroups = (data?): Promise<AccountGroupData> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return accountApi.get(`/backoffice/accountGroup${queryString}`)
}

const getPlayersInfo = (field: GetPlayerInfoField, value: string): Promise<GetPlayersInfoResponse> => {
  const queryString = `field=${field}&values[]=${value}`
  return accountApi.get(`/backoffice/accounts/playersinfo?${queryString}`)
}

export {
  getAccountGroups,
  getPlayersInfo
}
