export function _authInterceptor(request) {
  const authToken = window.localStorage.getItem('console:global:authToken')

  if (authToken) {
    Object.assign(request.headers, {
      Authorization: authToken,
    })
  }

  return request
}
